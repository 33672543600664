import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslocoService } from '@ngneat/transloco';
import { AutoDestroy } from 'projects/lib-shared-common/src/lib/decorators/auto-destroy';
import { getFileExtension, HttpErrorService } from 'projects/lib-shared-common/src/public-api';
import { ISvcCarousel, SvcDialogComponent, SvcToastService } from 'projects/lib-shared-component/src/public-api';
import { Subject, catchError, finalize, takeUntil, tap } from 'rxjs';
import { SvcReactionComponent } from 'projects/lib-shared-feature/src/lib/general/svc-reaction/svc-reaction.component';
import { environment } from 'projects/environments/environment';
import { CommunicationService } from './communication.service';
import { SvcCommentsChatComponent } from '../../general/svc-comments/components/svc-comments-chat/svc-comments-chat.component';
import { StaticApplicationId, SvcViewsService } from 'projects/lib-shared-core/src/public-api';

interface ViewCommunicationModel {
  communicationId?: number;
  title: string;
  editor: string;
  midiasCarousel: ISvcCarousel[];
}

@Component({
  selector: 'app-modal-view-communication',
  templateUrl: './modal-view-communication.component.html',
  styleUrls: ['./modal-view-communication.component.scss'],
})
export class ModalViewCommunicationComponent implements OnInit {
  public info: ViewCommunicationModel;
  public isLoading = false;
  public setAsViewsIsLoading = false;
  public setViewsIncrementIsLoading = false;
  public communicationId: number;
  public isPreview: boolean = false;
  public showCommentsChat: boolean = false;
  public markedAsRead = false;
  public applicationId = StaticApplicationId.communication;
  public featureName = 'view-communication';
  public configTypeId = '1B8DB746-99B0-45A0-9677-67AA8F102B6A';
  public env = environment;

  @ViewChild(SvcCommentsChatComponent) public svcCommentsChatComponent: SvcCommentsChatComponent;
  @ViewChild(SvcReactionComponent) public svcReaction: SvcReactionComponent;

  public isReactionLoading = false;
  public isReactionDone = false;
  public showTitle = true;

  @AutoDestroy destroy$: Subject<void> = new Subject<void>();

  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogData: any,
    private _dialogRef: MatDialogRef<ModalViewCommunicationComponent>,
    private _communicationService: CommunicationService,
    private _toastService: SvcToastService,
    private _translocoService: TranslocoService,
    private _httpErrorService: HttpErrorService,
    private _svcViewsService: SvcViewsService,
  ) {
    this._dialogRef.disableClose = true;
  }

  ngOnInit() {
    this.communicationId = +((this.dialogData && this.dialogData.id) || this.dialogData);
    this.isPreview = this.dialogData.isPreview ?? false;
    if (isNaN(this.communicationId)) return;

    this.setData();
    this.setAsViewed();
    this.setViewsIncrement();
  }

  private setData() {
    this.isLoading = true;
    this._communicationService
      .getCommunicationPreview(this.communicationId)
      .pipe(
        tap((response: any) => {
          if (response) {
            this.info = {
              communicationId: response.communicationId,
              title: response.title,
              midiasCarousel: response.attachment ? [{
                title: response.attachment?.fileName,
                type: ['mp4', 'mov', '3gp'].includes(getFileExtension(response.attachment?.fileName)) ? 'video' : 'image',
                url: response.attachment?.assignUrl,
              }] : [{
                title: 'Default Communication Image',
                type: 'image',
                url: 'assets/modal-communication/default-image-view-communication.png'
              }],
              editor: response.message,
            };
          }
          else {
            const msg = this._translocoService.translate('Este comunicado não está mais disponível, pois foi removido.');
            this._toastService.error(msg);
            this.closeDialog();
          }
        }),
        catchError((error) => {
          this._toastService.error(this._translocoService.translate(this._httpErrorService.getErrorMessage(error)));
          return error;
        }),
        takeUntil(this.destroy$),
        finalize(() => (this.isLoading = false)),
      )
      .subscribe();
  }

  private setAsViewed() {
    if (!this.isPreview) {
      this.setAsViewsIsLoading = true;
      this._communicationService.setAsRead(this.communicationId).pipe(
        tap(() => this.markedAsRead = true),
        finalize(() => this.setAsViewsIsLoading = false)
      ).subscribe();
    }
  }

  private setViewsIncrement() {
    if (!this.isPreview) {
      this.setViewsIncrementIsLoading = true;
      this._svcViewsService.setViews({
        registryId: this.communicationId.toString(),
        applicationId: this.applicationId,
        featureName: this.featureName,
      }).pipe(
        finalize(() => this.setViewsIncrementIsLoading = false)
      ).subscribe();
    }
  }

  public showComments() {
    if (!this.isPreview) {
      this.showCommentsChat = !this.showCommentsChat;

      if (this.showCommentsChat)
        setTimeout(() => {
          this.svcCommentsChatComponent?.focus();
        });
    }
  }

  public closeDialog() {
    this._dialogRef.close({
      viewed: this.markedAsRead,
    });
  }

  public handleVideoPlay(videoElement: any, isMobile: boolean): void {
    this.showTitle = false;
    
    if (isMobile) {
      if (videoElement?.requestFullscreen) 
        videoElement.requestFullscreen();
      else if (videoElement?.webkitRequestFullscreen) 
        videoElement.webkitRequestFullscreen();
       else if (videoElement?.mozRequestFullScreen) 
        videoElement.mozRequestFullScreen();
      else if (videoElement?.msRequestFullscreen) 
        videoElement.msRequestFullscreen();
    }
  }
}
