import { HttpErrorResponse } from '@angular/common/http';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { StaticApplicationId } from 'projects/lib-shared-core/src/lib/Constants/static-application-id.enum';
import { catchError, first, forkJoin, map, Observable, of, switchMap, tap } from 'rxjs';
import { SvcGamificationService } from '../features/gamification/services/svc-gamification.service';
import { AuthStatus } from "../auth/model/user-auth.model";
import { externalURLSamePageWithoutHistory } from 'projects/lib-shared-common/src/public-api';
import { EnvironmentInfo } from "../auth/model/environment.info.model";
import { NavigationService } from '../features/navigation/services/navigation.service';
import { AuthService } from '../auth/auth.service';
import { UserService } from '../features/user/services/user.service';
import { SitesService } from '../features/sites/services/sites.service';
import { AppsService } from '../features/apps/services/apps.service';
import { NotificationsService } from 'projects/lib-shared-feature/src/lib/general/svc-notifications/services/notifications.service';
import { SvcAppSettings } from '../settings/svc-app-settings';
import { PageCantBeLoadedType } from '../features/page-cant-be-load/page-cant-be-load.component';

export class RootResolver  {
  /**
   * Constructor
   */
  constructor(
    protected _router: Router,
    protected _navigationService: NavigationService,
    protected _authService: AuthService,
    protected _userService: UserService,
    protected _sitesService: SitesService,
    protected _appsService: AppsService,
    protected _notificationsService: NotificationsService,
    protected _appSettings: SvcAppSettings,
    protected _gamificationService: SvcGamificationService
  ) { }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Use this resolver to resolve initial mock-api for the application
   *
   * @param route
   * @param state
   */
  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> {
    return this._userService.get().pipe(
      first(),
      switchMap(() => forkJoin([
        this._navigationService.get(this._appSettings.applicationId as StaticApplicationId),
        this._userService.getEnvironment(),
        this._userService.getCurrencyAcronym(),
        this._authService.getUserStatusAuth(),
      ])),
      tap(([environment, apps, currency, userStatus]) => {
        if (<AuthStatus>userStatus == AuthStatus.SupplierUser)
          externalURLSamePageWithoutHistory(`${(<EnvironmentInfo>environment).url}/suppliermanagement`, ``);

        if (<AuthStatus>userStatus == AuthStatus.ContractorUser)
          externalURLSamePageWithoutHistory(`${(<EnvironmentInfo>environment).url}/contractormanagement`, ``);

        if (<AuthStatus>userStatus == AuthStatus.PendingGDPRUser)
          this._router.navigate(['page-cant-be-load'], {
            queryParams: {
              type: PageCantBeLoadedType.GDPR,
              from: this._router.url,
            }
          });
      }),
      catchError<any, any>((error: HttpErrorResponse) => {
        if (error?.status === 400) {
          this._goToPageCantBeLoaded({
            type: PageCantBeLoadedType.PERMISSION,
            from: this._router.url,
          });
        }
        else {
          this._goToPageCantBeLoaded();
        }
        return error;
      }),
    );
  }

  private _goToPageCantBeLoaded(params?: {
    type?: PageCantBeLoadedType,
    from?: string,
  }) {
    this._router.navigate(['page-cant-be-load'], {
      queryParams: {
        type: PageCantBeLoadedType.PERMISSION,
        from: this._router.url,
      }
    });
  }
}
