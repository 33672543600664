<div class="flex flex-col" *screenSizes="let size">
  <svc-breadcrumb class="px-6 py-3">
    <svc-breadcrumb-item type="HOME">Home</svc-breadcrumb-item>
    <svc-breadcrumb-item *ngIf="moduleName" link="/">{{ moduleName }}</svc-breadcrumb-item>
    <svc-breadcrumb-item [active]="true">{{ 'Cadastro de distribuição' | transloco }}</svc-breadcrumb-item>
  </svc-breadcrumb>

  <svc-distribution-manage-form class="px-6 py-4 mb-3" [getClick]="subItemEmit" (formSubmitted)="fetchDistributions()"></svc-distribution-manage-form>

  <svc-data-table-accordion
    [showSearchControl]="true"
    [showDownloadOptions]="size.isAboveSM"
    [dataTable]="dataTable"
    [isExporting]="exporting"
    [items]="items"
    [isLoading]="isLoading"
    (exportFile)="handleExportFile($event)"
    (onSubRowClicked)="handleSubRowClick($event)"
  />
</div>
