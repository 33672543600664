import { AppEnvironment } from "projects/config/model/environment.model";

const host = location.host;
const isLocalhost = host.startsWith('localhost');
const isQA = host.startsWith('localhost') || host.startsWith('qa.');
const isDEV = host.startsWith('localhost') || host.startsWith('devsp.');
const isRC = host.startsWith('localhost') || host.startsWith('sandboxefeso.');
const isDEMO = host.startsWith('localhost') || host.startsWith('demo.');
const isPROD = host.startsWith('localhost') || (!isQA && !isDEV && !isRC && !isDEMO);

export const environment: AppEnvironment = {
  isLocalhost,
  isQA,
  isDEV,
  isDEVorQA: isQA || isDEV,
  isRC,
  isDEMO,
  isPROD,
  production: false,
  APIs: {
    apiUrlAuth: 'https://api-authentication-dev.solvacelabs.com',
    apiUrlUsers: 'https://api-users-dev.solvacelabs.com',
    apiUrlActionPlan: 'https://api-actionplan-dev.solvacelabs.com',
    apiUrlChecklist: 'https://api-checklist-dev.solvacelabs.com',
    apiUrlCil: 'https://api-cil-dev.solvacelabs.com',
    apiUrlDefectTag: 'https://api-defecttag-dev.solvacelabs.com',
    apiUrlObeya: 'https://api-digitalobeya-dev.solvacelabs.com',
    apiUrlAssessment: 'https://api-assessment-dev.solvacelabs.com',
    apiUrlIncident: 'https://api-incident-dev.solvacelabs.com',
    apiUrlComplaint: 'https://api-complaint-dev.solvacelabs.com',
    apiUrlNonconformity: 'https://api-nonconformity-dev.solvacelabs.com',
    apiUrlTraining: 'https://api-training-dev.solvacelabs.com',
    apiUrlGed: 'https://api-documentation-dev.solvacelabs.com',
    apiUrlLpp: 'https://api-lpp-dev.solvacelabs.com',
    apiUrlKaizen: 'https://api-kaizen-dev.solvacelabs.com',
    apiUrlMoc: 'https://api-moc-dev.solvacelabs.com',
    apiUrlWorkpermit: 'https://api-workpermit-dev.solvacelabs.com',
    apiUrlCenterline: 'https://api-centerline-dev.solvacelabs.com',
    apiUrlHashtag: 'https://api-hashtag-dev.solvacelabs.com',
    apiUrlExportFile: 'https://api-digitalobeya-dev.solvacelabs.com',
    apiUrlReaction: 'https://api-reaction-dev.solvacelabs.com',
    apiUrlPraise: 'https://api-praise-dev.solvacelabs.com',
    apiUrlQuiz: 'https://api-quiz-dev.solvacelabs.com',
    apiUrlCommunication: 'https://api-communication-dev.solvacelabs.com',
    apiUrlPosts: 'https://api-post-dev.solvacelabs.com',
    apiUrlAdministration: 'https://api-administration-dev.solvacelabs.com',
    apiUrlComments: 'https://api-comment-dev.solvacelabs.com',
    apiUrlCommentManager: 'https://api-commentmanager-dev.solvacelabs.com',
    apiUrlProject: 'https://api-project-dev.solvacelabs.com',
    apiUrlBookmark: 'https://api-bookmark-dev.solvacelabs.com',
    apiUrlNotification: 'https://api-notification-dev.solvacelabs.com',
    apiUrlDocuments: 'https://api-documents-dev.solvacelabs.com',
    apiUrlSurvey: 'https://api-survey-dev.solvacelabs.com',
    apiUrlMasterdata: 'https://api-masterdata-dev.solvacelabs.com',
    apiUrlViews: 'https://api-views-dev.solvacelabs.com',
    apiUrlBOS: 'https://api-bos-dev.solvacelabs.com',
    apiUrlMultilingual: 'https://api-multilingual-dev.solvacelabs.com',
    apiUrlAlert: 'https://api-alert-dev.solvacelabs.com',
    apiUrlGamification: 'https://api-gamification-dev.solvacelabs.com',
    apiUrlCustomField: 'https://api-customfield-dev.solvacelabs.com',
    apiUrlUnsafeCondition: 'https://api-unsafe-condition-dev.solvacelabs.com',
    apiUrlSubtitle: 'https://api-subtitle-dev.solvacelabs.com',
    apiUrlCopilot: 'https://api-copilot-dev.solvacelabs.com',
    apiUrlRca: 'https://api-rca-dev.solvacelabs.com',
    apiUrlFishbone: 'https://api-fishbone-dev.solvacelabs.com',
  }
};
