<button mat-icon-button (click)="openPanel()" #notificationsOrigin>

  <ng-container *ngIf="totalUnread">
    <span *ngIf="totalUnread > 0" class="absolute top-0 right-0 left-0 z-9 flex items-center justify-center h-3">
      <span
        class="flex items-center justify-center shrink-0 min-w-4 h-4 px-1 ml-4 mt-2.5 z-10 rounded-full bg-red-500 text-white text-sm font-medium">
        {{ totalUnread }}
      </span>
    </span>
  </ng-container>
  <mat-icon class="text-current" [svgIcon]="'heroicons_solid:bell'"></mat-icon>
</button>

<ng-template #notificationsPanel *screenSizes="let size">

  <div class="fixed left-0 top-0 md:relative flex flex-col mat-elevation-z8 bg-white" [ngStyle]="size.isBelowMD ? { width: '100dvw', height: '100dvh' } : null" [class.aboveMD]="!size.isBelowMD">
    <div *ngIf="size.isBelowMD" class="flex items-center border-b px-4 py-2.5">
      <div class="flex flex-auto items-center gap-2 text-default">
      </div>
      <button mat-icon-button (click)="closePanel()" class="w-8 h-8 min-h-8 text-default">
        <mat-icon class="icon-size-5 text-current" svgIcon="heroicons_solid:x-mark"></mat-icon>
      </button>
    </div>
    <div class="relative flex-auto">
      <mat-tab-group #tabGroup animationDuration="0ms" class="h-full" (selectedIndexChange)="onTabIndexChanged($event)">
        <mat-tab>
          <ng-template mat-tab-label>
            {{"Notificações" | transloco}}
            <span
              *ngIf="totalUnredNotifications > 0"
              [ngClass]="{'bg-disabled' : tabGroup.selectedIndex !== 0, 'bg-primary' : tabGroup.selectedIndex === 0}"
              class="inline-flex items-center justify-center text-xs text-on-primary rounded-full font-bold px-2 ml-3 w-6 h-6"
            >{{ totalUnredNotifications }}</span>
          </ng-template>

          <lib-notification-content (clickedItem)="closePanel()"/>
        </mat-tab>

        <mat-tab *ngIf="communicationIsEnabled">
          <ng-template mat-tab-label>
            {{"Comunicados" | transloco}}
            <ng-container *ngIf="totalUnreadCommunication">
              <span
                *ngIf="totalUnreadCommunication > 0"
                [ngClass]="{'bg-disabled' : tabGroup.selectedIndex !== 1, 'bg-primary' : tabGroup.selectedIndex === 1}"
                class="inline-flex items-center justify-center text-xs text-on-primary rounded-full font-bold px-2 ml-3 w-6 h-6"
              >{{ totalUnreadCommunication }}</span>
            </ng-container>
          </ng-template>
          <lib-communication-content (clickedItem)="closePanel()"/>
        </mat-tab>

        <mat-tab>
          <ng-template mat-tab-label>
            {{"Newsletter" | transloco}}
            <ng-container *ngIf="totalUnredNewsletter">
              <span
                *ngIf="totalUnredNewsletter > 0"
                [ngClass]="{'bg-disabled' : tabGroup.selectedIndex !== (communicationIsEnabled ? 2 : 1), 'bg-primary' : tabGroup.selectedIndex === (communicationIsEnabled ? 2 : 1)}"
                class="inline-flex items-center justify-center text-xs text-on-primary rounded-full font-bold px-2 ml-3 w-6 h-6"
              >
                {{ totalUnredNewsletter }}
              </span>
            </ng-container>
          </ng-template>

          <lib-newsletter-content (clickedItem)="openNewsletter($event)"/>

        </mat-tab>

      </mat-tab-group>
    </div>
    <div *ngIf="size.isBelowMD" class="flex justify-end p-4 border-t">
      <svc-button size="sm" mode="flat" color="primary" (click)="closePanel()">
        {{ 'Fechar' | transloco }}
      </svc-button>
    </div>
  </div>

</ng-template>
