import { Component } from '@angular/core';

@Component({
  selector: 'svc-posts-skeleton',
  templateUrl: './svc-posts-skeleton.component.html',
  styleUrls: ['./svc-posts-skeleton.component.scss']
})
export class SvcPostsSkeletonComponent {

}
