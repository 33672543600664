import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatRippleModule } from '@angular/material/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import { SvcOutlinedBtnComponent } from './svc-outlined-btn.component';

@NgModule({
  declarations: [
    SvcOutlinedBtnComponent
  ],
  imports: [
    CommonModule,
    MatRippleModule,
    MatProgressSpinnerModule
  ],
  exports: [
    SvcOutlinedBtnComponent
  ]
})
export class SvcOutlinedBtnModule { }
