import { Component } from '@angular/core';

@Component({
  selector: 'svc-comments-feed-skeleton',
  templateUrl: './svc-comments-feed-skeleton.component.html',
  styleUrls: ['./svc-comments-feed-skeleton.component.scss']
})
export class SvcCommentsFeedSkeletonComponent {

}
