import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { RequestCollectorService } from 'projects/lib-shared-common/src/public-api';
import { Subject, tap } from 'rxjs';

@UntilDestroy()
@Component({
	selector: 'empty-layout',
	templateUrl: './empty.component.html',
	encapsulation: ViewEncapsulation.None,
	host: {
		'[style.height.dvh]': `100`,
	}
})
export class EmptyLayoutComponent implements OnInit, OnDestroy {
	private _unsubscribeAll: Subject<any> = new Subject<any>();

	/**
	 * Constructor
	 */
	constructor(
    private _router: Router,
    private _requestCollectorService: RequestCollectorService,
	) {
	}

	ngOnInit(): void {
		this._router.events.pipe(
			untilDestroyed(this),
			tap((event: any) => {
				if (event instanceof NavigationStart) {
					const currentUrl = this._router.url.replace('/', '');
					const nextUrl = event.url.replace('/', '');
					if (currentUrl !== nextUrl) {
						this._requestCollectorService.cancelAll();
					}
				}
			}),
		).subscribe();
	}

	// -----------------------------------------------------------------------------------------------------
	// @ Lifecycle hooks
	// -----------------------------------------------------------------------------------------------------

	/**
	 * On destroy
	 */
	ngOnDestroy(): void {
		// Unsubscribe from all subscriptions
		this._unsubscribeAll.next(null);
		this._unsubscribeAll.complete();
	}
}
