import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter, lastValueFrom, Subject, take, takeUntil, tap } from 'rxjs';
import { Layout } from './layout.types';
import { AppConfig, appConfig } from './config/app.config';
import { LegacyService } from "../legacy/legacy.service";
import { NotificationIntegrationService } from '../notification/notification-integration.service';
import { StartupConfigService } from 'projects/config/startup-config.service';
import { AuthCheckService, AuthService, UserService } from 'projects/lib-shared-core/src/public-api';
import { SvcGlobalDataPrefetching } from 'projects/lib-shared-core/src/lib/data-prefetching/svc-global-data-prefetching';
import { toggleSplashScreenVisibility } from 'projects/lib-shared-common/src/public-api';

@Component({
    selector: 'layout',
    templateUrl: './layout.component.html',
    styleUrls: ['./layout.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class LayoutComponent implements OnInit, OnDestroy {

    config: AppConfig = appConfig;
    layout: Layout;
    private _unsubscribeAll: Subject<any> = new Subject<any>();

    /**
     * Constructor
     */
    constructor(
        private _activatedRoute: ActivatedRoute,
        private _startupConfigService: StartupConfigService,
        private _router: Router,
        private _legacyService: LegacyService,
        private _userService: UserService,
        private _notificationIntegrationService: NotificationIntegrationService,
        private _authCheckService: AuthCheckService,
        private _authService: AuthService,
        private _svcGlobalDataPrefetching: SvcGlobalDataPrefetching,
    ) {
        // Hide it on the first NavigationEnd event
        this._startupConfigService.initialized$.pipe(
            tap((initialized) => {
                if (initialized) {
                    toggleSplashScreenVisibility();
                }
            }),
        ).subscribe();
        this._router.events
            .pipe(
                filter(event => event instanceof NavigationEnd),
                take(1)
            )
            .subscribe(async () => {
                if (this._startupConfigService.isInitialized || await lastValueFrom(this._authService.check())) {
                    toggleSplashScreenVisibility();
                }
            });
        this._legacyService.onLegacyCallback$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((evt) => console.log('angular captured custom callback event from iframe', evt));

        this._authService.checkAuthenticatedProperty();
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        this._updateLayout();
        // Subscribe to NavigationEnd event
        this._router.events.pipe(
            filter(event => event instanceof NavigationEnd),
            takeUntil(this._unsubscribeAll)
        ).subscribe(() => {
            this._updateLayout();
        });
        
        this._svcGlobalDataPrefetching.initAndFetch();
        this._authCheckService.startCheckSessionState();

        window['getLanguage'] = () => {
            return this._userService?.user?.languageTag ?? 'en-US';
        }
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
        this._authCheckService.stopCheckSessionState();
    }

    public childLayoutWasResolved() {
        this._legacyService.addLegacySupport();
        this._notificationIntegrationService.addIntegration();
        this._svcGlobalDataPrefetching.initAndFetch();
        this._authCheckService.startCheckSessionState();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Update the selected layout
     */
    private _updateLayout(): void {
        // Get the current activated route
        let route = this._activatedRoute;
        while (route.firstChild) {
            route = route.firstChild;
        }

        // 1. Set the layout from the config
        this.layout = this.config.layout;

        // 2. Get the query parameter from the current route and
        // set the layout and save the layout to the config
        const layoutFromQueryParam = (route.snapshot.queryParamMap.get('layout') as Layout);
        if (layoutFromQueryParam) {
            this.layout = layoutFromQueryParam;
            if (this.config) {
                this.config.layout = layoutFromQueryParam;
            }
        }

        // 3. Iterate through the paths and change the layout as we find
        // a config for it.
        //
        // The reason we do this is that there might be empty grouping
        // paths or componentless routes along the path. Because of that,
        // we cannot just assume that the layout configuration will be
        // in the last path's config or in the first path's config.
        //
        // So, we get all the paths that matched starting from root all
        // the way to the current activated route, walk through them one
        // by one and change the layout as we find the layout config. This
        // way, layout configuration can live anywhere within the path and
        // we won't miss it.
        //
        // Also, this will allow overriding the layout in any time so we
        // can have different layouts for different routes.
        const paths = route.pathFromRoot;
        paths.forEach((path) => {

            // Check if there is a 'layout' data
            if (path.routeConfig && path.routeConfig.data && path.routeConfig.data.layout) {
                // Set the layout
                this.layout = path.routeConfig.data.layout;
            }
        });
    }
}
