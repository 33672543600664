import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from "@angular/router";
import { TranslocoService } from '@ngneat/transloco';
import { environment } from 'projects/environments/environment';
import { AutoDestroy } from 'projects/lib-shared-common/src/lib/decorators/auto-destroy';
import { HttpErrorService } from 'projects/lib-shared-common/src/public-api';
import { Subject, catchError, filter, finalize, from, mergeMap, of, takeUntil, tap } from 'rxjs';
import { AppsService } from '../../apps/services/apps.service';
import { AvailableModule } from '../../apps/services/available-module.enum';
import { NavigationService } from '../../navigation/services/navigation.service';
import { SvcWorkspaceNavigationItem } from '../../navigation/services/navigation.types';
import { Counters } from '../models/counters';
import { UserNavigationService } from '../services/user-navigation.service';
import { StaticApplicationId } from '../../../Constants/static-application-id.enum';
import { SvcToastService } from 'projects/lib-shared-component/src/lib/svc-toast/svc-toast.service';

const TRAINING_APP_ID = 'D69CD5A4-1F73-40F2-B4A7-50D8BF8B0A10';
const PRAISE_APP_ID = StaticApplicationId.praise;

@Component({
  selector: 'user-navigation',
  templateUrl: './user-navigation.component.html',
  styleUrls: ['./user-navigation.component.scss']
})
export class UserNavigationComponent implements OnInit {
  @Input() navigation: SvcWorkspaceNavigationItem[];
  @Output() openPraiseReceived = new EventEmitter<boolean>();
  @Output() emitCounters = new EventEmitter<Counters>();

  public counters: Counters = {};
  public env = environment;
  public loadingState = {
    myQualifications: false,
    praiseReceived: false,
  };
  public errorState = {
    myQualifications: false,
    praiseReceived: false,
  };
  public accessibilityState = {
    myQualifications: false,
    praiseReceived: false,
  };

  #myQualifications = 'Minhas Qualificações';
  #praiseReceived = 'Elogios';
  #uncontractedModule = 'Módulo não contratado. Favor acionar o administrador do sistema.';

  @AutoDestroy destroy$: Subject<void> = new Subject<void>();

  constructor(
    private _router: Router,
    private _navigationService: NavigationService,
    private _appsService: AppsService,
    private _translocoService: TranslocoService,
    private _svcToastService: SvcToastService,
    private _userNavigationService: UserNavigationService,
    private _httpErrorService: HttpErrorService,
  ) { }

  ngOnInit(): void {
    this.#checkAccessToModules();
  }

  goTo(item: SvcWorkspaceNavigationItem) {
    const goTo = () => {
      const url = item.url;
      if ((typeof url === 'function'))
        this._router.navigate([]).then(() => {
          url();
        });
      else
        this._router.navigate([url]);
    };

    if (item?.title.toLocaleLowerCase() === this.#praiseReceived.toLocaleLowerCase()) {
      if (this.accessibilityState.praiseReceived) {
        this.openPraiseReceived.emit(true);
        return;
      } else {
        const msg = this._translocoService.translate(this.#uncontractedModule);
        this._svcToastService.error(msg, null, {
          timeout: 10000
        });
        return;
      }
    }

    const checkIsFromTraningModule = item.params?.includes(`system={${TRAINING_APP_ID}}`) || item.params?.includes('trn_curriculo_treinamento.asp');
    if (checkIsFromTraningModule) {
      if (this.accessibilityState.myQualifications) {
        goTo();
      } else {
        const msg = this._translocoService.translate(this.#uncontractedModule);
        this._svcToastService.error(msg, null, {
          timeout: 10000
        });
        return;
      }
    } else {
      goTo();
      return;
    }
  }

  public getCounterMyQualifications() {
    if (this.loadingState.myQualifications) return;
    this.accessibilityState.myQualifications = true;
    this.loadingState.myQualifications = true;
    this._userNavigationService.getQualificationsCounter()
      .pipe(
        tap((response) => {
          this.errorState.myQualifications = false;
          this.counters.qualifications = response;
          this.emitCounters.emit({ qualifications: this.counters.qualifications });
        }),
        catchError(() => {
          this.errorState.myQualifications = true;
          this.emitCounters.emit({ qualifications: null });
          return this.#handleError.bind(this)
        }),
        takeUntil(this.destroy$),
        finalize(() => (this.loadingState.myQualifications = false))
      ).subscribe();
  }

  public getPraiseReceivedCounter() {
    if (this.loadingState.praiseReceived) return;
    this.accessibilityState.praiseReceived = true;
    this.loadingState.praiseReceived = true;
    this._userNavigationService.getPraiseReceivedCounter()
      .pipe(
        tap((response) => {
          this.errorState.praiseReceived = false;
          this.counters.compliments = response;
          this.emitCounters.emit({ compliments: this.counters.compliments });
        }),
        catchError(() => {
          this.errorState.praiseReceived = true;
          this.emitCounters.emit({ compliments: null });
          return this.#handleError.bind(this)
        }),
        takeUntil(this.destroy$),
        finalize(() => (this.loadingState.praiseReceived = false))
      ).subscribe();
  }

  #checkAccessToModules() {
    from(this.navigation).pipe(
      filter(item => item?.title.toLocaleLowerCase() === this.#myQualifications.toLocaleLowerCase() || item?.title.toLocaleLowerCase() === this.#praiseReceived.toLocaleLowerCase()),
      mergeMap(item => {
        if (item?.title.toLocaleLowerCase() === this.#myQualifications.toLocaleLowerCase()) {
          const checkIsFromTrainingModule = item.params?.includes(`system={${TRAINING_APP_ID}}`) || item.params?.includes('trn_curriculo_treinamento.asp');
          if (checkIsFromTrainingModule) {
            return this._appsService.checkModuleByApplicationId(TRAINING_APP_ID).pipe(
              tap((available) => {
                if (available === AvailableModule.ACCESSIBLE) {
                  this.getCounterMyQualifications();
                }
              }),
              catchError((error) => {
                this.#handleError.bind(this);
                return error;
              }),
            );
          }
        }

        if (item?.title.toLocaleLowerCase() === this.#praiseReceived.toLocaleLowerCase()) {
          return this._appsService.checkModuleByApplicationId(PRAISE_APP_ID).pipe(
            tap((available) => {
              this.getPraiseReceivedCounter();
              /* if (available === AvailableModule.ACCESSIBLE) {
                this.accessibilityState.praiseReceived = true;
              } */
            }),
            catchError((error) => {
              this.#handleError.bind(this);
              return error;
            }),
          );
        }

        return of(null);
      })
    ).subscribe();
  }

  #handleError(error: any) {
    this._httpErrorService.showErrorInToast(error);
    return error;
  }
}
