<!-- ----------------------------------------------------------------------------------------------------- -->
<!-- Empty layout -->
<!-- ----------------------------------------------------------------------------------------------------- -->
<empty-layout *ngIf="layout === 'empty'" class="block overflow-y-auto"></empty-layout>

<!-- ----------------------------------------------------------------------------------------------------- -->
<!-- Layouts with vertical navigation -->
<!-- ----------------------------------------------------------------------------------------------------- -->

<!-- Solvace Custom layout -->
<svc-custom-layout *ngIf="layout === 'svc-custom-layout'"></svc-custom-layout>

<!-- Solvace Workspace layout -->
<svc-workspace-layout *ngIf="layout === 'svc-workspace-layout'"></svc-workspace-layout>

<!-- ----------------------------------------------------------------------------------------------------- -->
<!-- Settings drawer - Remove this to remove the drawer and its button -->
<!-- ----------------------------------------------------------------------------------------------------- -->
<!-- <settings></settings> -->
