import { Component, Input } from '@angular/core';
import { SvcBtnBaseComponent } from '../svc-btn-base.component';

@Component({
  selector: '[svc-btn]',
  templateUrl: './svc-btn.component.html',
  styleUrls: ['./svc-btn.component.scss'],
  host: {
    '[class.svc-btn]': `true`,
    '[class.svc-btn-primary]': `color === 'primary'`,
    '[class.svc-btn-on-primary]': `color === 'on-primary'`,
    '[class.svc-btn-link-type]': `type === 'link'`,
    '[class.svc-btn-disabled]': `processing || disabled`,
  },
})
export class SvcBtnComponent extends SvcBtnBaseComponent {
  @Input() public type: 'default' | 'link' = 'default';
}
