<ng-container *screenSizes="let size">
  <div #headerContainer class="empty:hidden bg-white md:bg-transparent">
    <div *ngIf="size.isBelowMD && mode == 'over'" class="flex py-4 pl-8 pr-4 mb-2 border-b">
      <div class="flex-auto flex items-center text-default">
        <div class="flex items-center">
          <mat-icon class="icon-size-8 text-current" [svgIcon]="selectedModule === 0 ? 'mat_solid:checklist' : 'heroicons_mini:newspaper'"></mat-icon>
        </div>
        <div class="flex-auto text-2xl font-bold pl-2 leading-tight">
          <p *ngIf="selectedModule === 0">{{ 'Tarefas' | transloco }}</p>
          <p *ngIf="selectedModule === 1">{{ 'Feed' | transloco }}</p>
        </div>
      </div>
      <div class="flex">
        <svc-button class="flex self-end w-9" size="sm" icon-only mode="simple" (click)="toggle()">
          <span class="text-default">
            <mat-icon class="text-current" svgIcon="mat_solid:close"></mat-icon>
          </span>
        </svc-button>
      </div>
    </div>
    
    <svc-kaizen-resume
      *ngIf="inWorkspace && !showSideBySide && size.isAboveMD"
      class="block mb-2 cursor-pointer"
      (click)="onKaizenResumeClicked.emit()"
    ></svc-kaizen-resume>

    <!-- TABs -->
    <div *ngIf="feedIsEnabled && !showSideBySide" class="hidden items-end pb-2 w-full lg:flex" [style.maxWidth.px]="318">
      <!-- TASKS TAB -->
      <ng-container *ngIf="!hasErrorTasks; else refreshTotalTasks">
        <div class="flex-auto flex px-3 text-white items-center justify-center gap-2 h-fit rounded-t-md cursor-pointer" [ngClass]="selectedModule === 0 ? 'bg-primary py-2' : 'bg-gray-400 py-1.5'" (click)="selectedModule = 0; commentsFeed?.expanded && commentsFeed?.toggleExpanded()">
          <mat-icon class="text-current icon-size-5" [svgIcon]="'mat_solid:checklist'"></mat-icon>
          <span class="font-light">{{ 'Tarefas' | transloco }}</span>
          <div *ngIf="totalTasks || loadingTasks"
            class="flex items-center justify-center bg-white text-xs font-bold rounded-full"
            [ngClass]="selectedModule === 0 ? 'text-primary' : 'text-gray-400'"
            [style.width.px]="24"
            [style.height.px]="24"
          >
            <span *ngIf="!loadingTasks">{{ totalTasks > 99 ? '+99' : totalTasks }}</span>

            <ngx-skeleton-loader
              *ngIf="loadingTasks"
              appearance="circle"
              [theme]="{ width: '24px', height: '24px',  margin: '0' }"
            >
            </ngx-skeleton-loader>
          </div>
        </div>
      </ng-container>
      <ng-template #refreshTotalTasks>
        <div class="flex-auto flex p-3 items-center justify-center gap-2 h-fit rounded-t-md border shadow" [ngClass]="{'bg-white': !loadingTasks}">
          <div *ngIf="!loadingTasks" class="flex gap-2 justify-center items-center font-normal cursor-pointer" (click)="getTasks()">
            <mat-icon class="icon-size-3.5 text-primary" svgIcon="fontawesome_solid:triangle-exclamation"></mat-icon>
            <span class="text-sm text-primary font-bold underline">{{ 'Atualize a página' | transloco }}</span>
          </div>
          <mat-spinner *ngIf="loadingTasks" diameter="16" class="text-current"></mat-spinner>
        </div>
      </ng-template>

      <!-- FEED TAB -->
        <div class="flex-auto flex p-3 text-white items-center justify-center gap-2 h-fit rounded-t-md cursor-pointer" [ngClass]="selectedModule === 1 ? 'bg-primary py-2' : 'bg-gray-400 py-1.5'" (click)="selectedModule = 1">
          <mat-icon class="text-current icon-size-5" [svgIcon]="'heroicons_mini:newspaper'"></mat-icon>
          <span>{{ 'Feed' | transloco }}</span>
          <div *ngIf="(totalFeeds || loadingFeeds) && !hasErrorFeed"
            class="flex items-center justify-center bg-white text-xs font-bold rounded-full"
            [ngClass]="selectedModule === 1 ? 'text-primary' : 'text-gray-400'"
            [style.width.px]="24"
            [style.height.px]="24"
          >
            <span *ngIf="!loadingFeeds">{{ totalFeeds > 99 ? '+99' : totalFeeds }}</span>
            
            <ngx-skeleton-loader
              *ngIf="loadingFeeds"
              appearance="circle"
              [theme]="{ width: '24px', height: '24px', margin: '0' }"
            >
            </ngx-skeleton-loader>
          </div>

          <mat-icon *ngIf="!loadingFeeds && hasErrorFeed" class="icon-size-4 text-white" svgIcon="heroicons_solid:exclamation-triangle"></mat-icon>

          <mat-icon *ngIf="!loadingFeeds && hasErrorTotalUnread && !hasErrorFeed" class="icon-size-5 text-white" svgIcon="heroicons_solid:arrow-path" (click)="getTotalUnread()" [matTooltip]="'Atualizar total de não lidos' | transloco"></mat-icon>

          <mat-spinner *ngIf="loadingFeeds && hasErrorFeed" diameter="16" class="text-current"></mat-spinner>
        </div>
    </div>
  </div>

  <!-- TASKS TAB CONTENT -->
  <div class="flex flex-col overflow-y-auto h-full bg-white lg:bg-transparent" [ngClass]="{ 'w-full': !showSideBySide, 'w-1/2': showSideBySide, 'hidden': !showSideBySide && selectedModule !== 0 }">
    <svc-kaizen-resume
      *ngIf="inWorkspace && showSideBySide"
      class="mb-2 cursor-pointer"
      (click)="onKaizenResumeClicked.emit()"
    ></svc-kaizen-resume>
    <main-tasks #mainTasks
      class="flex-auto overflow-y-auto w-full h-full"
      (onTotalChanged)="totalTasks = $event"
      (onLoadingChanged)="loadingTasks = $event"
      (onError)="hasErrorTasks = !$event.allHadErrors && $event.hadAtLeastOneError"
    ></main-tasks>
  </div>

  <!-- FEED TAB CONTENT -->
  <div *ngIf="feedIsEnabled" class="overflow-y-auto h-full bg-white md:bg-transparent" [ngClass]="{ 'w-full': !showSideBySide, 'w-1/2': showSideBySide, 'hidden': (!showSideBySide && selectedModule !== 1) || hasErrorFeed }">
    <svc-comments-feed #commentsFeed
      [style.height]="(commentsFeed?.expanded && !showSideBySide) ? 'calc(100% - ' + (headerContainer?.clientHeight ?? 0) + 'px)' : null"
      [class.h-full]="!commentsFeed?.expanded || showSideBySide"
      class="overflow-y-auto"
      (onTotalChanged)="totalFeeds = $event"
      (onLoadingChanged)="loadingFeeds = $event"
      (onExpanded)="setForceOverlay($event)"
      (onHasErrorFeed)="hasErrorFeed = $event"
      (onHasErrorTotalUnread)="hasErrorTotalUnread = $event"
    ></svc-comments-feed>
  </div>
  <div *ngIf="hasErrorFeed && selectedModule === 1" class="flex p-3 items-center justify-center gap-2 overflow-y-auto h-full rounded-t-md border shadow" [ngClass]="{'bg-white': !loadingFeeds}">
    <div *ngIf="!loadingFeeds" class="flex gap-2 justify-center items-center font-normal cursor-pointer" (click)="getFeed()">
      <mat-icon class="icon-size-3.5 text-primary" svgIcon="fontawesome_solid:triangle-exclamation"></mat-icon>
      <span class="text-sm text-primary font-bold underline">{{ 'Atualize a página' | transloco }}</span>
    </div>
    <mat-spinner *ngIf="loadingFeeds" diameter="16" class="text-current"></mat-spinner>
  </div>
</ng-container>
