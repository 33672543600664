export type MyworkspaceTabs = 'posts' | 'obeya' | 'dashboard/subordinates' | 'dashboard/user' | 'kaizen' | 'projects' | 'communications';

export enum MyworkspaceUserPreferencesFeaturesNames {
  MyworkspaceTabs = 'Myworkspace Tabs',
  MyworkspaceObeyaTab = 'Myworkspace Obeya Tab',
  MyWorkspaceKaizenTab = 'Myworkspace Kaizen Tab',
  MyWorkspaceCommunicationTab = 'Myworkspace Communication Tab',
  MyWorkspaceProjectsTab = 'Myworkspace Projects Tab',
  MyWorkspacePostsTab = 'Myworkspace Posts Tab',
  MyWorkspaceFilterBy = 'Myworkspace Posts Tab FilterBy',
  MyWorkspaceHashtagFilterPostsTab = 'Myworkspace Filter Posts Tab by hashtags clicked',
}
