import { Component } from '@angular/core';

@Component({
  selector: 'main-task-skeleton',
  templateUrl: './main-task-skeleton.component.html',
  styleUrls: ['./main-task-skeleton.component.scss']
})
export class MainTaskSkeletonComponent {

}
