import { Injectable } from '@angular/core';
import { AppEnvironmentConfig } from './model/environment.config.model';
import { DatePipe } from '@angular/common';
import { environment } from 'projects/environments/environment';
import { Observable, ReplaySubject, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class StartupConfigService extends AppEnvironmentConfig {
  constructor(private datePipe: DatePipe) {
    super();
  }

  private _ready: ReplaySubject<void> = new ReplaySubject<void>(1);
  public ready$ = this._ready.asObservable();

  private _initialized = new BehaviorSubject<boolean>(false);
  public initialized$ = this._initialized.asObservable();
  public get isInitialized() {
    return this._initialized.value;
  }

  static config(): AppEnvironmentConfig {
    return Object.assign(new AppEnvironmentConfig(), this);
  }

  initialized() {
    if (!this._initialized.value) {
      this._initialized.next(true);
    }
  }

  async load$(): Promise<void> {
    this.Populate();
  }

  Populate(): void {
    this.APIs = environment.APIs;
    this._ready.next();
  }

  logProblem(
    source: string,
    action: string = null,
    message: string = null,
    data: any = null,
    isError: boolean = false
  ): Promise<any> {
    const LogDateFormat: string = 'hh:mm:ss.sss';
    const IsDebugActive: boolean =
      localStorage.getItem('debug-active') == 'true';

    if (!IsDebugActive) {
      return Promise.resolve();
    }
    const time = this.datePipe.transform(new Date(), LogDateFormat);
    const color: string = isError ? '#e03232' : '#f76901';
    let logmsg: string = '';

    logmsg += `%c[LOG]-> [${time}][${source}]`;
    if (action && action != null) {
      logmsg += `[${action}]`;
    }
    if (message && message != null) {
      logmsg += ` ${message}`;
    }

    if (data != null) {
      console.groupCollapsed(`${logmsg}`, `color: ${color};`);
      console.log(data);
      console.groupEnd();
    } else {
      console.info(`${logmsg}`, `color: ${color};`);
    }

    return Promise.resolve();
  }
}
