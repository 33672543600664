import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DatePipe } from '@angular/common';
import { AppEnvironmentConfig } from 'projects/config/model/environment.config.model';
import { HttpClient } from '@angular/common/http';
import { StartupConfigService } from 'projects/config/startup-config.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PageCantBeLoadModule, SVC_APP_SETTINGS, SvcAppSettingsValue, StaticApplicationId, SvcModule } from 'projects/lib-shared-core/src/public-api';
import { LibSharedIconsModule } from 'projects/lib-shared-icons/src/public-api';
import { NgxPermissionsModule } from 'ngx-permissions';
import { APPNAME } from "projects/lib-shared-core/src/lib/Constants/app-injection-token";
import { NotFound404Module } from 'projects/lib-shared-core/src/public-api';
import { LibSharedComponentModule, SvcButtonsModule, SvcDataTableModule, SvcElementsBoardModule, SvcTreeModule } from 'projects/lib-shared-component/src/public-api';
import { ModalPraiseNewModule } from "projects/lib-shared-feature/src/lib/modals/modal-praise-new/modal-praise-new.module";
import { ModalInsightsModule } from "projects/lib-shared-feature/src/lib/modals/modal-insights/modal-insights.module";
import { LibSharedFeatureModule } from 'projects/lib-shared-feature/src/public-api';
import { SvcModalAlertModule } from 'projects/lib-shared-feature/src/lib/modals/svc-modal-alert/svc-modal-alert.module';
import { SvcModalBosModule } from 'projects/lib-shared-feature/src/lib/modals/svc-modal-bos/svc-modal-bos.module';

export function StartupConfigFactory$(
  StartupConfigService: StartupConfigService
) {
  return () => {
    return StartupConfigService.load$();
  };
}

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    NgxPermissionsModule.forRoot(),
    // Core module of your application
    LibSharedFeatureModule,
    LibSharedIconsModule,
    LibSharedComponentModule,
    SvcButtonsModule,
    NotFound404Module,
    PageCantBeLoadModule,
    SvcDataTableModule,
    SvcTreeModule,
    SvcElementsBoardModule,
    ModalPraiseNewModule,
    ModalInsightsModule,
    SvcModalAlertModule,
    SvcModalBosModule,
  ],
  providers: [
    DatePipe,
    {
      provide: APPNAME,
      useValue: 'myworkspace'
    },
    {
      provide: AppEnvironmentConfig,
      deps: [HttpClient, DatePipe],
      useExisting: StartupConfigService,
    },
    {
      provide: APP_INITIALIZER,
      multi: true,
      deps: [StartupConfigService],
      useFactory: StartupConfigFactory$,
    },
    {
      provide: SVC_APP_SETTINGS,
      useValue: new SvcAppSettingsValue({
        applicationId: StaticApplicationId.myworkspace,
        applicationName: 'MyWorkSpace',
        module: SvcModule.MyWorkspace,
      }),
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
