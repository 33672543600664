import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatExpansionModule } from "@angular/material/expansion";
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from "@angular/material/tooltip";
import { TranslocoModule } from "@ngneat/transloco";
import { AgVirtualScrollModule } from "ag-virtual-scroll";
import { LibServiceActionplanAnalyticsModule } from 'projects/lib-service-actionplan-analytics/src/public-api';
import { LibServiceChecklistAnalyticsModule } from 'projects/lib-service-checklist-analytics/src/public-api';
import { LibServiceCilAnalyticsModule } from 'projects/lib-service-cil-analytics/src/public-api';
import { LibServiceDefecttagAnalyticsModule } from 'projects/lib-service-defecttag-analytics/src/public-api';
import { LibSharedCommonModule } from 'projects/lib-shared-common/src/public-api';
import { MainTaskComponent } from "./main-task/main-task.component";
import { MainTasksComponent } from "./main-tasks/main-tasks.component";
import { SvcProgressBarModule } from 'projects/lib-shared-component/src/lib/svc-progress-bar/svc-progress-bar.module';
import { SvcStatusBadgeModule } from 'projects/lib-shared-component/src/lib/svc-status-badge/svc-status-badge.module';
import { SvcUserAvatarModule } from 'projects/lib-shared-component/src/lib/svc-user-avatar/svc-user-avatar.module';
import { SvcLockButtonModule } from 'projects/lib-shared-component/src/lib/svc-lock-button/svc-lock-button.module';
import { SvcBookmarkButtonModule } from 'projects/lib-shared-feature/src/lib/general/svc-bookmark-button/svc-bookmark-button.module';
import { MainTaskSkeletonModule } from './main-task-skeleton/main-task-skeleton.module';

@NgModule({
  declarations: [
    MainTaskComponent,
    MainTasksComponent
  ],
  imports: [
    CommonModule,
    LibSharedCommonModule,
    MatExpansionModule,
    AgVirtualScrollModule,
    SvcLockButtonModule,
    SvcBookmarkButtonModule,
    SvcUserAvatarModule,
    SvcStatusBadgeModule,
    SvcProgressBarModule,
    MatTooltipModule,
    LibServiceCilAnalyticsModule,
    LibServiceDefecttagAnalyticsModule,
    LibServiceChecklistAnalyticsModule,
    LibServiceActionplanAnalyticsModule,
    TranslocoModule,
    MatIconModule,
    MainTaskSkeletonModule
  ],
  exports: [
    MainTaskComponent,
    MainTasksComponent
  ],
})
export class MainTasksModule { }
