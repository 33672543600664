import { Component, Input } from '@angular/core';
import { SvcBtnBaseComponent } from '../svc-btn-base.component';

@Component({
  selector: '[svc-outlined-btn]',
  templateUrl: './svc-outlined-btn.component.html',
  styleUrls: ['./svc-outlined-btn.component.scss'],
  host: {
    '[class.svc-outlined-btn]': `true`,
    '[class.svc-outlined-btn-primary]': `color === 'primary'`,
    '[class.svc-outlined-btn-on-primary]': `color === 'on-primary'`,
    '[class.svc-outlined-btn-border]': `hasBorder`,
    '[class.svc-outlined-btn-disabled]': `processing || disabled`,
  },
})
export class SvcOutlinedBtnComponent extends SvcBtnBaseComponent {
  @Input() hasBorder = true;
}
