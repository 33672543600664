<div class="flex flex-col flex-auto space-y-1 p-2">
  <div 
    *ngFor="let feed of [1, 2, 3, 4]; let index = index; let first = first; let last = last"
    [ngClass]="{ 'border-b border-[#D0D5DD]': feed !== 4 }"
  >
    <div class="flex flex-col px-2 py-2 rounded-md cursor-pointer gap-1" [ngClass]="{ 'pt-3': feed === first, 'pb-3': feed === last }">
      <div class="flex">
        <div class="flex flex-col flex-auto gap-0.5">
          <ngx-skeleton-loader
            appearance="line"
            [theme]="{ width: '180px', height: '21px'}"
          >
          </ngx-skeleton-loader>

          <ngx-skeleton-loader
            *ngIf="index === 1"
            appearance="line"
            [theme]="{ width: '80px', height: '18px'}"
          >
          </ngx-skeleton-loader>
        </div>
        
        <ngx-skeleton-loader
          *ngIf="index === 2"
          class="pl-2"
          appearance="circle"
          [theme]="{ width: '30px', height: '30px', margin: '0'}"
        >
        </ngx-skeleton-loader>

        <ngx-skeleton-loader
          *ngIf="index === 3"
          class="pl-2"
          [theme]="{ width: '24px', height: '24px' }"
        >
        </ngx-skeleton-loader>
      </div>

      <ng-container *ngIf="index !== 2">
        <div class="flex items-center gap-x-2">
          <ngx-skeleton-loader
            appearance="circle"
            [theme]="{ width: '34px', height: '34px', margin: '0'}"
          >
          </ngx-skeleton-loader>
  
          <ngx-skeleton-loader
            class="flex flex-col gap-0.5"
            appearance="line"
            [count]="index === 3 ? 2 : 1"
            [theme]="{ width: '100px', height: '21px'}"
          >
          </ngx-skeleton-loader>
        </div>
  
        <div *ngIf="index === 3" class="flex gap-x-2 mt-2">
          <ngx-skeleton-loader
            [theme]="{ width: '24px', height: '24px'}"
          >
          </ngx-skeleton-loader>
  
          <ngx-skeleton-loader
            [theme]="{ width: '138px', height: '24px'}"
          >
          </ngx-skeleton-loader>
        </div>
      </ng-container>

      <div class="flex items-center gap-x-2" *ngIf="index === 2">
        <ngx-skeleton-loader
          appearance="circle"
          [theme]="{ width: '34px', height: '34px', margin: '0'}"
        >
        </ngx-skeleton-loader>


        <div class="flex text-primary gap-x-2">,
          <ngx-skeleton-loader
            [theme]="{ width: '24px', height: '24px'}"
          >
          </ngx-skeleton-loader>
  
          <ngx-skeleton-loader
            [theme]="{ width: '138px', height: '24px'}"
          >
          </ngx-skeleton-loader>
        </div>
      </div>
    </div>
  </div>
</div>
