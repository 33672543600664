<div class="flex flex-col bg-white items-center rounded-lg overflow-hidden" *transloco="let t">

  <div class="flex flex-col w-full items-center space-y-3 mb-2">
    <div class="flex flex-col w-full relative">
      <svc-user-avatar style="width: 100%; height: 150px !important" [info]="userAvatar"></svc-user-avatar>
      <div
        class="text-white uppercase font-bold text-center text-base w-full absolute bottom-0 py-2 bg-gradient-to-t from-gray-900">
        {{ user.firstLastName }}
      </div>
    </div>

    <div class="flex flex-col">
      <div class="text-black text-center text-base">
        {{ user.employeePositionName }}
      </div>
      <div class="text-black text-center text-base">
        {{ user.areaName }}
      </div>
    </div>

  </div>

  <div class="flex flex-row flex-wrap w-full" *ngIf="indicators">
    <div class="flex flex-col w-1/4 px-0.5 mb-3 items-center" *ngFor="let indicator of indicators">
      <span class="flex-auto block text-center text-xxs text-black" [style.wordWrap]="'break-word'" [style.wordBreak]="'break-word'">
        {{ t(indicator.description) }}
      </span>
      <span *ngIf="!indicator.error && !indicator.loading" class="items-center text-center text-base font-bold text-black">
        {{ indicator?.amount | formatNumberDefault }}
      </span>
      <div *ngIf="!indicator.error && indicator.loading" class="pt-1">
        <ngx-skeleton-loader
          appearance="line"
          [theme]="{ width: '32px', height: '24px'}"
        >
        </ngx-skeleton-loader>
      </div>
      <div *ngIf="indicator.error" class="pt-1">
        <mat-icon class="icon-size-4 text-default" svgIcon="heroicons_solid:exclamation-triangle"></mat-icon>
      </div>
    </div>
  </div>

</div>
