import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';

import { catchError, finalize, Observable, of, Subject, Subscription, takeUntil, tap } from 'rxjs';

import { ISvcSelectOption } from 'projects/lib-shared-component/src/lib/svc-controls/svc-select/svc-select.component';
import { AutoDestroy } from 'projects/lib-shared-common/src/lib/decorators/auto-destroy';
import { SvcCustomFieldService } from './svc-custom-field.service';
import { HttpErrorService } from 'projects/lib-shared-common/src/lib/services/http-error.service';
import { SvcCustomField, SvcCustomFieldAdd, SvcCustomFieldList } from './svc-custom-field.interface';
import { TranslocoService } from '@ngneat/transloco';
import { SvcFormCustomFieldType } from '../svc-form-custom-field/svc-form-custom-field.enum';

@Component({
  selector: 'svc-custom-fields',
  templateUrl: './svc-custom-fields.component.html',
  styleUrls: ['./svc-custom-fields.component.scss'],
})

export class SvcCustomFieldsComponent implements OnInit, OnDestroy {

  /**
  * Name of the feature, such as "bos-type" or "rca-type",
  * which corresponds to the type displayed in the registration form.
  */
  @Input() public featureName: string;
  @Input() maxLengthName: string | number = null;

  public form: FormGroup;
  public options: ISvcSelectOption[] = [];
  public isOptionsLoading = true;
  public fieldTypes = SvcFormCustomFieldType;
  public isLoading: boolean;

  private subscriptions: Subscription[] = [];

  @AutoDestroy private _destroy$ = new Subject<void>();

  constructor(
    private _fb: FormBuilder,
    private _customfieldService: SvcCustomFieldService,
    private _errorService: HttpErrorService,
    private _translocoService: TranslocoService
  ) {
    this.form = this._fb.group({
      fields: this._fb.array([])
    });
  }

  ngOnInit(): void {
    this._getCustomFieldTypes();
    this.initializeCustomTypeListeners();
  }

  ngOnDestroy(): void {
    this._unsubcribeForm();
  }

  private _unsubcribeForm(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  private _getCustomFieldTypes(): void {
    this.isOptionsLoading = true;
    this._customfieldService.getCustomFieldTypes().pipe(
      tap((options: ISvcSelectOption[]) =>
        this.options = options?.map(option => (
          {
            ...option,
            text: this._translocoService.translate(option.text)
          }
        ))
      ),
      catchError(err => {
        this._errorService.showErrorInToast(err);
        return err;
      }),
      finalize(() => this.isOptionsLoading = false),
      takeUntil(this._destroy$)
    ).subscribe();
  }

  get customFields(): FormArray {
    return this.form.get('fields') as FormArray;
  }

  private initializeCustomTypeListeners(): void {
    this.customFields.controls.forEach((field, index) =>
      this.subscribeToCustomTypeChange(field, index)
    );
  }

  private subscribeToCustomTypeChange(field: AbstractControl, index: number): void {
    const sub = field.get('fieldTypeId')?.valueChanges.subscribe(value =>
      this.handleCustomTypeChange(value, index)
    );

    if (sub)
      this.subscriptions.push(sub);
  }

  private handleCustomTypeChange(customType: number, fieldIndex: number): void {
    const options = this.getOptions(fieldIndex);

    if (![this.fieldTypes.List, this.fieldTypes.ListMultiple].includes(customType))
      options.clear();
  }

  getOptions(fieldIndex: number): FormArray {
    return this.customFields.at(fieldIndex).get('options') as FormArray;
  }

  addOption(fieldIndex: number): void {
    const options = this.getOptions(fieldIndex);
    options.push(this.createOptionGroup());
  }

  removeOption(fieldIndex: number, optionIndex: number): void {
    const options = this.getOptions(fieldIndex);
    options.removeAt(optionIndex);
  }

  private createOptionGroup(): FormGroup {
    return this._fb.group({
      fieldOption: ['', Validators.required]
    });
  }

  addCustomField(): void {
    const customFieldGroup = this._fb.group({
      fieldName: ['', Validators.required],
      fieldTypeId: ['', Validators.required],
      mandatory: [false],
      options: this._fb.array([this.createOptionGroup()])
    });

    const index = this.customFields.length;
    this.customFields.push(customFieldGroup);
    this.subscribeToCustomTypeChange(customFieldGroup, index);
  }

  removeCustomField(index: number): void {
    this.customFields.removeAt(index);
  }

  drop(event: CdkDragDrop<string[]>, fieldIndex: number): void {
    const options = this.getOptions(fieldIndex).controls;
    moveItemInArray(options, event.previousIndex, event.currentIndex);

    this.getOptions(fieldIndex).setValue(options.map(control => control.value));
  }

  public clearForm(): void {
    this.form.reset({
      customFields: this._fb.array([])
    });
    this.customFields?.clear();
    this._unsubcribeForm();
  }

  private _getFieldsToAdd(): SvcCustomField[] {
    return this.form.value.fields?.map(field => {
      return {
        ...field,
        options: field?.options?.map(option => option?.fieldOption)
      }
    });
  }

  public changeCustomField(registryId: number): Observable<boolean> {
    if (this.formValid() && this.form?.value?.fields?.length) {
      const customFieldRequest: SvcCustomFieldAdd = {
        registryId,
        featureName: this.featureName,
        fields: this._getFieldsToAdd()
      };

      return this._customfieldService.changeCustomFields(customFieldRequest).pipe(
        tap(() => this.clearForm())
      );
    }
    else
      return of(true);
  }

  private _patchForm(customFields: SvcCustomField[]) {
    this.clearForm();

    customFields?.forEach((customField: SvcCustomField, index: number) => {
      this.addCustomField();
      const formGroup = this.customFields?.controls?.[index] as FormGroup;

      formGroup?.patchValue({
        fieldName: customField?.fieldName,
        fieldTypeId: customField?.fieldTypeId,
        mandatory: customField.mandatory,
      });

      customField?.options?.forEach((option: string, indexOption: number) => {

        if (indexOption > 0)
          this.addOption(index);

        (formGroup?.controls?.options as FormGroup)?.controls?.[indexOption]?.patchValue({
          fieldOption: option
        });
      });
    });
  }

  public getCustomField(registryId: number): void {
    this.clearForm();
    this.isLoading = true;
    this._customfieldService.getCustomField(this.featureName, registryId).pipe(
      tap((customFields: SvcCustomFieldList) => {
        this._patchForm(customFields?.fields);
      }),
      catchError(err => {
        this._errorService.showErrorInToast(err);
        return err;
      }),
      finalize(() => this.isLoading = false),
      takeUntil(this._destroy$)
    ).subscribe();
  }

  public formValid(): boolean {
    this.form.markAllAsTouched();
    return this.form.valid;
  }
}
