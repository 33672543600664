<div 
  class="flex items-center gap-2.5 p-1 mb-[10px]" 
  *ngIf="showSearch || showButtonAddActionplan || isViewMode"
>
  <mat-icon
    class="icon-size-6 text-primary cursor-pointer"
    id="svc-actionplan-button-add"
    svgIcon="heroicons_solid:plus-circle"
    *ngIf="showButtonAddActionplan && !isViewMode"
    (click)="openDialogActionPlan()"
  >
  </mat-icon>

  <div class="w-full max-w-[420px]" *ngIf="showSearch">
    <svc-search-field [forceWidthFull]="true" [disabled]="isLoading" (onChanged)="filterActionsPlans($event)">
    </svc-search-field>
  </div>
</div>

<div class="flex flex-col gap-2.5" *ngIf="!isLoading || actionsPlans?.length">
  <div
    class="action-plan flex gap-1 justify-between items-center px-2 cursor-pointer"
    id="svc-actionplan-card"
    *ngFor="let actionPlan of actionsPlans"
    (click)="openDialogActionPlan(actionPlan, false)"
  >
    <div class="flex gap-1 justify-between items-center border-b-[0.29px] border-[#D0D5DD] w-full py-1">
      <div class="flex gap-1.5 items-center">
        <div class="flex gap-2">
          <div class="h-5 w-5">
            <svc-lock-button
              *ngIf="actionPlan?.classified"
              iconSizeClass="icon-size-5"
              [locked]="!actionPlan?.allowed"
              [readonly]="true"
            >
            </svc-lock-button>
          </div>

          <div [matTooltip]="actionPlan?.senderName">
            <svc-user-avatar
              [info]="actionPlan?.svcUserAvatar"
            ></svc-user-avatar>
          </div>
        </div>

        <div class="flex flex-col gap-0.5">
          <svc-button
            class="action-plan-id"
            mode="simple"
            color="primary"
            size="sm"
            buttonId="svc-actionplan-button-id"
          >
            <p class="font-medium text-sm uppercase text-primary">
              {{ ((tempId ? 'Rascunho' : 'Pla') | transloco).toUpperCase() }}-{{id ? actionPlan?.planId : '' }}
            </p>
          </svc-button>

          <p class="text-default w-fit text-sm break-words line-clamp-2" [matTooltip]="actionPlan?.planName">
            {{ actionPlan?.planName }}
          </p>
        </div>
      </div>

      <div class="flex flex-col gap-1 items-end justify-center">
        <div class="flex items-center gap-0.5">
          <svc-totals-status
            classColor="text-default"
            [referenceId]="actionPlan.planId"
            [configTypeId]="'7D0932EA-8C0E-4E24-A4AF-7B952D369142'"
            [available]="{ comments: true }"
          ></svc-totals-status>
        </div>

        <svc-status-badge
          class="items-start"
          [badgeData]="{
            description: actionPlanStatusName[actionPlan?.statusId],
            type: actionPlanStatusType[actionPlan?.statusId],
            size: 'small'
          }"
        >
        </svc-status-badge>

        <p class="text-default text-xs">
          {{ actionPlan?.delayedDays }} {{ actionPlan?.delayedDays > 1 ? ('dias' | transloco) : ('dia' | transloco) }}
        </p>
      </div>
    </div>
  </div>

  <div class="w-full justify-end items-center" *ngIf="actionsPlans?.length">
    <svc-paginate
      [current]="pageIndex"
      [totalRegisters]="totalRegisters"
      [showPageSize]="false"
      (onPageChanged)="onPageChange($event)"
    >
    </svc-paginate>
  </div>

  <div class="svc-data-loading absolute inset-0 flex items-center justify-center" *ngIf="isLoading && actionsPlans?.length">
    <mat-spinner diameter="30"></mat-spinner>
  </div>
</div>

<div class="flex items-center justify-center py-30" *ngIf="isLoading && !actionsPlans?.length">
  <mat-spinner diameter="30"></mat-spinner>
</div>