import { ChangeDetectorRef, Component, ElementRef, Inject, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslocoService } from '@ngneat/transloco';
import { ISvcCarousel, ISvcChipGroupedOption, ISvcChipOption, ISvcUserAvatar, SvcCarouselComponent, SvcToastService, UserAvatarSize } from 'projects/lib-shared-component/src/public-api';
import { StaticApplicationId, SvcViewsService, User, UserEnvironment, UserService, SvcGlobalDataPrefetching } from 'projects/lib-shared-core/src/public-api';
import { catchError, finalize, forkJoin, Subject, Subscription, takeUntil, tap } from 'rxjs';
import { DialogAlertTabs, DialogAlertType } from './shared/enums/dialog-alert-type.enum';
import { UserRoleALert } from './shared/enums/user-role-alert.enum';
import { AlertAttachmentMedias } from './shared/interfaces/alert-attachment.interface';
import { AlertCombos } from './shared/interfaces/alert-combos-data.interface';
import { AlertBodyCreate, AlertData, AlertResponseCreate } from './shared/interfaces/alert.interface';
import { DialogAlert } from './shared/interfaces/dialog-alert.interface';
import { AlertAttachmentService } from './shared/services/alert-attachment.service';
import { AlertCombosService } from './shared/services/alert-combos.service';
import { AlertErrorService } from './shared/services/alert-error.service';
import { AlertService } from './shared/services/alert.service';
import { SvcDialogPrimaryComponent } from 'projects/lib-shared-component/src/lib/svc-dialogs/components/svc-dialog-primary/svc-dialog-primary.component';
import { UserRoleItem } from '../../admin/svc-access-permission/models/user-role.model';
import { SvcAccessPermissionService } from '../../admin/svc-access-permission/svc-access-permission.service';
import { SvcPrinterService } from '../../general/svc-printer/svc-printer.service';
import { AlertApplicationFeed } from './shared/interfaces/alert-application-feed.interface';
import { AlertApplicationFeedService } from './shared/services/alert-application-feed.service';
import { SvcPrinterElement } from '../../general/svc-printer/svc-printer-classes';
import { environment } from 'projects/environments/environment';

@Component({
  selector: 'svc-modal-dialog-alert',
  templateUrl: './svc-modal-alert.component.html',
  styleUrls: ['./svc-modal-alert.component.scss']
})
export class SvcModalAlertComponent implements OnInit, OnDestroy {

  @ViewChild('inputFile', { static: false }) fileInput: ElementRef;
  @ViewChild(SvcDialogPrimaryComponent) dialog: SvcDialogPrimaryComponent;
  @ViewChild('carousel') carousel: SvcCarouselComponent;
  @ViewChild('header') header: ElementRef<HTMLElement>;
  @ViewChild('topInfo') topInfo: ElementRef<HTMLElement>;
  @ViewChild('alertTab') alertTab: ElementRef<HTMLElement>;
  @ViewChild('actionPlanTab') actionPlanTab: ElementRef<HTMLElement>;
  @ViewChild('feedTab') feedTab: ElementRef<HTMLElement>;

  private readonly limitSizeFile: number = 10485760;
  private readonly limitSizeTotal: number = 104857600;
  private readonly limitQuantityFiles: number = 10;

  public env = environment;
  public configType: string;
  public environment: UserEnvironment = this._userService.environment;
  public isLoading: boolean = true;
  public formAlert: FormGroup;
  public tabIndex: number = 0;
  public alert: AlertData;
  public midiasCarousel: ISvcCarousel[] = [];
  public featureName = 'view-alert'
  public acceptFiles: string[] = ['image/png', 'image/jpg', 'image/jpeg', 'video/mp4', 'video/mov', 'video/3pg', 'video/webm'];
  public userAvatar: ISvcUserAvatar;
  public filesToUpload: File[] = [];
  public filesToDelete: number[] = [];
  public alertCombos: AlertCombos = {};
  public userHasPermissionToEdit: boolean;
  public userLoggedId: string = this._userService.userId$;
  public openPlans: number;
  public completePlans: number;
  public dialogAlertTabs = DialogAlertTabs;
  public applicationId = StaticApplicationId.alert;
  public showTabActionPlan: boolean;
  public isReactionLoading = false;
  public isReactionDone = false;

  public showVoiceToTextButton: boolean;
  public isFailuresLoading = false;
  public isShowTabActionPlanLoading = true;
  public isShowVoiceToTextButtonLoading = true;
  public isMaterialsLoading = false;
  public isAllCombosLoading = true;
  public rolesCreatePermission: string[] = [UserRoleALert.Administrator, UserRoleALert.Analyst];

  private destroy$: Subject<void> = new Subject<void>();
  private getFailuresSubscription: Subscription;
  private getMaterialSubscription: Subscription;
  private getRoleByUserSubscription: Subscription;

  constructor(
    public dialogRef: MatDialogRef<SvcModalAlertComponent>,
    @Inject(MAT_DIALOG_DATA) public alertMode: DialogAlert,
    private _userService: UserService,
    private _translocoService: TranslocoService,
    private _fb: FormBuilder,
    private _toastService: SvcToastService,
    private _cd: ChangeDetectorRef,
    private _alertCombosService: AlertCombosService,
    private _alertErrorService: AlertErrorService,
    private _alertService: AlertService,
    private _alertAttachmentService: AlertAttachmentService,
    private _accessPermissionService: SvcAccessPermissionService,
    private _svcPrinterService: SvcPrinterService,
    private _alertApplicationFeedService: AlertApplicationFeedService,
    private _svcViewsService: SvcViewsService,
    private _globalDataPrefetching: SvcGlobalDataPrefetching,
  ) {
    this.dialogRef.disableClose = true;
    dialogRef.addPanelClass('md:py-5');
  }

  public ngOnInit(): void {
    this.initFormAlert();
    this.setUserAvatar();
    this.getAlertById();
    this.getPermissionsUser();
    this.getShowTabActionPlan();
    this.getShowVoiceToText()
    this.getFormAllCombos();
    this.getConfigType()
    this.registerView()
  }

  public get isViewMode(): boolean {
    return this.alertMode.mode === DialogAlertType.view;
  }

  public get isEditMode(): boolean {
    return this.alertMode.mode === DialogAlertType.edit;
  }

  public get isAddMode(): boolean {
    return this.alertMode.mode === DialogAlertType.add;
  }

  private getPermissionsUser(): void {
    if (this.isViewMode)
      this.getRoleByUserSubscription?.unsubscribe();
    this.getRoleByUserSubscription = this._accessPermissionService.getRolesByUser(StaticApplicationId.alert)
      .subscribe({
        next: (userRoles: UserRoleItem[]) => {
          this.userHasPermissionToEdit = userRoles.some((userRole: UserRoleItem) =>
            userRole.roleId === UserRoleALert.Administrator || userRole.roleId === UserRoleALert.Analyst
          );
        },
        error: err => this._alertErrorService.showErrorInToast(err)
      });
  }

  private initFormAlert(): void {
    this.formAlert = this._fb.group({
      originatorId: [this._userService.userId$, Validators.required],
      alertId: [null],
      createDate: [new Date(), Validators.required],
      occurrenceDate: [null, Validators.required],
      teamId: [null],
      locationId: [null, Validators.required],
      classificationId: [null, Validators.required],
      failureId: [null, Validators.required],
      materialTypeId: [null],
      productId: [null],
      supplierId: [null],
      hashtagIds: [null],
      description: [null]
    });

    if (!this.isViewMode)
      this.formValueChanges();
  }

  private formValueChanges(): void {
    this.formAlert.get('classificationId').valueChanges.pipe(takeUntil(this.destroy$)).subscribe({
      next: (classificationId: number[]) => {
        if (classificationId?.length && !this.isViewMode)
          this.getFailures(classificationId[0]);
        else if (!this.isViewMode) {
          this.formAlert?.controls?.failureId?.reset();
          this.alertCombos.failures = [];
        }
      }
    });

    this.formAlert.get('materialTypeId').valueChanges.pipe(takeUntil(this.destroy$)).subscribe({
      next: (materialTypeId: number[]) => {
        if (materialTypeId?.length && !this.isViewMode)
          this.getMaterials(materialTypeId[0]);
        else if (!this.isViewMode) {
          this.formAlert?.controls?.productId?.reset();
          this.alertCombos.materials = [];
        }
      }
    })
  }

  private getFailures(classificationId: number): void {
    this.isFailuresLoading = true;
    this.getFailuresSubscription?.unsubscribe();
    this.getFailuresSubscription = this._alertCombosService.getFailures(classificationId)
      .pipe(
        tap((failures: ISvcChipOption[]) => this.alertCombos = { ...this.alertCombos, failures }),
        catchError(err => {
          this._alertErrorService.showErrorInToast(err);
          return err;
        }),
        finalize(() => {
          if (this.isViewMode && this.alert?.productId) {
            this.getMaterials(null, this.alert?.productId);
          }
          this.isFailuresLoading = false;
        })
      ).subscribe();
  }

  private getMaterials(materialTypeId: number, productId?: number): void {
    this.isMaterialsLoading = true;
    if (materialTypeId) {
      this._alertCombosService.materialTypeId = materialTypeId;
    }
    this.getMaterialSubscription?.unsubscribe();
    this.getMaterialSubscription = this._alertCombosService.getMaterial(materialTypeId, productId)
      .pipe(
        tap((materials: ISvcChipOption[]) => {
          this.alertCombos = { ...this.alertCombos, materials };
          if (!materialTypeId) {
            this.formAlert?.get('materialTypeId').setValue(
              [this._alertCombosService.materialTypeId],
              { emitEvent: false }
            );
          }
        }),
        catchError(err => {
          this._alertErrorService.showErrorInToast(err);
          return err;
        }),
        finalize(() => {
          this.isMaterialsLoading = false;
        })
      ).subscribe();
  }

  private setUserAvatar(): void {
    if (DialogAlertType.add === this.alertMode.mode) {
      const user: User = this._userService.user;
      this.userAvatar = {
        name: user.firstLastName,
        picture: user.pictureFile,
        initialsColor: user.initialsColor,
        size: UserAvatarSize.Table
      };
    }
  }

  private getConfigType(): void {
    if (this.isAddMode) return;
    this._alertApplicationFeedService.getApplicationFeed().subscribe((res: AlertApplicationFeed) => {
      this.configType = res.feedKey
    })
  }

  private goToSlide(index: number = 0): void {
    this._cd.detectChanges();
    this.carousel.goToSlide(index);
  }

  public removeSlide(media: ISvcCarousel): void {
    const indexToRemove: number = this.midiasCarousel.findIndex((midia: ISvcCarousel) => midia.url === media.url);

    if (indexToRemove !== -1) {
      if (this.isEditMode && this.midiasCarousel[indexToRemove]?.id)
        this.filesToDelete.push(this.midiasCarousel[indexToRemove].id);

      this.midiasCarousel.splice(indexToRemove, 1);
      this.filesToUpload.splice(indexToRemove, 1);

      if (this.midiasCarousel?.length === 0)
        this.midiasCarousel = [];

      this.fileInput.nativeElement.value = null;
    }
  }

  private fileTypeIsInvalid(files: FileList): boolean {
    const isAccept: boolean = Array.from(files).every((file: File) =>
      this.acceptFiles.includes(file.type)
    );

    if (!isAccept) {
      this._toastService.error(
        this._translocoService.translate('Tipo de arquivo não permitido')
      );
    }

    return !isAccept;
  }

  private getTotalFilesSize(): number {
    return this.filesToUpload.reduce((totalSize: number, file: File) => totalSize + file.size, 0);
  }

  private filesExceededLimit(files: FileList): boolean {
    let totalSize: number = this.getTotalFilesSize();
    let exceedingLimit = false;

    Array.from(files).forEach((file: File) => {
      totalSize += file.size;

      const messageFile: string = totalSize >= this.limitSizeTotal ? 'O tamanho total dos arquivos excede o limite de 100 Megabytes' : 'O tamanho do arquivo excede o limite de 10 Megabytes';

      if (totalSize >= this.limitSizeTotal || file.size >= this.limitSizeFile) {
        this._toastService.error(
          this._translocoService.translate(messageFile)
        );
        exceedingLimit = true;
        return;
      }
    });

    return exceedingLimit;
  }

  public filesExceededQuantity(files: FileList): boolean {
    const exceededQuantity: boolean = files?.length >= this.limitQuantityFiles;

    if (exceededQuantity)
      this._toastService.error(
        this._translocoService.translate('Limite de arquivos atingidos')
      );

    return exceededQuantity;
  }
  public filesIsInvalid(files: FileList): boolean {
    return !files || files.length === 0 || this.fileTypeIsInvalid(files) || this.filesExceededLimit(files) || this.filesExceededQuantity(files);
  }

  public addMediaInputFile(event: Event): void {
    const files: FileList = (event.target as HTMLInputElement).files;

    if (this.filesIsInvalid(files)) return;

    Array.from(files).forEach((file: File, index: number) =>
      this.appendMediaFile(file, files.length - 1 === index)
    );
  }

  public print() {
    const imgs = this.midiasCarousel.filter((m) => !m.type || m.type === 'image');
    this._svcPrinterService.open({
      options: [
        { name: 'Header', elements: [this.dialog.dialogTitleContainer?.nativeElement], show: false, },
        { name: 'TopInfo', elements: [this.topInfo.nativeElement], show: false, },
        {
          name: this._translocoService.translate('Alerta'),
          elements: [this.alertTab.nativeElement],
          selected: true,
        },
        ...(this.actionPlanTab?.nativeElement ? [{
          name: this._translocoService.translate('Plano de ação'),
          elements: [this.actionPlanTab.nativeElement],
          selected: true,
        }] : []),
        {
          name: this._translocoService.translate('Feed'),
          elements: [this.feedTab.nativeElement],
        },
      ],
      additional: {
        options: imgs.length > 0 ? [
          {
            name: this._translocoService.translate('Imagens'),
            areAllImageElements: true,
            elements: imgs.map((m) => {
              const img = document.createElement('img');
              img.src = m.url;
              return <SvcPrinterElement>{
                element: img,
                selected: true,
              };
            })
          }
        ] : null
      },
    });
  }

  private appendMediaFile(file: File, lastIndex: boolean): void {
    const reader = new FileReader();
    reader.onload = (fileReader: ProgressEvent<FileReader>) => {
      if (!this.midiasCarousel?.[0]?.title)
        this.midiasCarousel.splice(0, 1);

      this.midiasCarousel.push({
        title: file.name,
        url: fileReader.target.result as string,
        type: file.type?.includes('video') ? 'video' : 'image'
      });
      this.filesToUpload.push(file);

      if (lastIndex) {
        this.midiasCarousel = [...this.midiasCarousel];
      }
    };
    reader.readAsDataURL(file);
  }

  private updateMidiasCarousel(): void {
    if (this.alert?.attachments?.length)
      this.midiasCarousel = this.alert?.attachments?.map((attachment: AlertAttachmentMedias) => {
        return {
          id: attachment?.attachmentId,
          url: attachment?.signedUrl,
          title: '',
          type: ['.png', '.jpg', '.jpeg', 'png', 'jpg', 'jpeg'].includes(attachment?.extension?.toLowerCase()) ? 'image' : 'video'
        };
      });
  }

  private patchFormAlert(): void {
    this.userAvatar = {
      name: this.alert?.userAvatar?.firstLastName,
      picture: this.alert?.userAvatar?.pictureUrl,
      initialsColor: this.alert?.userAvatar?.preferenceColor,
      size: UserAvatarSize.Table
    };

    if (this.isAllCombosLoading) {
      this.alertCombos = {
        ...this.alertCombos,
        teams: this.alert.team ? [{ text: this.alert.team, value: this.alert.teamId }] : [],
        classification: this.alert.classification ? [{ label: this.alert.classification, value: this.alert.classificationId }] : [],
        materials: this.alert.product ? [{ label: this.alert.product, value: this.alert.productId }] : [],
        suppliers: this.alert.supplier ? [{ label: this.alert.supplier, value: this.alert.supplierId }] : [],
        failures: this.alert.failure ? [{ label: this.alert.failure, value: this.alert.failureId }] : [],
        materialType: this.alert.materialType ? [{ label: this.alert.materialType, value: this.alert.materialTypeId }] : [],
        hashtags: this.alert.hashtags?.length
          ? <ISvcChipGroupedOption[]>[{ group: '', options: this.alert.hashtags.map((h) => ({ label: h.hashtag, value: h.hashtagId })) }]
          : []
      };
    }

    this.updateMidiasCarousel();

    if (this.alert?.materialTypeId) {
      this._alertCombosService.materialTypeId = this.alert.materialTypeId;
    }

    this.formAlert?.patchValue({
      ...this.alert,
      locationId: this.alert?.locationId ? [this.alert?.locationId] : [],
      classificationId: this.alert?.classificationId ? [this.alert?.classificationId] : [],
      teamId: this.alert?.teamId,
      failureId: this.alert?.failureId ? [this.alert?.failureId] : [],
      productId: this.alert?.productId ? [this.alert?.productId] : [],
      supplierId: this.alert?.supplierId ? [this.alert?.supplierId] : [],
      materialTypeId: this._alertCombosService.materialTypeId ? [this._alertCombosService.materialTypeId] : [],
      createDate: this.alert?.createDate,
      occurrenceDate: this.alert?.occurrenceDate,
      hashtagIds: this.alert?.hashtags?.map((h) => h.hashtagId) ?? [],
    });

    if (this.isViewMode) {
      this.formAlert?.disable();
      document.querySelector('body').classList.add('app-dialog-alert-date-picker');
    }
  }

  private getAlertById(): void {
    if ((this.isViewMode || this.isEditMode) && this.alertMode?.id) {
      this.isLoading = true;
      this._alertService.getAlertById(this.alertMode.id)
        .pipe(
          takeUntil(this.destroy$),
          tap((alert: AlertData) => {
            this.alert = alert;
            this.patchFormAlert();

            if (this.alert?.classificationId)
              this.getFailures(this.alert.classificationId);
            else if (this.alert?.productId)
              this.getMaterials(null, this.alert?.productId);

            this.isLoading = false;
          }),
          catchError(err => {
            this.showMessageError(err);
            return err;
          })
        )
        .subscribe();
    }
    else {
      this.isLoading = false;
    }
  }

  private getShowTabActionPlan(): void {
    this.isShowTabActionPlanLoading = true;
    const prefetchingItem = this._globalDataPrefetching.data.alertShowTabActionPlan;
    if (prefetchingItem.isLoading)
      prefetchingItem.isLoading$.pipe(
        tap((showTabActionPlan: boolean) => {
          this.showTabActionPlan = showTabActionPlan;
        }),
        catchError(err => {
          this._alertErrorService.showErrorInToast(err);
          return err;
        }),
        finalize(() => this.isShowTabActionPlanLoading = false),
        takeUntil(this.destroy$),
      ).subscribe();
    else {
      this.showTabActionPlan = prefetchingItem.value;
      this.isShowTabActionPlanLoading = false;
    }
  }

  private getShowVoiceToText(): void {
    this.isShowVoiceToTextButtonLoading = true;
    const prefetchingItem = this._globalDataPrefetching.data.voiceToText;
    if (prefetchingItem.isLoading)
      prefetchingItem.isLoading$.pipe(
        tap((showVoiceToTextButton: boolean) =>
          this.showVoiceToTextButton = showVoiceToTextButton
        ),
        catchError(err => {
          this._alertErrorService.showErrorInToast(err);
          return err;
        }),
        finalize(() => this.isShowVoiceToTextButtonLoading = false),
        takeUntil(this.destroy$)
      ).subscribe();
    else {
      this.showVoiceToTextButton = prefetchingItem.value;
      this.isShowVoiceToTextButtonLoading = false;
    }
  }

  private getFormAllCombos(): void {
    this.isAllCombosLoading = true;
    this._alertCombosService.getAllCombosToRegisterAlert()
      .pipe(
        takeUntil(this.destroy$),
        tap((alertCombos: AlertCombos) => {
          this.alertCombos = {
            ...this.alertCombos,
            ...alertCombos,
          };
        }),
        catchError(err => {
          this._alertErrorService.showErrorInToast(err);
          return err;
        }),
        finalize(() => this.isAllCombosLoading = false),
      ).subscribe();
  }

  private showSuccesMessage(): void {
    const message: string = this.isAddMode ? 'Alerta incluído com sucesso' : 'Alteração salva com sucesso';
    this._toastService.success(
      this._translocoService.translate(message)
    );
    this._alertService.alertAdd$.next();

    if (this.isViewMode) {
      this.dialogRef.close();
    } else {
      this.switchToViewMode();
    }
  }

  private switchToViewMode(): void {
    if (this.isAddMode && this.alertMode.id) {
      this.alertMode.mode = DialogAlertType.view;
      this.getAlertById();
      this.getPermissionsUser();
      this.setModeView();
    } else {
      this.setModeView();
    }
  }

  private setModeView() {
    this.isLoading = false;
    this.alertMode.mode = DialogAlertType.view;
    this.formAlert.updateValueAndValidity();
  }

  private getChipValueByName(property: string): number {
    return this.formAlert?.value?.[property]?.[0];
  }

  private buildPaylodCreationAlert(): AlertBodyCreate {
    const alertBody = {
      ...this.formAlert.value,
      locationId: this.getChipValueByName('locationId'),
      classificationId: this.getChipValueByName('classificationId'),
      failureId: this.getChipValueByName('failureId'),
      productId: this.getChipValueByName('productId'),
      supplierId: this.getChipValueByName('supplierId'),
    };

    delete alertBody?.materialTypeId;
    delete alertBody?._datepickerInternalControl;

    if (this.isAddMode)
      delete alertBody?.alertId;

    return alertBody;
  }

  private showMessageError(error: any): void {
    this._alertErrorService.showErrorInToast(error);
    this.isLoading = false;
  }

  private removeAlertAttachments(): void {
    if (this.filesToDelete?.length) {
      forkJoin(
        this.filesToDelete.map((fileId: number) => {
          return this._alertAttachmentService.removeAlertAttachment(this.alert.alertId, fileId)
        })
      ).pipe(
        takeUntil(this.destroy$),
        tap(() => this.showSuccesMessage()),
        catchError(err => {
          this.showMessageError(err);
          return err;
        })
      ).subscribe();
    }
    else
      this.showSuccesMessage();
  }

  private updateAlertAttachments(alertId: number): void {
    if (this.filesToUpload?.length)
      this._alertAttachmentService.updateAlertAttachment(alertId, this.filesToUpload)
        .pipe(
          takeUntil(this.destroy$),
          catchError(err => {
            this.showMessageError(err);
            return err;
          }),
          finalize(() => this.removeAlertAttachments())
        ).subscribe();
    else
      this.removeAlertAttachments();
  }

  private _dispatchEventToLegacy(bodyAlert: AlertBodyCreate, alertResponse: AlertResponseCreate): void {
    const failureName: string = this.alertCombos?.failures?.find((option: ISvcChipOption) =>
      option?.value === bodyAlert?.failureId
    )?.label as string;

    window.parent.dispatchEvent(
      new CustomEvent(
        bodyAlert?.alertId ? 'UpdateAlertCallback' : 'CreateAlertCallback',
        {
          detail: {
            failureName,
            alertId: alertResponse.alertId
          }
        }
      )
    );
  }

  public onBookmarkChanged(value: boolean): void {
    const messageKey = value ? 'adicionado aos' : 'removido dos';
    this._toastService.success(this._translocoService.translate(`Registro ${messageKey} favoritos com sucesso`));
  }

  public createOrUpdateAlert(): void {
    this.formAlert.markAllAsTouched();
    if (this.formAlert.valid) {
      this.isLoading = true;
      const _bodyAlert: AlertBodyCreate = this.buildPaylodCreationAlert();
      this._alertService.createOrUpateAlert(_bodyAlert)
        .pipe(
          takeUntil(this.destroy$),
          tap((alertResponse: AlertResponseCreate) => {
            this._dispatchEventToLegacy(_bodyAlert, alertResponse);
            this.alertMode.id = alertResponse.alertId;
            this.formAlert.get('alertId').setValue(alertResponse.alertId);
            this.updateAlertAttachments(alertResponse.alertId);
          }),
          catchError(err => {
            this.showMessageError(err);
            return err;
          })
        )
        .subscribe();
    }
  }

  public setEditMode(): void {
    if (this.isViewMode) {
      this.alertMode.mode = DialogAlertType.edit;
      this.formAlert.enable();
      this.formAlert.get('failureId').setValue([this.alert?.failureId]);
      this.formAlert.get('productId').setValue([this.alert?.productId]);
      document.querySelector('body').classList.remove('app-dialog-alert-date-picker');

      setTimeout(() => {
        this.formValueChanges();
      }, 1000);
    }
    else
      this.createOrUpdateAlert();
  }

  public goToFeed(): void {
    if (this.tabIndex !== DialogAlertTabs.Feed)
      this.tabIndex = DialogAlertTabs.Feed;
  }

  private registerView(): void {
    if (this.isAddMode) return;
    this._svcViewsService.setViews({
      registryId: this.alertMode?.id.toString(),
      applicationId: StaticApplicationId.alert,
      featureName: 'view-alert'
    }).subscribe({
      next: () => {
        this._alertService.alertUpdated$.next(this.alertMode?.id)
      }
    });
  }

  public ngOnDestroy(): void {
    document.querySelector('body').classList.remove('app-dialog-alert-date-picker');
    this.getFailuresSubscription?.unsubscribe();
    this.getMaterialSubscription?.unsubscribe();
    this.getRoleByUserSubscription?.unsubscribe();
    this.destroy$.next();
    this.destroy$.complete();
  }
}
