<div *screenSizes="let size">
  <div [ngClass]="{'pb-5': customFields.length }">
    <button class="flex items-center gap-2" type="button" (click)="addCustomField()" *ngIf="!isLoading">
      <mat-icon class="text-primary icon-size-5" svgIcon="heroicons_solid:plus-circle"></mat-icon>
      <span class="text-base">{{ 'Campos customizados' | transloco }}</span>
    </button>

    <div class="flex flex-col gap-5" *ngIf="isLoading">
      <div class="flex gap-2 items-center">
        <ngx-skeleton-loader [theme]="{ width: '20px', height: '20px', borderRadius: '100%' }">
        </ngx-skeleton-loader>

        <ngx-skeleton-loader [theme]="{ width: '160px', height: '24px' }">
        </ngx-skeleton-loader>
      </div>

      <div class="flex flex-col gap-4 w-full items-start" [class.pl-6]="!size.isXS">
        <div class="w-full">
          <div class="grid grid-cols gap-4" [ngClass]="{ 'grid-cols-3': !size.isXS }">
            <div class="flex flex-col gap-2" *ngFor="let skeleton of [1, 2]">
              <ngx-skeleton-loader class="h-[18px]" [theme]="{ width: '32px', height: '18px'}">
              </ngx-skeleton-loader>

              <ngx-skeleton-loader class="h-10" [theme]="{ width: '100%', height: '40px', borderRadius: '6px' }">
              </ngx-skeleton-loader>
            </div>

            <div class="flex gap-4 items-start h-full">
              <div class="flex flex-col h-full">
                <ngx-skeleton-loader class="h-[18px]" [theme]="{ width: '60px', height: '18px'}">
                </ngx-skeleton-loader>

                <div class="flex items-center justify-center h-full relative">
                  <ngx-skeleton-loader class="h-[18px]" [theme]="{ width: '18px', height: '18px', borderRadius: '4px'}">
                  </ngx-skeleton-loader>

                  <div class="absolute left-[76px]">
                    <ngx-skeleton-loader class="h-6" [theme]="{ width: '24px', height: '24px', borderRadius: '0' }">
                    </ngx-skeleton-loader>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div [formGroup]="form">
    <div formArrayName="fields" class="w-full flex flex-col gap-4 items-start mb-5"
      [ngClass]="{ 'pl-6': !size.isXS }">
      <div class="w-full" *ngFor="let field of customFields.controls; let i = index" [formGroupName]="i">
        <div class="grid grid-cols gap-4 mb-3" [ngClass]="{ 'grid-cols-3': !size.isXS }">
          <!-- Nome -->
          <svc-text class="w-full" [label]="'Nome' | transloco" formControlName="fieldName" [maxLength]="maxLengthName"></svc-text>

          <!-- Tipo -->
          <div>
            <svc-select [label]="'Tipo' | transloco" [options]="options" formControlName="fieldTypeId" [loading]="isOptionsLoading"></svc-select>

            <!-- Opções (drag-and-drop) -->
            <div class="my-1" *ngIf="field.controls?.fieldTypeId?.value === fieldTypes.List || field.controls?.fieldTypeId?.value === fieldTypes.ListMultiple" formArrayName="options" cdkDropList
              (cdkDropListDropped)="drop($event, i)"
              [cdkDropListDisabled]="field.controls?.options?.length === 1" class="boundary mt-4">

              <div *ngFor="let option of field.controls?.options?.controls; let j = index" [formGroupName]="j"
                cdkDragLockAxis="y" cdkDragBoundary=".boundary" cdkDrag
                class="flex gap-4 items-center mt-2 cursor-auto"
                [ngClass]="{'cursor-move': field.controls?.options?.length > 1 }">

                <div class="custom-placeholder" *cdkDragPlaceholder></div>

                <mat-icon class="text-primary icon-size-6 rotate-45" svgIcon="heroicons_solid:arrows-pointing-out"></mat-icon>

                <svc-text [ngClass]="{ 'w-full' : size.isXS, 'w-52': !size.isXS }" formControlName="fieldOption"></svc-text>

                <button type="button" (click)="removeOption(i, j)">
                  <mat-icon class="text-primary icon-size-6 mr-1" svgIcon="heroicons_solid:trash"></mat-icon>
                </button>
              </div>

              <button class="flex items-center gap-2 mt-2 w-28 pl-8 bg-transparent" type="button"
                (click)="addOption(i)">
                <mat-icon class="icon-size-4 text-primary" svgIcon="heroicons_solid:plus"></mat-icon>
                <p class="text-sm font-semibold text-primary cursor-pointer">{{ 'Opção' | transloco }}</p>
              </button>
            </div>
          </div>

          <!-- Obrigatório e Remover -->
          <div class="flex gap-4 items-start mt-2">
            <div class="flex flex-col align-middle">
              <mat-label class="input-label-size">{{ "Obrigatório" | transloco }}</mat-label>
              <mat-checkbox class="pr-0" color="primary" formControlName="mandatory"></mat-checkbox>
            </div>

            <div class="flex mt-6">
              <button type="button" (click)="removeCustomField(i)">
                <mat-icon class="text-primary icon-size-6" svgIcon="heroicons_solid:trash"></mat-icon>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
 </div>
