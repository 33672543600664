export interface SvcMediaCarouselItem {
  id?: number;
  url: string;
  type: 'image' | 'video';
  name?: string;
}

export interface SvcMediaCarouselInternalItem extends SvcMediaCarouselItem {
  fileLoading?: boolean;
  base64Url?: string;
}