import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppEnvironmentConfig } from 'projects/config/model/environment.config.model';
import { getAccessTokenPayloadInfo, SvcHttpClient } from 'projects/lib-shared-common/src/public-api';
import { CommonPagination } from 'projects/lib-shared-model/src/public-api';
import { BehaviorSubject, Observable, Subject, Subscription, finalize, forkJoin, map, tap } from 'rxjs';
import { HubConnection, HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import { AuthService } from 'projects/lib-shared-core/src/public-api';
import { TranslocoService } from '@ngneat/transloco';

export interface SvcAlertBarBanner {
  bannerId: number;
  message: string;
  note: string;
  bannerTypeId: number;
  bannerTypeName: string;
  displayUntilDate: string;
  allEnvironments: boolean;
  createUserId: string;
  createDate: string;
  lastUpdateDate: string;
  updateUserId: string;
  active: boolean;
  environments: SvcAlertBarBannerEnvironment[];
  createUserAvatar: SvcAlertBarUserAvatar;
  updateUserAvatar: SvcAlertBarUserAvatar;
}

interface SvcAlertBarUserAvatar {
  userId: string;
  firstLastName: string;
  preferenceColor: string;
  pictureUrl: string;
}
interface SvcAlertBarBannerEnvironment {
  environmentId: number;
  environmentName: string;
  environmentURL: string;
  active: boolean;
}

@Injectable()
export class SvcAlertBarService extends SvcHttpClient {

  private _alertBarSubscription: Subscription;
  private _alertBarHubConnection: HubConnection;
  private _newAlertBarReceived = new Subject<any>();
  public get newFeedReceived$() { return this._newAlertBarReceived.asObservable(); }

  private _alertBar = new BehaviorSubject<SvcAlertBarBanner>({active: false} as SvcAlertBarBanner)
  public get alertBar$() { return this._alertBar.asObservable(); }

  constructor(
    protected _appConfig: AppEnvironmentConfig,
    protected _httpClient: HttpClient,
    protected _authService: AuthService,
    protected _translocoService: TranslocoService,
  ) {
    super(
      _appConfig.APIs.apiUrlAdministration,
      _httpClient
    );
  }

  public getBannerActive() : Observable<SvcAlertBarBanner> {
    this._alertBarSubscription?.unsubscribe();
    return this.get<SvcAlertBarBanner>('/Banner/Active', {}).pipe(
      tap((response) => {
        return response;
      })
    );
  }


  public startListenSignalR() {
    this._alertBarHubConnection = new HubConnectionBuilder()
      .withUrl(`${this._appConfig.APIs.apiUrlAdministration}/bannerHub`, {
        accessTokenFactory: () => this._authService.accessToken,
      })
      .withAutomaticReconnect()
      .configureLogging(LogLevel.Information)
      .build();

    this._alertBarHubConnection.onclose(async () => {
      this.startListenSignalR();
    });

    this._alertBarHubConnection.onreconnecting((error) => {
      console.log(`[AlertBarSignalR] Connection lost due to error ${error}. Reconnecting.`);
    });

    this._alertBarHubConnection.on('BroadcastBanner', (item: any) => {
      item.banner.active = true;
      this._alertBar.next(item.banner);
    });

    this._alertBarHubConnection.start()
      .catch(err => console.error('Error while starting BannerHub connection in AlertBar: ' + err));
  }
}
