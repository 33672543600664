<div 
  class="fadeIn flex flex-col sm:flex-row items-center sm:justify-center flex-auto min-w-0 h-full overflow-auto" 
  [ngClass]="{ 'md:items-start': !isSmScreen, 'items-center': isSmScreen }"
  *transloco="let t"
>
  <div 
    class="relative flex-auto items-center justify-center h-full bg-[#17457A]"
    [ngClass]="{ 'hidden': isSmScreen, 'md:flex': !isSmScreen }"
  >
    <div class="w-full h-full bg-cover" [style.background-image]="backgroundImg"></div>
  </div>
  <div 
    class="flex flex-col w-full h-full rounded-none shadow-none bg-card relative overflow-y-auto"
    [ngClass]="{ 'md:w-120': !isSmScreen }"
  >
    <div class="flex flex-col w-full min-h-full px-4 sm:px-12 md:px-16">
      <div class="pt-8 -ml-4">
        <!-- language -->
        <languages [hasUser]="false" [onlyImage]="false"></languages>
      </div>
  
      <div
        class="-mt-11 flex-auto flex flex-col justify-center self-center w-full max-w-[360px] py-8 sm:py-12 md:py-16 md:pt-24"
        [ngClass]="{ 'max-w-[520px] md:py-16 md:pt-24': !isSmScreen }"
      >
  
        <!-- Logo -->
        <div class="mt-8 px-4">
          <img class="w-full" [src]="solvaceLogo$ | async"/>
        </div>
  
        <!-- Title -->
        <div class="flex justify-center my-10 text-5xl font-extrabold tracking-tight leading-tight text-[#17457A]">
          {{ t("Bem vindo") }}
        </div>
  
        <!-- Alert -->
        <svc-alert
          class="mb-3"
          *ngIf="showAlert && !loading"
          [appearance]="alert.appearance"
          [showIcon]="passwordExpire"
          [type]="alert.type"
          [@shake]="alert.type === 'error'"
        >
          {{ alert.message }}
        </svc-alert>
  
        <ng-container *ngIf="!privateCurrentUser">
          <!-- SSO buttons -->
          <div *ngFor="let sso of environmentInfo?.ssoItems" class="flex items-center space-x-4 pb-3">
            <button
              type="button"
              (click)="loginSSO(sso)"
              class="svc-mat-button-large w-full bg-[#17457A] text-white"
              mat-flat-button
              id="enter-with-button"
            >
              {{ t("Entrar com") }} {{ sso.name }}
            </button>
          </div>
  
          <!-- Separator -->
          <div class="flex items-center my-3" *ngIf="environmentInfo?.ssoItems?.length??0 > 0">
            <div class="flex-auto mt-px border-t-2 border-gray-300"></div>
            <div class="mx-2 text-secondary font-bold">
              {{ t("ou") }}
            </div>
            <div class="flex-auto mt-px border-t-2 border-gray-300"></div>
          </div>
        </ng-container>
  
        <ng-container *ngIf="privateCurrentUser">
          <button
            type="button"
            (click)="signInWithCurrentUser()"
            class="svc-mat-button-large w-full bg-[#17457A] text-white text-ellipsis"
            mat-flat-button
            id="continue-button"
          >
            <ng-container *ngIf="!quickSignInInProcess">
              {{ t("Continuar como") }} {{ privateCurrentUser.username }}
            </ng-container>
            <mat-progress-spinner *ngIf="quickSignInInProcess" [diameter]="24" [mode]="'indeterminate'"></mat-progress-spinner>
          </button>
  
          <button
            type="button"
            (click)="changeLoginUser()"
            class="svc-mat-button-large w-full mt-6 bg-zinc-100 text-black text-ellipsis"
            mat-flat-button
            id="other-user-button"
          >
            <ng-container *ngIf="!quickSignInInProcess">
              {{ t("Entrar com um usuário diferente") }}
            </ng-container>
            <mat-progress-spinner  *ngIf="quickSignInInProcess" [diameter]="24" [mode]="'indeterminate'"></mat-progress-spinner>
          </button>
  
        </ng-container>
  
        <div *ngIf="isEnvironmentInfoLoading" class="pt-10 pb-50 text-[#17457A]">
          <mat-spinner class="m-auto text-current" diameter="30"></mat-spinner>
        </div>
  
        <!-- Sign in form (global)-->
        <form *ngIf="userEnviromentSingInForm && !hasEnviromentInfoByOriginPath && !isEnvironmentInfoLoading" class="flex flex-col space-y-6" [formGroup]="userEnviromentSingInForm">
  
          <!-- Email field -->
          <svc-text class="w-full"
            [label]="t('Usuário')"
            [formControl]="userEnviromentSingInForm.controls.user"
            [readonly]="environmentUserInfoList"
            inputId="username-field"
          ></svc-text>

          <ng-container *ngIf="environmentUserInfoList">
            <svc-select class="w-full"
              [label]="t('Ambiente')"
              [formControl]="userEnviromentSingInForm.controls.environment"
              [options]="environmentUserInfoList"
              inputId="environment-field"
            ></svc-select>
          </ng-container>

          <!-- Submit button env -->
          <button
            type="button"
            class="w-full mt-6 bg-[#17457A] text-white"
            mat-flat-button
            (click)="signInEnviromentUserInfo()"
            id="signin-button"
            *ngIf="!environmentUserInfoList || userEnviromentSingInForm.controls.environment.valid"
          >
            <ng-container *ngIf="!userEnviromentSingInForm.disabled">
              {{ t("Entrar") }}
            </ng-container>
            <mat-progress-spinner *ngIf="userEnviromentSingInForm.disabled" [diameter]="24" [mode]="'indeterminate'"></mat-progress-spinner>
          </button>

          <!-- Submit button env -->

          <ng-container *ngIf="environmentUserInfoList">
            <button
              type="button"
              (click)="changeLoginUser()"
              class="svc-mat-button-large w-full mt-6 bg-zinc-100 text-black text-ellipsis"
              mat-flat-button
              [disabled]="userEnviromentSingInForm.disabled"
              id="other-user-button"
            >
              <ng-container *ngIf="!userEnviromentSingInForm.disabled">
                {{ t("Entrar com um usuário diferente") }}
              </ng-container>
              <mat-progress-spinner *ngIf="userEnviromentSingInForm.disabled" [diameter]="24" [mode]="'indeterminate'"></mat-progress-spinner>
            </button>
          </ng-container>
        </form>
  
        <!-- Sign in form (local) -->
        <form *ngIf="signInForm && hasEnviromentInfoByOriginPath && !privateCurrentUser && !isEnvironmentInfoLoading" class="flex flex-col space-y-6" [formGroup]="signInForm">
  
          <!-- Email field -->
          <svc-text class="w-full"
            [label]="t('Usuário')"
            [formControl]="signInForm.controls.user"
            [readonly]="environmentUserInfoList"
            inputId="username-field"
          ></svc-text>
  
          <!-- Password field -->
          <svc-text class="w-full"
            [label]="t('Senha')"
            [formControl]="signInForm.controls.password"
            [readonly]="environmentUserInfoList"
            [isObscure]="true"
            type="password"
            inputId="password-field"
          ></svc-text>
  
          <!-- Submit button -->
          <button
            type="submit"
            class="w-full mt-6 bg-[#17457A] text-white"
            mat-flat-button
            [disabled]="signInForm.disabled && passwordExpire !== 'expiring'"
            (click)="signInWithEnviromentInfo()"
            id="signin-button"
          >
            <ng-container *ngIf="!signInForm.disabled || passwordExpire && !loading">
              {{ t("Entrar") }}
            </ng-container>
            <mat-progress-spinner *ngIf="signInForm.disabled && loading" [diameter]="24" [mode]="'indeterminate'"></mat-progress-spinner>
          </button>

          <ng-container *ngIf="passwordExpire && !loading">
            <button
              type="button"
              (click)="onRefresh()"
              class="svc-mat-button-large w-full mt-6 bg-zinc-100 text-black text-ellipsis"
              mat-flat-button
              [disabled]="userEnviromentSingInForm.disabled"
              id="other-user-button-local"
              *ngIf="privateGlobalLogin"
            >
              <ng-container *ngIf="!userEnviromentSingInForm.disabled">
                {{ t("Entrar com um usuário diferente") }}
              </ng-container>
              <mat-progress-spinner *ngIf="userEnviromentSingInForm.disabled" [diameter]="24" [mode]="'indeterminate'"></mat-progress-spinner>
            </button>
          </ng-container>
        </form>
        <!-- Actions -->
        <div class="inline-flex items-end justify-end w-full mt-4" *ngIf="environmentInfo">
          <a class="text-[15px] font-bold text-[#17457A] hover:underline" [routerLink]="['/forgot-password']">
            {{ t("Esqueceu a senha?") }}
          </a>
        </div>
      </div>
    </div>
  </div>

</div>
