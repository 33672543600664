import { NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatRippleModule } from '@angular/material/core';
import { TranslocoModule } from "@ngneat/transloco";
import { SvcImagesViewerModalComponent } from './svc-images-viewer-modal.component';
import { LibSharedCommonModule } from 'projects/lib-shared-common/src/public-api';
import { SvcMediaCarouselModule } from '../../svc-media-carousel/svc-media-carousel.module';

@NgModule({
  declarations: [
    SvcImagesViewerModalComponent,
  ],
  imports: [
    LibSharedCommonModule, 
    MatTooltipModule,
    MatRippleModule,
    MatIconModule,
    MatDialogModule,
    SvcMediaCarouselModule,
    TranslocoModule,
  ],
  exports: [
    SvcImagesViewerModalComponent
  ],
})
export class SvcImagesViewerModalModule { }
