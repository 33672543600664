import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { AppEnvironmentConfig } from 'projects/config/model/environment.config.model';
import { getAccessTokenPayloadInfo, isNullOrUndefined, setCookie } from 'projects/lib-shared-common/src/public-api';
import { BehaviorSubject, Observable, Subject, catchError, finalize, forkJoin, map, merge, of, switchMap, tap } from 'rxjs';
import { AWS_DEFAULT_BACKGROUND_IMAGE, AWS_DEFAULT_LOGO, AWS_DEFAULT_LOGO_NG } from '../../../Constants/AWS-IMAGES';
import { SettingsService } from '../../settings/services/settings.service';
import { IUserAvatar } from "../models/user-avatar";
import { UserHttpClient } from "../user-http-client";
import { User, UserEnvironment } from './user.types';
import { SvcAppSettings } from '../../../settings/svc-app-settings';
import { EnvironmentInfo } from '../../../auth/model/environment.info.model';
import { ParameterService } from '../../../services/parameter/parameter.service';
import { SvcFeatureToggleService } from '../../../services/svc-feature-toggle/svc-feature-toggle.service';
import { LanguagesService } from '../../languages/services/languages.service';
import { SvcDataCacheService } from '../../../services/cache/svc-data-cache.service';
import { SvcCacheName } from '../../../services/cache/svc-cache-name.enum';
import { AuthService } from '../../../auth/auth.service';
import { SvcDelimiterType } from '../../../settings/enums/svc-delimiter-type.enum';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private _user$ = new BehaviorSubject<User>(null);
  public user$ = this._user$.asObservable();
  
  private _version$ = new BehaviorSubject<string>(null);
  public version$ = this._version$.asObservable();

  private set _environmentCached(value: EnvironmentInfo) {
    this._dataCacheService.set(SvcCacheName.ENVIRONMENT_INFO, value, { hours: 4 });
  }
  private get _environmentCached(): EnvironmentInfo {
    return this._dataCacheService.get(SvcCacheName.ENVIRONMENT_INFO);
  }

  private set _currencyCached(value: { currencyName: string, currencySymbol: string }) {
    this._dataCacheService.set(SvcCacheName.CURRENCY, value);
  }
  private get _currencyCached(): { currencyName: string, currencySymbol: string } {
    return this._dataCacheService.get(SvcCacheName.CURRENCY);
  }
  
  /**
   * Constructor
   */
  constructor(
    private _httpClient: HttpClient,
    private _appConfig: AppEnvironmentConfig,
    private _authService: AuthService,
    private _translocoService: TranslocoService,
    private _settingsService: SettingsService,
    private _userHttpClient: UserHttpClient,
    private _svcAppSettgins: SvcAppSettings,
    private _parameterService: ParameterService,
    private _featureToggleService: SvcFeatureToggleService,
    private _languagesService: LanguagesService,
    private _dataCacheService: SvcDataCacheService,
  ) {
    merge(
      this._authService.signIn$,
      this._authService.signOut$,
    ).pipe(
      tap(() => this._user$.next(null)),
    ).subscribe();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Setter & getter for user
   *
   * @param value
   */
  set user(value: User) {
    this._user$.next(value);
  }
  get user(): User { return this._user$.value; }

  get environment(): UserEnvironment {
    return {
      backgroundImage: this._environmentCached?.backgroundImage || AWS_DEFAULT_BACKGROUND_IMAGE,
      logoImageNegative: this._environmentCached?.logoImageNegative || AWS_DEFAULT_LOGO_NG,
      logoImage: this._environmentCached?.logoImage || AWS_DEFAULT_LOGO,
      colorBase: this._environmentCached?.colorBase,
      url: this._environmentCached?.url,
    };
  }

  get userId$(): string {
    return getAccessTokenPayloadInfo('UserId')?.toLocaleLowerCase();
  }

  private _featureToggles: Subject<SvcFeatureToggleService>;
  private _versionPendingRequest: Subject<void>;

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Get the current logged in user data
   */
  get(): Observable<User> {
    const user = this._user$.value;
    const hasCachedUser = !!(user);

    return (
      hasCachedUser
        ? of(user)
        : this._httpClient.get<User>(`${this._appConfig.APIs.apiUrlUsers}/user/session`)
    ).pipe(
      tap((user) => {
        this._svcAppSettgins.setDateFormat(user.dateFormat);
        this._svcAppSettgins.setDelimiterSymbol(isNullOrUndefined(user.delimiter) ? SvcDelimiterType.COMMA : user.delimiter);
        setCookie('D3A4C9FB40C10D86B49EE9836EB586E8', user.delimiter ?? 1);
      }),
      switchMap(user => {
        if (hasCachedUser) {
          return of(user);
        }
        this._translocoService['cache'] = new Map();
        return this._languagesService.getAll().pipe(
          switchMap((langs) => {
            const languages = langs.map((lang) => ({ id: lang.languageTag, label: lang.languageTranslated }));
            this._translocoService.setAvailableLangs(languages);
            return forkJoin([
              this._translocoService.load(user.languageTag),
              this.getFeatureToggles(),
            ]);
          }),
          map(() => user)
        );
      }),
      map((user) => {
        if (!hasCachedUser) {
          this._translocoService.setActiveLang(user.languageTag);
        }
        this.user = user;
        this.getVersion();
        return user;
      }),
    );
  }

  getFeatureToggles(): Observable<SvcFeatureToggleService> {
    if (!this._featureToggles) {
      this._featureToggles = new Subject<SvcFeatureToggleService>();
      let isGlobalLoading = true;
      let isSiteLoading = true;
      this._parameterService.getGlobalParams(['FEED_ENABLE', 'POST_ENABLE', 'APP_OFFLINE_ENABLE', 'COMMUNICATION_ENABLE']).pipe(
        tap((res) => {
          const feedIsEnabled = (res.find(p => p.key === 'FEED_ENABLE')?.value ?? 'FALSE').toString().toLocaleLowerCase();
          const postIsEnabled = (res.find(p => p.key === 'POST_ENABLE')?.value ?? 'FALSE').toString().toLocaleLowerCase();
          const communicationIsEnabled = (res.find(p => p.key === 'COMMUNICATION_ENABLE')?.value ?? 'FALSE').toString().toLocaleLowerCase();
          const appOffilineIsEnabled = (res.find(p => p.key === 'APP_OFFLINE_ENABLE')?.value ?? 'FALSE').toString().toLocaleLowerCase();
          this._featureToggleService.setFeedIsEnabled(typeof feedIsEnabled === 'boolean' ? feedIsEnabled : feedIsEnabled === 'true');
          this._featureToggleService.setPostIsEnabled(typeof postIsEnabled === 'boolean' ? postIsEnabled : postIsEnabled === 'true');
          this._featureToggleService.setCommunicationIsEnabled(typeof communicationIsEnabled === 'boolean' ? communicationIsEnabled : communicationIsEnabled === 'true');
          this._featureToggleService.setAppOffilineEnabled(typeof appOffilineIsEnabled === 'boolean' ? appOffilineIsEnabled : appOffilineIsEnabled === 'true');
        }),
        catchError(() => {
          this._featureToggleService.setFeedIsEnabled(false);
          this._featureToggleService.setPostIsEnabled(false);
          this._featureToggleService.setCommunicationIsEnabled(false);
          this._featureToggleService.setAppOffilineEnabled(false);
          return of(this._featureToggleService);
        }),
        finalize(() => {
          isGlobalLoading = false;
          if (!isSiteLoading) {
            this._featureToggles.next(this._featureToggleService);
            this._featureToggles.complete();
            this._featureToggles.closed = true;
          }
        }),
      ).subscribe();

      this._parameterService.getSiteParam('COPILOT_ENABLE').pipe(
        tap((res) => {
          const copilotIsEnabled = (res ?? 'FALSE').toString().toLocaleLowerCase();
          this._featureToggleService.setCopilotIsEnabled(typeof copilotIsEnabled === 'boolean' ? copilotIsEnabled : copilotIsEnabled === 'true');
        }),
        catchError(() => {
          this._featureToggleService.setCopilotIsEnabled(false);
          return of(this._featureToggleService);
        }),
        finalize(() => {
          isSiteLoading = false;
          if (!isGlobalLoading) {
            this._featureToggles.next(this._featureToggleService);
            this._featureToggles.complete();
            this._featureToggles.closed = true;
          }
        }),
      ).subscribe();
    }

    

    if (this._featureToggles.closed) {
      return of(this._featureToggleService);
    }
    return this._featureToggles.asObservable();
  }

  getVersion() {
    if (!this._versionPendingRequest) {
      this._versionPendingRequest = new Subject<void>();
      this._parameterService.getGlobalParams(['VERSION_ID']).pipe(
        tap((res) => {
          this._version$.next((res.find(p => p.key === 'VERSION_ID')?.value ?? '00000').toString());
          this._versionPendingRequest.next();
          this._versionPendingRequest.complete();
          this._versionPendingRequest = null;
        })
      ).subscribe();
    }
  }

  getEnvironment(): Observable<UserEnvironment> {
    const fromCached = !!(this._environmentCached);
    return (
      this._environmentCached
        ? of(this._environmentCached)
        : this._httpClient.post<EnvironmentInfo>(`${this._appConfig.APIs.apiUrlAuth}/Environment/info`, { environmentUrl: getAccessTokenPayloadInfo('iss') })
    ).pipe(
      map((info) => {
        if (!fromCached) this._environmentCached = info;
        this._settingsService.handleThemeChange(`svc-${info.colorBase.replace('#', '').toUpperCase()}`);
        return this.environment;
      }),
    );
  }

  getCurrencyAcronym() {
    return (this._currencyCached
      ? of(this._currencyCached)
      : this._httpClient.get<{ currencyName: string, currencySymbol: string }>(`${this._appConfig.APIs.apiUrlAdministration}/Parameter/currency`)
    ).pipe(
      tap((response) => {
        this._currencyCached = response;
        this._svcAppSettgins.setCurrencyAcronym(response.currencySymbol);
      }),
      catchError(() => {
        return of(this._svcAppSettgins.currencyAcronym);
      }),
    );
  }

  update(user: User): Observable<any> {
    return this._httpClient
      .put<User>(`${this._appConfig.APIs.apiUrlUsers}/UserPreference`, user);
  }

  updateUserImg(userImg: any): Observable<any> {

    let formData: FormData = new FormData();
    formData.append('fileData', userImg)

    let headers = new HttpHeaders();
    /** In Angular 5, including the header Content-Type can invalidate your request */
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');

    let params = new HttpParams();

    const options = {
      headers: headers,
      params: params,
      reportProgress: true,
    };

    return this._httpClient
      .put(`${this._appConfig.APIs.apiUrlUsers}/userPreference/picture`, formData, options);
  }

  deleteImg(userImg: any) {

    return this._httpClient
      .delete(`${this._appConfig.APIs.apiUrlUsers}/userPreference/picture`, userImg);
  }

  getAvatar(userId: string): Observable<IUserAvatar> {
    return this._userHttpClient.get(`/User/Avatar`, {
      params: {
        userId: userId
      }
    });
  }
  getUsers(active: boolean = true) {
    return this._userHttpClient.get<{
      userId: string,
      name: string,
      active: boolean
    }[]>(
      `/User/names`,
      { params: { active: active } }
    );
  }

  getUsersInfo(params: { pageSize: number, pageIndex: number, name: string, active: boolean }) {
    return this._userHttpClient.get<{
      users: {
        userId: string,
        name: string,
        site: string,
        isGlobal: boolean
      }[],
      page: {
        pageIndex: number,
        pageSize: number,
        pagesTotal: number,
        registersTotal: number
      }
    }>('/User/Info', { params: { ...params } });
  }

  getUsersInfoById(ids: string[]) {
    return this._userHttpClient.get<{
      userId: string,
      name: string,
      site: string,
      isGlobal: boolean
    }[]>('/User/InfoByIds', {
      params: {
        userId: ids
      }
    })
  }

  getTeams(active: boolean = true) {
    return this._userHttpClient.get<{
      groupId: number,
      groupName: string,
      active: boolean
    }[]>('/Groups/names', {
      params: {
        active: active
      }
    }
    );
  }

  getTeamsInfo(params: {
    pageSize: number,
    pageIndex: number,
    name: string,
    active: boolean
  }): Observable<{
    teams: [
      {
        teamId: number,
        name: string,
        siteId: number,
        siteName: string
      }
    ],
    page: {
      pageIndex: number,
      pageSize: number,
      pagesTotal: number,
      registersTotal: number
    }
  }> {
    return this._userHttpClient.get('/Team', { params: { ...params } });
  }

  getTeamInfo(ids: number[]) {
    return this._userHttpClient.get('/Team/InfoByIds', { params: { teamId: ids } })
  }

  getArea(active: boolean = true) {
    return this._httpClient.get<any[]>(
      `${this._appConfig.APIs.apiUrlUsers}/Area/names`,
      { params: { active: active } }
    );
  }

  getEmployeesSite() {
    return this._httpClient.get<any[]>(`${this._appConfig.APIs.apiUrlUsers}/User/names`, { params: { active: true } });
  }

  getEmployeesAreas(areaIds: number[]) {
    let params = new HttpParams();
    areaIds.forEach(id => {
      params = params.append('areaIds', id.toString());
    });
    return this._httpClient.get<any[]>(`${this._appConfig.APIs.apiUrlUsers}/User/names/areas`, { params });
  }

  getEmployeesTeams(teamIds: number[]) {
    let params = new HttpParams();
    teamIds.forEach(id => {
      params = params.append('teamIds', id.toString());
    });
    return this._httpClient.get<any[]>(`${this._appConfig.APIs.apiUrlUsers}/User/names/teams`, { params });
  }
}
