<div class="fixed right-10" [class.hidden]="!visible" [ngClass]="bottomCssClass ?? 'bottom-20 md:bottom-10'">

  <button
    class="bg-primary text-on-primary text-left min-w-15 min-h-15 w-15 h-15"
    mat-fab
    (click)="opened =! opened"
    id="main-floating-button"
  >
    <mat-icon
      class="icon-size-button"
      svgIcon="heroicons_solid:plus-small"
    ></mat-icon>
  </button>

  <div
    *ngIf="opened"
    class="flex flex-col absolute bottom-[70px] right-0 space-y-2"
    [@menuAnimation]
  >
    <div
      *ngFor="let menu of menuList; let i = index"
    >
      <div class="flex justify-end">
        <button
          [ngClass]="{'bg-gray-400' : !menu.isActived, 'bg-primary' : menu.isActived}"
          [style.cursor]="menu.isActived ? 'pointer' : 'default'"
          class="text-on-primary justify-start text-base text-left float-button whitespace-nowrap px-2 py-1 h-[27px] min-h-[27px] hover:brightness-75 w-fit"
          mat-fab extended
          (click)="openMenu(menu)"
          [id]="'float-button-'+i"
        >
          <mat-icon
            class="icon-size-6 mr-0"
            svgIcon="heroicons_solid:plus-small"
          ></mat-icon>
          {{ menu.menuName | transloco }}
        </button>
      </div>

    </div>
  </div>
</div>
