import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable, of, tap } from 'rxjs';

import { AppEnvironmentConfig } from 'projects/config/model/environment.config.model';
import { UserService } from '../../user/services/user.service';
import { SvcGamificationResponse } from '../interfaces/svc-gamification.interface';
import { SvcGamificationLocalStorage } from '../enums/svc-gamification-local-storage.enum';

@Injectable({
  providedIn: 'root'
})
export class SvcGamificationService {

  private _isGamefied: boolean;

  constructor(
    private _appConfig: AppEnvironmentConfig,
    private _http: HttpClient,
    private _userService: UserService,
  ) { }

  private set localStorageGamefied(gamification: SvcGamificationResponse) {
    localStorage.setItem(SvcGamificationLocalStorage.IsGamefied, String(gamification.isGamefied));
  }

  private getLocalStorageGamefied(): boolean {
    const isGamefiedString: string | null = localStorage.getItem(SvcGamificationLocalStorage.IsGamefied);
    const isGamefied: boolean = isGamefiedString ? JSON.parse(isGamefiedString) : false;
    return isGamefied;
  }

  public get isGamefied(): boolean {
    return this._isGamefied === true || this._isGamefied === false ? this._isGamefied : this.getLocalStorageGamefied();
  }

  public getIsGamefied(): Observable<SvcGamificationResponse> {
    if (!this._userService.user?.originSiteId) {
      return of({ isGamefied: false });
    }
    return this._http.get<SvcGamificationResponse>(`${this._appConfig.APIs.apiUrlGamification}/isGamefied`, {
      params: {
        siteId: this._userService.user?.originSiteId ?? 0
      }
    }).pipe(
      tap((gamification: SvcGamificationResponse) => {
        this._isGamefied = gamification.isGamefied;
        this.localStorageGamefied = gamification;
      })
    );
  }
}
