import { ConnectedPosition, Overlay, OverlayRef, PositionStrategy } from "@angular/cdk/overlay";
import { Directive, ElementRef, HostListener, Injector, Input, OnChanges, OnDestroy, SimpleChanges, TemplateRef, ViewContainerRef } from "@angular/core";
import { TOOLTIP_DATA, TOOLTIP_MAX_WIDTH, TOOLTIP_TEMPLATE, TemplateTooltipComponent } from "./template-tooltip.component";
import { ComponentPortal } from "@angular/cdk/portal";

@Directive({
    selector: '[template-tooltip]',
  })
  export class TemplateTooltipDirective implements OnChanges, OnDestroy {
    @Input('template-tooltip') templateTooltip!: TemplateRef<void>;
    @Input('tooltipData') data: any;
    @Input('tooltipMaxWidth') maxWidth: string = '200px';
    @Input('tooltipPosition') position: 'left' | 'right' | 'above' | 'below' = 'below';

    private overlayRef: OverlayRef | null = null;
    private needToRecreateOverlay = true;

    constructor(
      private element: ElementRef<HTMLElement>,
      private overlay: Overlay,
      private viewContainer: ViewContainerRef,
    ) {}

    @HostListener('mouseenter')
    @HostListener('focus')
    showTooltip(): void {
      if (this.overlayRef?.hasAttached() === true) {
        return;
      }

      this.attachTooltip();
    }

    @HostListener('mouseleave')
    @HostListener('blur')
    hideTooltip(): void {
      if (this.overlayRef?.hasAttached() === true) {
        this.overlayRef?.detach();
      }
    }

    ngOnChanges(changes: SimpleChanges): void {
      this.needToRecreateOverlay = 'position' in changes;
    }

    ngOnDestroy(): void {
      this.overlayRef?.dispose();
    }

    private attachTooltip(): void {
      if (this.templateTooltip) {
        if (this.needToRecreateOverlay || this.overlayRef === null) {
          const positionStrategy = this.getPositionStrategy();
          this.overlayRef = this.overlay.create({ positionStrategy });
          this.needToRecreateOverlay = false;
        }

        const injector = Injector.create({
          providers: [
            {
              provide: TOOLTIP_TEMPLATE,
              useValue: this.templateTooltip,
            },
            {
              provide: TOOLTIP_DATA,
              useValue: this.data,
            },
            {
              provide: TOOLTIP_MAX_WIDTH,
              useValue: this.maxWidth,
            },
          ],
        });
        const component = new ComponentPortal(
          TemplateTooltipComponent,
          this.viewContainer,
          injector
        );
        this.overlayRef.attach(component);
      }
    }

    private getPositionStrategy(): PositionStrategy {
      let connectedPosition: ConnectedPosition;

      if (['below', 'above'].includes(this.position)) {
        connectedPosition = {
          originX: 'center',
          originY: this.position == 'below' ? 'bottom' : 'top',
          overlayX: 'center',
          overlayY:  this.position == 'below' ? 'top' : 'bottom',
          offsetY: this.position == 'above' ? -5 : 5,
        };
      }
      else if (['left', 'right'].includes(this.position)) {
        connectedPosition = {
          originX: this.position == 'left' ? 'start' : 'end',
          originY: 'center',
          overlayX: this.position == 'left' ? 'end' : 'start',
          overlayY: 'center',
          offsetX: this.position == 'left' ? -5 : 5,
        };
      }
      return this.overlay
        .position()
        .flexibleConnectedTo(this.element)
        .withPositions([
          connectedPosition,
        ]);
    }
  }
