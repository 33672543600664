<ng-container *screenSizes="let size">
  <div class="relative flex-auto overflow-y-auto" [style.height]="size.isBelowMD ? 'auto' : '400px'">
    <ng-container *ngIf="(newsletterItems$ | async) as newsletterItems">
      <ag-virtual-scroll *ngIf="(newsletterItems?.length ?? 0) > 0" #vs [items]="newsletterItems" [height]="size.isBelowMD ? ((elRef?.nativeElement?.offsetHeight ?? 0) + 'px') : '400px'" [min-row-height]="90">
        <div *ngFor="let newsletter of vs.items;" class="border-b border-solid border-slate-200">
          <div class="flex group hover:bg-gray-50"
            [ngClass]="{ unread: newsletter.unread }"
            (click)="emitClickItem(newsletter)"
          >
            <div class="flex flex-col flex-auto py-3 pl-4 cursor-pointer">
              <ng-container *ngTemplateOutlet="newsletterContent"></ng-container>
            </div>

            <div class="flex flex-col py-3 pr-4 pl-2 space-y-2 items-center min-w-20 justify-center">

              <div class="flex text-xs leading-none text-primary font-bold">
                <span *ngIf="newsletter.daysPublished > 0">
                  {{ newsletter.daysPublished }}  {{ newsletter.daysPublished > 1 ? ('dias' | transloco) : ('DIA' | transloco) }}
                </span>
              </div>

              <button *ngIf="!newsletter.readByUser" class="w-6 h-6 min-h-6" mat-icon-button>
                <span class="w-2 h-2 rounded-full bg-primary"></span>
              </button>
            </div>
          </div>

          <ng-template #newsletterContent>

            <div class="flex flex-auto items-center mt-2">
              <svc-user-avatar [info]="{ picture: 'https://solvacelabs-webcomponents.s3.amazonaws.com/images/company-logo-simple-default.svg', size: 32}"></svc-user-avatar>

              <div
                class="line-clamp-3 text-sm leading-tight ml-2"
                [matTooltip]="(newsletter.versionDescription ?? '') + ' ' + (newsletter.versionFooter ?? '') | removeHtml"
                [innerHTML]="(newsletter.versionDescription ?? '') + ' ' + (newsletter.versionFooter ?? '')"
              ></div>
            </div>
          </ng-template>
        </div>
      </ag-virtual-scroll>

      <ng-container *ngIf="!(isLoading$ | async) && ((newsletterItems?.length ?? 0) <= 0)">
        <div class="flex flex-col flex-auto items-center justify-center sm:justify-start py-12 px-8">
          <div class="flex flex-0 items-center justify-center w-14 h-14 rounded-full bg-primary-100">
            <mat-icon class="text-primary-700" [svgIcon]="'heroicons_outline:bell'"></mat-icon>
          </div>
          <div class="mt-5 text-2xl font-semibold tracking-tight">
            {{ 'Sem newsletters' | transloco }}
          </div>
          <div class="w-full max-w-60 mt-1 text-md text-center text-secondary">
            {{ 'Quando você tiver newsletters, elas aparecerão aqui.' | transloco }}
          </div>
        </div>
      </ng-container>
    </ng-container>
    <div *ngIf="isLoading$ | async" class="absolute w-full bottom-0 flex justify-center items-center pb-5">
      <mat-spinner diameter="30"></mat-spinner>
    </div>
  </div>



  <div *ngIf="isLoading$ | async" class="flex flex-auto justify-center p-10">
    <mat-spinner diameter="30"></mat-spinner>
  </div>

</ng-container>
