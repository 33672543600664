<ng-container *ngIf="!isResolving">
  <!-- Toast manager -->
  <svc-toast></svc-toast>

  <!-- Loading bar -->
  <loading-bar></loading-bar>

  <div @fadeInOut class="absolute inset-0 flex flex-col min-w-0 overflow-hidden" *screenSizes="let size">
    <alert-bar></alert-bar>
    <div class="flex flex-row flex-auto overflow-x-auto">
      <!-- Navigation -->
      <vertical-navigation class="bg-primary lg:z-49 overflow-auto text-on-primary" [mode]="size.isAboveSM ? 'side' : 'over'" [opened]="size.isAboveSM" [retracted]="true">
        <!-- Navigation header hook -->
        <ng-container verticalNavigationContentHeader>
          <div class="flex flex-row items-center pt-6 pb-3">
            <div class="absolute md:hidden top-0 right-0">
              <button mat-icon-button (click)="toggleNavigation()">
                <mat-icon class="text-on-primary-400 icon-size-4" [svgIcon]="'mat_solid:close'"></mat-icon>
              </button>
            </div>

            <!-- User -->
            <div class="user-avatar relative shrink-0 text-on-primary">
              <svc-user-avatar
                [info]="{ name: user.firstLastName, picture: user.pictureFile, initialsColor: user.initialsColor, size: 46}"
              ></svc-user-avatar>
            </div>
            <div *ngIf="!verticalNavigation?.isRetracted" class="flex flex-col items-center ml-2 overflow-hidden user-info">
              <div
                class="w-full whitespace-nowrap text-ellipsis overflow-hidden text-base leading-normal font-medium text-on-primary">
                {{ user.firstLastName }}
              </div>
              <div
                class="w-full -mt-0.5 whitespace-nowrap text-ellipsis overflow-hidden text-xs leading-normal text-on-primary">
                {{ user.email }}
              </div>
            </div>
          </div>

          <div *ngIf="navigation?.default.length" class="flex flex-col">
            <div *ngFor="let item of navigation.default" class="menu-item" [ngClass]="{ 'expanded': item.expanded }">
              <div class="menu-item-header flex flex-row justify-center" (click)="toggleCurrentNavigationItem(item)">
                <div class="flex items-center" [ngClass]="{ 'pr-3': !verticalNavigation?.isRetracted }">
                  <mat-icon class="icon-size-5 text-current" [svgIcon]="item.icon"></mat-icon>
                </div>
                <div *ngIf="!verticalNavigation?.isRetracted" class="flex flex-auto items-center text-sm font-bold">
                  {{ item.title | transloco }}
                </div>
                <div *ngIf="!verticalNavigation?.isRetracted" class="flex items-center pl-2">
                  <mat-icon class="icon-size-5 text-current"
                    [svgIcon]="item.expanded ? 'mat_solid:keyboard_arrow_up' : 'mat_solid:keyboard_arrow_down'"></mat-icon>
                </div>
              </div>
              <div class="menu-child-content overflow-hidden"
                [style.height.px]="(item.expanded && !verticalNavigation?.isRetracted) ? childrenInnerContent.clientHeight : 0">
                <div #childrenInnerContent class="flex flex-col pt-2">
                  <div *ngFor="let childItem of item.children" class="menu-child text-sm">
                    <a [routerLink]="[childItem.url]" routerLinkActive="active" class="block p-1"
                      (click)="toggleNavigation()">
                      {{ childItem.title | transloco }}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
        <ng-container verticalNavigationFooter>
          <div class="flex flex-col items-center justify-center mt-2 mb-4 divide-y divide-on-primary">
            <div class="flex mb-3" *ngIf="this.navigation.default.length">
              <!-- Navigation toggle appearance button -->
              <button *ngIf="!size.isBelowMD" class="hidden md:inline-flex" mat-icon-button
                (click)="toggleNavigationRetraction()">
                <mat-icon class="text-current icon-size-7" svgIcon="heroicons_solid:bars-3"></mat-icon>
              </button>
            </div>
            <div class="flex px-2 pt-3">
              <img *ngIf="!verticalNavigation?.isRetracted" class="max-w-40"
                src="https://solvacelabs-webcomponents.s3.amazonaws.com/images/company-logo-ng.svg" />
              <img *ngIf="verticalNavigation?.isRetracted" class="max-w-40"
                src="https://solvacelabs-webcomponents.s3.amazonaws.com/images/company-logo-simple-ng.svg" />
            </div>
          </div>
        </ng-container>
      </vertical-navigation>

      <!-- Content -->
      <div class="relative flex flex-col flex-auto w-full min-w-0 overflow-y-auto">
        <!-- Header -->
        <div class="relative flex flex-0 items-center w-full h-16 z-49 shadow-sm border-b bg-card">
          <div class="flex w-full px-4 md:px-6">
            <div class="flex items-center pr-2 space-x-0.5 sm:space-x-2 md:space-x-0">
              <!-- Navigation toggle button -->
              <button class="inline-flex md:hidden" mat-icon-button (click)="toggleNavigation()">
                <mat-icon [svgIcon]="'heroicons_solid:bars-3'"></mat-icon>
              </button>

              <a [href]="[urlHome]">
                <img class="max-w-28" [src]="environment.logoImage"/>
              </a>
            </div>
            <!-- Components -->
            <div class="flex items-center pl-2 ml-auto space-x-0.5 sm:space-x-2 text-primary">
              <svc-notifications></svc-notifications>
              <ng-container *ngIf="!size.isBelowMD">
                <sites></sites>
              </ng-container>
              <user [showAvatar]="!size.isBelowMD" [showSites]="size.isBelowMD"></user>
              <button *ngIf="!size.isBelowLG" mat-icon-button (click)="appsPanel.toggle()">
                <mat-icon class="text-current" [svgIcon]="'mat_solid:apps'"></mat-icon>
              </button>
              <!-- <settings></settings> -->
            </div>
          </div>
        </div>
        <!-- Content -->
        <div class="svc-custom-layout-content flex-auto w-full overflow-y-auto">
          <router-outlet *ngIf="true"></router-outlet>
          <svc-copilot-float-button *ngIf="copilotEnabled" class="absolute left-10 bottom-7 z-99 hidden lg:flex"></svc-copilot-float-button>
        </div>

        <div class="inset-0 flex flex-col min-w-0 overflow-hidden">
          <apps-panel #appsPanel="appsPanel"></apps-panel>
        </div>
      </div>
    </div>
    <mobile-menu
      (menuClick)="mobileMenuClick($event)"
      [totalTasks]="sidebarNavigationComponent?.totalTasks"
      [loadingTasks]="sidebarNavigationComponent?.loadingTasks ?? true"
      [totalFeeds]="sidebarNavigationComponent?.totalFeeds"
      [loadingFeeds]="sidebarNavigationComponent?.loadingFeeds ?? true"
    ></mobile-menu>
    <sidebar-navigation class="absolute top-0 bottom-0 right-0"
      [class.h-auto]="!sidebarNavigationComponent?.opened"
      #sidebarNavigationComponent
      mode="over"
      [opened]="false"
    ></sidebar-navigation>
  </div>
</ng-container>

<ng-container *ngIf="isResolving">
  <div @fadeInOut class="custom-skeleton-page fixed inset-0 flex flex-col" *screenSizes="let size">
    <div class="flex-auto flex overflow-y-hidden">

      <!-- SIDE BAR -->
      <div *ngIf="size.isAboveSM" class="flex flex-col bg-zinc-400 bg-opacity-40 w-18">
        <div class="flex-auto flex flex-col items-center gap-6 pt-6 overflow-y-hidden">
          <ngx-skeleton-loader class="white" [theme]="{ width: '46px', height: '46px', margin: 0 }" appearance="circle" />
          <ngx-skeleton-loader *ngFor="let _ of [1,2,3,4]" class="mb-2 white" [theme]="{ width: '25px', height: '25px', borderRadius: '6px' }" />
        </div>
        <div class="flex flex-col items-center gap-2 pb-4">
          <ngx-skeleton-loader class="mb-1 white" [theme]="{ width: '26px', height: '22px' }" />
          <div class="w-full px-1">
            <div class="w-full border-t border-white border-opacity-60"></div>
          </div>
          <ngx-skeleton-loader class="white" [theme]="{ width: '38px', height: '38px', margin: 0 }" appearance="circle" />
        </div>
      </div>

      <div class="flex-auto flex flex-col">

        <!-- HEADER -->
        <div class="relative flex flex-0 items-center w-full py-2.5 bg-white shadow-sm">
          <div class="flex w-full h-11 m-auto px-4">
            <div class="flex items-center justify-center h-full gap-3">
              <ngx-skeleton-loader *ngIf="size.isBelowMD" [theme]="{ width: '24px', height: '24px' }" />
              <ngx-skeleton-loader class="w-fit h-full py-1" [theme]="{ width: '112px', height: '100%' }" />
            </div>
            <div class="flex-auto flex items-center justify-end gap-2">
              <ngx-skeleton-loader class="flex mx-3" [theme]="{ width: '24px', height: '24px' }" />
              <div class="flex items-center px-3 gap-1" *ngIf="size.isAboveSM">
                <ngx-skeleton-loader class="flex" [theme]="{ width: '24px', height: '24px', margin: 0 }" appearance="circle" />
                <ngx-skeleton-loader class="flex" [theme]="{ width: '50px', height: '16px' }" />
              </div>
              <ngx-skeleton-loader class="flex mx-3" [theme]="{ width: '24px', height: '24px', margin: 0 }" appearance="circle" />
              <ngx-skeleton-loader *ngIf="size.isAboveMD" class="flex ml-3" [theme]="{ width: '24px', height: '24px' }" />
            </div>
          </div>
        </div>

        <!-- CONTENT -->
        <div class="relative flex-auto flex flex-col gap-7 px-6 pt-6 overflow-y-hidden">
          <div class="flex gap-1 items-center opacity-40">
            <ngx-skeleton-loader [theme]="{ width: '38px', height: '18px' }" />
            <ngx-skeleton-loader [theme]="{ width: '6px', height: '6px', margin: 0 }" appearance="circle" />
            <ngx-skeleton-loader [theme]="{ width: '40px', height: '18px' }" />
            <ngx-skeleton-loader [theme]="{ width: '6px', height: '6px', margin: 0 }" appearance="circle" />
            <ngx-skeleton-loader [theme]="{ width: '120px', height: '18px' }" />
          </div>
          <div class="flex gap-2 items-center opacity-40">
            <div class="flex-auto flex gap-2 items-center">
              <ngx-skeleton-loader class="w-full md:w-56" [theme]="{ width: '100%', height: '42px', borderRadius: '6px' }" />
              <ngx-skeleton-loader [theme]="{ width: '32px', height: '32px', borderRadius: '6px' }" />
            </div>
            <ngx-skeleton-loader [theme]="{ width: '32px', height: '32px', borderRadius: '6px' }" />
          </div>
          <div class="flex flex-col gap-2 opacity-40">
            <div class="w-full flex shrink-0 gap-2 items-center justify-center overflow-hidden">
              <ngx-skeleton-loader [theme]="{ width: '75px', height: '20px' }" />
              <ngx-skeleton-loader [theme]="{ width: '110px', height: '20px', }" />
              <ngx-skeleton-loader [theme]="{ width: '90px', height: '20px', }" />
            </div>
            <div class="w-full border-t-2 border-gray-200"></div>
          </div>
          <div class="w-full grid gap-4 auto-cols-max grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 opacity-30">
            <ngx-skeleton-loader *ngFor="let _ of [].constructor(12)" class="h-72" [theme]="{ width: '100%', height: '100%', borderRadius: '6px' }" />
          </div>
          <ngx-skeleton-loader class="absolute bottom-10 right-10" [theme]="{ width: '60px', height: '60px', margin: 0 }" appearance="circle" />
        </div>
      </div>
    </div>
    <div class="flex items-center justify-center gap-4 h-14 bg-white shadow-top" *ngIf="size.isBelowLG">
      <ngx-skeleton-loader [theme]="{ width: '32px', height: '32px', borderRadius: '4px' }"/>
      <ngx-skeleton-loader [theme]="{ width: '50px', height: '50px', margin: 0 }" appearance="circle" />
      <ngx-skeleton-loader [theme]="{ width: '32px', height: '32px', borderRadius: '4px' }"/>
    </div>
  </div>
</ng-container>