import {
  AfterContentInit,
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Eligibility } from 'projects/communication/src/app/communication/models/user-eligibility';
import { SvcDialogStyles } from '../../models/svc-dialog-styles';
import { BreakpointName, SvcMediaQuery } from 'projects/lib-shared-common/src/public-api';
import { tap } from 'rxjs';

@Component({
  selector: 'svc-dialog-primary',
  templateUrl: './svc-dialog-primary.component.html',
  styleUrls: ['./svc-dialog-primary.component.scss'],
})
export class SvcDialogPrimaryComponent implements AfterViewInit, AfterContentInit {
  @ViewChild('modal') modal: any;
  @ViewChild('titleContainer') dialogTitleContainer: ElementRef<HTMLElement>;

  @Input() textConfirm: string;
  @Input() textCancel: string;
  @Input() dialogStyle: SvcDialogStyles = 'form';
  @Input() disableConfirm = false;
  @Input() disableCancel = false;
  @Input() showMatMenu: Eligibility;
  @Input() processing = false;
  @Input() fullscreen: BreakpointName | BreakpointName[] = 'XS';
  @Input() scrollInsideContent: boolean = true;

  @Output() opened = new EventEmitter();
  @Output() closed = new EventEmitter();
  @Output() confirmed = new EventEmitter();
  @Output() cancelled = new EventEmitter();

  @Output() actionRemove = new EventEmitter();
  @Output() actionReschedule = new EventEmitter();
  @Output() actionResend = new EventEmitter();
  @Output() actionSetEditMode = new EventEmitter();
  @Output() actionView = new EventEmitter();
  @Output() actionClose = new EventEmitter();

  footerContentIsEmpty: boolean = true;

  hasTitle: boolean = false;
  hasActions: boolean = false;
  hasItemsToShowMenu: boolean = false;
  needInternalScroll: boolean = false;
  isViewInitialized = false;

  constructor(
    private _dialogRef: MatDialogRef<SvcDialogPrimaryComponent>,
    private _svcMediaQuery: SvcMediaQuery,
  ) { }

  ngOnInit() {
    this._svcMediaQuery.size$.pipe(
      tap(() => this.checkNeedInternalScroll()),
    ).subscribe();
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('showMatMenu' in changes) {
      this.hasItemsToShowMenu = Object.keys(this.showMatMenu ?? {}).some((key) => this.showMatMenu[key]);
    }
    if ('fullscreen' in changes) {
      this.checkNeedInternalScroll();
    }
  }

  ngAfterViewInit() {
    this.checkNeedInternalScroll();
    this.isViewInitialized = true;
  }

  ngAfterContentInit() {
    this.opened.emit();
  }

  private checkNeedInternalScroll() {
    this.fullscreen = (this.fullscreen ?? []);
    const sizes: BreakpointName[] = (
      Array.isArray(this.fullscreen)
        ? this.fullscreen
        : [this.fullscreen]
    ).filter((size) => !!(size));
    this.needInternalScroll = this._svcMediaQuery.isIn(sizes);
  }

  ngOnDestroy() {
    this.closed.emit();
  }

  closeDialog() {
    this._dialogRef.close();
  }

  remove() {
    this.actionRemove.emit();
  }

  resend() {
    this.actionResend.emit();
  }

  reschedule() {
    this.actionReschedule.emit();
  }

  close() {
    this.actionClose.emit();
  }

  setEditMode() {
    this.actionSetEditMode.emit();
  }

  view() {
    this.actionView.emit();
  }
}
