import { NgModule } from '@angular/core';
import { TranslocoModule } from "@ngneat/transloco";
import { LibSharedCommonModule } from 'projects/lib-shared-common/src/public-api';
import { LanguagesModule } from 'projects/lib-shared-core/src/lib/features/languages/languages.module';
import { UserModule } from 'projects/lib-shared-core/src/lib/features/user/user.module';
import { SitesModule } from 'projects/lib-shared-core/src/lib/features/sites/sites.module';
import { AppsModule } from 'projects/lib-shared-core/src/lib/features/apps/apps.module';
import { SvcToastModule } from 'projects/lib-shared-component/src/public-api';
import { SettingsModule } from 'projects/lib-shared-core/src/lib/features/settings/settings.module';
import { FloatAddButtonModule } from 'projects/lib-shared-core/src/lib/features/float-add-button/float-add-button.module';
import { SvcNotificationsModule } from '../../../../general/svc-notifications/svc-notifications.module';
import { ModalPraiseReceivedModule } from '../../../../praise/modals/modal-praise-received/modal-praise-received.module';
import { SolvaceWorkspaceLayoutComponent } from './solvace-workspace-layout.component';
import { SvcCopilotModule } from '../../../../general/svc-copilot/svc-copilot.module';
import { LayoutComponentsModule } from '../components/layout-components.module';

import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

@NgModule({
  declarations: [SolvaceWorkspaceLayoutComponent],
  imports: [
    LibSharedCommonModule,
    LayoutComponentsModule,
    LanguagesModule,
    SvcNotificationsModule,
    UserModule,
    SitesModule,
    AppsModule,
    SettingsModule,
    FloatAddButtonModule,
    TranslocoModule,
    SvcToastModule,
    ModalPraiseReceivedModule,
    SvcCopilotModule,
    NgxSkeletonLoaderModule,
  ],
  exports: [SolvaceWorkspaceLayoutComponent],
})
export class SolvaceWorkspaceLayoutModule { }
