<!-- Button -->
<button mat-icon-button [matMenuTriggerFor]="userActions">
  <ng-container *ngIf="showAvatar">
    <svc-user-avatar [info]="userAvatar" border-color="primary"></svc-user-avatar>
  </ng-container>
  <ng-container *ngIf="!showAvatar">
    <mat-icon class="text-current" [svgIcon]="'heroicons_solid:ellipsis-horizontal'"></mat-icon>
  </ng-container>
</button>

<mat-menu [xPosition]="'before'" #userActions="matMenu">
  <ng-container *ngIf="showSites">
    <button
      mat-menu-item
      class="bordered"
      [matMenuTriggerFor]="siteActions"
    >
      <sites [renderAsMenuItemContent]="true"></sites>
    </button>
  </ng-container>
  <ng-container *transloco="let t">
    <button mat-menu-item (click)="changeSettings()">
      <div class="flex items-center text-default">
        <mat-icon class="text-current" svgIcon="heroicons_solid:cog-6-tooth"></mat-icon>
        <span>{{ t('Configurações') }}</span>
      </div>
    </button>

    <button *ngIf="env.isLocalhost || !env.isPROD" mat-menu-item (click)="changeNotifications()">
      <mat-icon [svgIcon]="'heroicons_solid:bell'"></mat-icon>
      <span >{{ t('Gestão de Notificações') }}</span>
    </button>

    <button mat-menu-item (click)="changePassword()" *ngIf="!user.isFederated">
      <div class="flex items-center text-default">
        <mat-icon class="text-current" svgIcon="heroicons_solid:lock-closed"></mat-icon>
        <span>{{ t('Alterar Senha') }}</span>
      </div>
    </button>

    <button mat-menu-item (click)="signOut()">
      <div class="flex items-center text-default">
        <mat-icon class="text-current" svgIcon="heroicons_solid:arrow-left-on-rectangle"></mat-icon>
        <span>{{ t('Sair') }}</span>
      </div>
    </button>
    <div class="flex items-center justify-center text-default">
      <span *ngIf="version" class="text-sm">{{ 'Versão' | transloco }} : {{version}}</span>
      <ngx-skeleton-loader *ngIf="!version" [theme]="{'margin-bottom': 0, width: '120px', height: '18px'}"/>
    </div>
  </ng-container>
</mat-menu>

<mat-menu [xPosition]="'after'" #siteActions="matMenu">
  <sites [renderAsSubMenuItemContent]="true"></sites>
</mat-menu>
