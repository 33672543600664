import { AfterViewInit, Component, Input, ViewChild} from '@angular/core';
import { TasksModule} from "../models/tasks-module";
import { AgVirtualSrollComponent} from "ag-virtual-scroll";
import { externalNetcoreURL, generateModuleMenuURL, getAccessTokenPayloadInfo} from 'projects/lib-shared-common/src/public-api';
import { environment } from 'projects/environments/environment';
import { UserAvatarSize } from 'projects/lib-shared-component/src/lib/svc-user-avatar/interfaces/svc-user-avatar.interface';

@Component({
  selector: 'main-task',
  templateUrl: './main-task.component.html',
  styleUrls: ['./main-task.component.scss']
})
export class MainTaskComponent implements AfterViewInit {
  @Input() tasks: any[];
  taskModules = TasksModule;
  @Input() isTotalTasksLoading: boolean;

  @ViewChild(AgVirtualSrollComponent) agVirtualSroll: AgVirtualSrollComponent;

  avatarSize: UserAvatarSize = UserAvatarSize.Analytics;

  public env = environment;

  ngAfterViewInit(): void {
    this.agVirtualSroll?.refreshData();
  }

  openModuleItem(item: any) {

    //use this code below to opening at different tab
    /*
    const issuer = getAccessTokenPayloadInfo('iss');
    const params = {
      id: item.id,
      url: issuer,
      controller: null
    }

    switch(item.moduleName) {
      case TasksModule.ACTIONPLAN:
        params.url += '/actionplan/';
        params.controller = 'Plan';
        break;
      case TasksModule.DEFECTTAG:
        params.url += '/defecttag/'
        params.controller = 'DefectTag';
        break;
      case TasksModule.CIL:
        params.id = item.inspectionId;
        params.url += '/lil/'
        params.controller = 'Inspection';
        break;
      case TasksModule.CHECKLIST:
        params.id = item.inspectionId;
        params.url += '/checklist/'
        params.controller = 'Inspection';
        break;
    }
    externalNetcoreURL(params.url, `{controller=${params.controller}||action=Search||variables={redirectId=${params.id}}}`);
    */

    //use this code below to change to legacy modal instead of opening at different tab
    const issuer = getAccessTokenPayloadInfo('iss');
    let fnc = null;

    switch(item.moduleName) {
      case TasksModule.ACTIONPLAN:
        fnc = generateModuleMenuURL('MESSAGE', issuer, 'openModal', `{"functionName": "OpenDynamicModal", "urlToOpen": "/actionplan/Plan/View", "modalData": { "planId": ${item.id}, "flEdit":false}, "modalId": "#registry-action-plan-modal", "modalTimeout": 0}`);
        break;
      case TasksModule.DEFECTTAG:
        fnc = generateModuleMenuURL('MESSAGE', issuer, 'openModal', `{"functionName": "OpenDynamicModal", "urlToOpen": "/defecttag/DefectTag/View", "modalData": { "dft_id": ${item.id}, "flEdit":false}, "modalId": "#registry-mainregister-modal", "modalTimeout": 0}`);
        break;
      case TasksModule.CIL:
        fnc = generateModuleMenuURL('MESSAGE', issuer, 'openModal', `{"functionName": "OpenDynamicModal", "urlToOpen": "/lil/Inspection/View", "modalData": { "inspectionId": ${item.inspectionId}, "flEdit":false}, "modalId": "#registry-inspection-modal", "modalTimeout": 0}`);
        break;
      case TasksModule.CHECKLIST:
        fnc = generateModuleMenuURL('MESSAGE', issuer, 'openModal', `{"functionName": "OpenDynamicModal", "urlToOpen": "/checklist/Inspection/View", "modalData": { "inspectionId": ${item.inspectionId}, "flEdit":false}, "modalId": "#registry-inspection-modal", "modalTimeout": 0}`);
        break;
    }
    if (typeof fnc === 'function') fnc();
  }

  openInpectionNok(item: any, event: any) {
    if (event.label !== 'NOK') return;
    const module = item.moduleName === TasksModule.CHECKLIST ? "checklist" : "lil";
    const issuer = getAccessTokenPayloadInfo('iss');
    const fnc = generateModuleMenuURL('MESSAGE',issuer,'openModal',`{"functionName": "OpenDynamicModal", "urlToOpen": "/${module}/Inspection/ListDetailNoOk", "modalData": { "inspectionId": ${item.inspectionId}, "flEdit":false}, "modalId": "#registry-inspection-modal", "modalTimeout": 0}`);
    if (typeof fnc === 'function') fnc();
  }
  
  handleBookmarkChange(task: any, newValue: boolean): void {
    task.isBookmark = newValue;
  }
}
