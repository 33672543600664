import { AfterViewInit, Component, forwardRef, inject, OnInit, OnDestroy, Renderer2, ViewChild } from '@angular/core'
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import { catchError, Observable, of, Subject, take, takeUntil, tap } from 'rxjs';
import { RequestCollectorService, SvcMediaQuery } from 'projects/lib-shared-common/src/public-api';
import { AuthService, NavigationService, PageCantBeLoadedType, SitesService, SvcAppSettings, SvcFeatureToggleService, User, UserEnvironment, UserPreferencesService, UserService } from 'projects/lib-shared-core/src/public-api';
import { LayoutService } from '../layout.service';
import { SvcSidebarNavigationService } from './components/sidebar-navigation/svc-sidebar-navigation.service';
import { SvcDialogService } from 'projects/lib-shared-component/src/public-api';
import { NotificationIntegrationService } from '../../notification/notification-integration.service';
import { environment } from 'projects/environments/environment';
import { VerticalNavigationComponent } from './components/vertical-navigation/vertical-navigation.component';
import { SidebarNavigationComponent } from './components/sidebar-navigation/sidebar-navigation.component';
import { MobileMenuType } from './components/mobile-menu/mobile-menu.component';
import { LayoutComponent } from '../layout.component';

export enum SvcLayoutResolveStatus {
  Success,
  Error,
  HasNoPermission,
  PendingGDPRUser,
  CustomRedirection,
}

@Component({ template: '' })
export abstract class BaseLayoutComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild(VerticalNavigationComponent) verticalNavigation: VerticalNavigationComponent;
  @ViewChild('appsPanel') appsPanel: any;
  @ViewChild('sidebarNavigationComponent') sidebarNavigationComponent: SidebarNavigationComponent;

  protected _activatedRoute = inject(ActivatedRoute);
  protected _router = inject(Router);
  protected _renderer = inject(Renderer2);
  protected _navigationService = inject(NavigationService);
  protected _userService = inject(UserService);
  protected _authService = inject(AuthService);
  protected _layoutService = inject(LayoutService);
  protected _requestCollectorService = inject(RequestCollectorService);
  protected _svcSidebarNavigationService = inject(SvcSidebarNavigationService);
  protected _dialogService = inject(SvcDialogService);
  protected _notificationIntegrationService = inject(NotificationIntegrationService);
  protected _featureToggleService = inject(SvcFeatureToggleService);
  protected _mediaQuery = inject(SvcMediaQuery);
  protected _sitesService = inject(SitesService);
  protected _userPreferencesService = inject(UserPreferencesService);
  protected _appSettings = inject(SvcAppSettings);
  protected _layout = inject<LayoutComponent>(forwardRef(() => LayoutComponent));

  protected _unsubscribeAll = new Subject<any>();
  protected env = environment;
  protected user: User;
  protected environment: UserEnvironment;
  protected feedIsEnabled = false;
  protected copilotEnabled = false;
  protected isResolving = true;

  protected get navigationGotError(): boolean { return this._navigationService.error != null; }
  protected get navigationIsLoading(): boolean { return this._navigationService.isLoading; }

  constructor() {
    this.feedIsEnabled = this._featureToggleService.feedIsEnabled;
    this.copilotEnabled = this._featureToggleService.copilotIsEnabled;
  }

  public ngOnInit(): void {
    this.resolveLayout();
    this._router.events.pipe(
      takeUntil(this._unsubscribeAll),
      tap((event: any) => {
        if (event instanceof NavigationStart) {
          const currentUrl = this._router.url.replace('/', '');
          const nextUrl = event.url.replace('/', '');
          if (currentUrl !== nextUrl) {
            this._requestCollectorService.cancelAll();
          }
        }
      }),
    ).subscribe();

    this._sitesService.getAll().subscribe();
  }

  public ngAfterViewInit(): void {
    this.verticalNavigation && this._layoutService.setVerticalNavigationComponent(this.verticalNavigation);
  }

  protected toggleNavigation(): void {
    this.verticalNavigation.toggle();
  }

  protected mobileMenuClick(type: MobileMenuType) {
    if (type === 'modules' || type === 'menu') {
      this.toggleMenu();
    }
    else if (type === 'tasks') {
      this.sidebarNavigationComponent.open('tasks');
    }
    else if (type === 'feed') {
      this.sidebarNavigationComponent.open('feed');
    }
  }

  protected toggleMenu(): void {
    this.appsPanel?.toggle();
  }

  protected onLayoutResolving(): Observable<SvcLayoutResolveStatus> {
    return of(SvcLayoutResolveStatus.Success);
  }

  protected onLayoutResolvingFinished(): void {
    this.verticalNavigation && this._layoutService.setVerticalNavigationComponent(this.verticalNavigation);
  }

  private resolveLayout() {
    this.isResolving = true;
    this.onLayoutResolving().pipe(
      take(1),
      tap((status) => {
        switch (status) {
          case SvcLayoutResolveStatus.Error: {
            this.redirectToPageCantBeLoaded();
            return;
          }
          case SvcLayoutResolveStatus.HasNoPermission: {
            this.redirectToPageCantBeLoaded({
              type: PageCantBeLoadedType.PERMISSION,
            });
            return;
          }
          case SvcLayoutResolveStatus.PendingGDPRUser: {
            this.redirectToPageCantBeLoaded({
              type: PageCantBeLoadedType.GDPR,
            });
            return;
          }
        }
        this.user = this._userService.user;
        this.environment = this._userService.environment;
        this.feedIsEnabled = this._featureToggleService.feedIsEnabled;
        this.copilotEnabled = this._featureToggleService.copilotIsEnabled;
        this._layout.childLayoutWasResolved();
        setTimeout(() => this.onLayoutResolvingFinished());
        this.isResolving = false;
      }),
      catchError(() => of(SvcLayoutResolveStatus.Error)),
    ).subscribe();
  }

  private redirectToPageCantBeLoaded(params?: {
    type?: PageCantBeLoadedType,
    from?: string,
  }) {
    this._router.navigate(['page-cant-be-load'], {
      queryParams: {
        type: params?.type,
        from: params?.from ?? this._router.url,
      }
    });
  }

  public ngOnDestroy(): void {
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }
}