import { Component, Inject, Input, forwardRef, ViewChild, ElementRef, AfterViewInit, inject, SimpleChanges } from '@angular/core';
import { SvcMediaCarouselInternalItem } from '../interfaces/svc-media-carousel-item';
import { SvcMediaCarouselComponent } from '../svc-media-carousel.component';
import { fadeIn, fadeOut } from '../../animations/fade';
import { filter, fromEvent, Subject, takeUntil, tap } from 'rxjs';

@Component({
  selector: 'svc-media-carousel-item',
  templateUrl: './svc-media-carousel-item.component.html',
  styleUrls: ['./svc-media-carousel-item.component.scss'],
  animations: [
    fadeIn,
    fadeOut
  ],
  host: {
    '[class.svc-media-carousel-item]': 'true',
    '[class.svc-mci-one-by-one]': 'parent.renderMode === \'one-by-one\'',
    '[class.svc-mci-empty-view]': '!item',
    '[class.svc-mci-dark]': 'parent.isDark',
  },
})
export class SvcMediaCarouselItemComponent implements AfterViewInit {
  readonly #elementRef = inject<ElementRef<HTMLElement>>(ElementRef);

  @ViewChild('video') set videoWatch(video: ElementRef) {
    if (this.item?.type === 'video') {
      this.item.fileLoading = !!(video);
    }
  }

  @Input() item: SvcMediaCarouselInternalItem;

  protected canBeDisplayed = false;
  protected get element(): HTMLElement { return this.#elementRef?.nativeElement; }
  protected get viewportEl(): HTMLElement { return this.parent.element.querySelector('.svc-mc-viewport'); }

  #destroy = new Subject<void>();
  #wasInitialized = false;
  #loadingImage = false;
  #fetchImageController: AbortController;

  constructor(
    @Inject(forwardRef(() => SvcMediaCarouselComponent))
    public parent: SvcMediaCarouselComponent,
  ) { }

  public ngOnInit(): void {
    this.parent.onCurrentItemChanged.pipe(
      takeUntil(this.#destroy),
      filter((item) => !this.#loadingImage && this.item && item === this.item),
      tap(() => {
        this.canBeDisplayed = true;
        this.tryLoadImageIfItCan();
      }),
    ).subscribe();
    this.parent.needToTickItems$.pipe(
      takeUntil(this.#destroy),
      tap(() => {
        if (this.parent.renderModeIsList) {
          this.onViewportScroll();
        }
        else if (!this.#loadingImage && this.item && this.parent.getCurrent() === this.item){
          this.canBeDisplayed = true;
          this.tryLoadImageIfItCan();
        }
      }),
    ).subscribe();
    this.#wasInitialized = true;
  }

  public tryLoadImageIfItCan() {
    if (this.canBeDisplayed && this.item.type === 'image' && !this.item.base64Url) {
      this.loadItemImage();
    }
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if ('item' in changes && this.item) {
      this.item.fileLoading = this.item?.type === 'image' && !this.item?.base64Url;
    }

    if (this.#wasInitialized && this.viewportEl) {
      this.onViewportScroll();
    }
  }

  public ngAfterViewInit(): void {
    if (this.viewportEl) {
      [
        fromEvent(this.viewportEl, 'scroll'),
        fromEvent(window, 'resize'),
      ].forEach(
        (observ) => observ.pipe(
          takeUntil(this.#destroy),
          filter(() => this.parent.renderModeIsList),
          tap(() => this.onViewportScroll()),
        ).subscribe(),
      );
      this.onViewportScroll();
    }
    if (!this.parent.renderModeIsList && !this.canBeDisplayed) {
      this.canBeDisplayed = !this.#loadingImage && this.item && this.parent.getCurrent() === this.item;
      this.tryLoadImageIfItCan();
    }
  }

  private onViewportScroll() {
    setTimeout(() => {
      if (!this.parent.renderModeIsList || !this.item || this.canBeDisplayed || !this.viewportEl) return;
      const viewport = this.viewportEl;
      const viewportScrollLeft = viewport.scrollLeft;
      const viewportWidth = viewport.offsetWidth;
      this.canBeDisplayed = (viewportScrollLeft + viewportWidth) >= this.element.offsetLeft;
      this.tryLoadImageIfItCan();
    })
  }

  private loadItemImage() {
    if (this.#loadingImage) return;
    this.#loadingImage = true;
    this.item.fileLoading = true;
    const finalize = () => {
      this.item.fileLoading = false;
      this.#loadingImage = false;
      this.#fetchImageController = null;
    };

    this.#fetchImageController = new AbortController();
    fetch(this.item.url, { signal: this.#fetchImageController.signal })
      .then((response) => {
        response.blob()
          .then((blob) => {
            const reader = new FileReader();
            reader.onloadend = () => {
              this.item.base64Url = <string>reader.result;
              finalize();
            };
            reader.onerror = () => finalize();

            reader.readAsDataURL(blob);
          })
          .catch(() => finalize());
      })
      .catch(() => finalize());
  }

  public ngOnDestroy(): void {
    if (!this.item) {
      this.parent.needToTickItems$.next();
    }
    this.#fetchImageController?.abort();
    this.#destroy.next();
    this.#destroy.complete();
  }
}
