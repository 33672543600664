import { AfterViewInit, Component, ElementRef, HostBinding, Input, OnChanges, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';

@Component({
  selector: 'svc-drawer',
  templateUrl: './svc-drawer.component.html',
  styleUrls: ['./svc-drawer.component.scss']
})
export class SvcDrawerComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {

  @Input() position: 'left' | 'right' = 'right';
  @Input() mode: 'over' | 'side' = 'side';
  @Input() opened = false;

  @HostBinding('class.positioned-left') public isLeft = false;
  @HostBinding('class.positioned-right') public isRight = false;
  @HostBinding('class.over') public isOver = this.mode === 'over';
  @HostBinding('class.opened') public isOpened = this.opened;

  @ViewChild('drawerSide') public drawerSide: ElementRef<HTMLElement>;
  @ViewChild('drawerContent') public drawerContent: ElementRef<HTMLElement>;

  private _drawerSideHiddenTimeout:  NodeJS.Timeout = null;
  private _viewInit = false;

  constructor(
    private renderer: Renderer2,
  ){}

  public ngOnInit(): void {}

  public ngAfterViewInit(): void {
    this._viewInit = true;
    this._updateVariables();
    this._updateViews();
  }

  public ngOnChanges(): void {
    this._updateVariables();
    this._updateViews();
  }

  private _updateVariables() {
    if (this._viewInit) {
      this.isLeft = this.position == 'left';
      this.isRight = this.position == 'right';
    }
    this.isOver = this.mode == 'over';
    this.isOpened = this.opened;
  }

  private _updateViews() {
    if (this._viewInit && this.drawerContent && this.drawerSide) {
      this.renderer.setStyle(this.drawerContent.nativeElement, 'width', (this.opened && !this.isOver) ? `calc(100% - ${this.drawerSide.nativeElement.offsetWidth}px)` : null);

      this._drawerSideHiddenTimeout && clearTimeout(this._drawerSideHiddenTimeout);
      if (this.opened) {
        if (!this.drawerSide.nativeElement.style.visibility) {
          this.renderer.setStyle(this.drawerSide.nativeElement, 'visibility', 'visible');
        }
      }
      else {
        if ((this.drawerSide.nativeElement.style.visibility || '') !== '') {
          this._drawerSideHiddenTimeout = setTimeout(() => {
            this.renderer.setStyle(this.drawerSide.nativeElement, 'visibility', null);
          }, 300);
        }
      }

      const transform = this.opened ? `translateX(0)` : null;
      if (this.drawerSide.nativeElement.style.transform !== transform) {
        this.renderer.setStyle(this.drawerSide.nativeElement, 'transform', transform);
      }
    }
  }

  public toggle() {
    this.opened = !this.opened;
    this._updateVariables();
    this._updateViews();
  }

  public ngOnDestroy(): void {
  }

}
