import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatRippleModule } from '@angular/material/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import { SvcFilledBtnComponent } from './svc-filled-btn.component';

@NgModule({
  declarations: [
    SvcFilledBtnComponent
  ],
  imports: [
    CommonModule,
    MatRippleModule,
    MatProgressSpinnerModule
  ],
  exports: [
    SvcFilledBtnComponent
  ]
})
export class SvcFilledBtnModule { }
