import { NgModule } from '@angular/core';

import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

import { SvcPostsSkeletonComponent } from './svc-posts-skeleton.component';
import { LibSharedCommonModule } from 'projects/lib-shared-common/src/lib/lib-shared-common.module';

@NgModule({
  declarations: [
    SvcPostsSkeletonComponent
  ],
  imports: [
    LibSharedCommonModule,
    NgxSkeletonLoaderModule,
  ],
  exports: [
    SvcPostsSkeletonComponent
  ],
})
export class SvcPostsSkeletonModule { }
