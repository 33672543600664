<div 
  class="fadeIn flex flex-col sm:flex-row items-center sm:justify-center flex-auto min-w-0 h-full overflow-auto" 
  [ngClass]="{ 'md:items-start': !isSmScreen, 'items-center': isSmScreen }"
  *transloco="let t"
>
  <div 
    class="relative flex-auto items-center justify-center h-full bg-[#17457A]"
    [ngClass]="{ 'hidden': isSmScreen, 'md:flex': !isSmScreen }"
  >
    <div class="w-full h-full bg-cover" [style.background-image]="backgroundImg"></div>
  </div>
  <div 
    class="flex w-full h-full rounded-none shadow-none bg-card relative overflow-y-auto"
    [ngClass]="{ 'md:w-120': !isSmScreen }"
  >
    <div class="flex flex-col w-full min-h-full px-4 sm:px-12 md:px-16">
      <div
        class="flex-auto flex flex-col justify-center self-center w-full max-w-[360px] py-8 sm:py-12 md:py-16 md:pt-24"
        [ngClass]="{ 'max-w-[520px] md:py-16 md:pt-24': !isSmScreen }"
      >
        <!-- Logo -->
        <div class="mt-8 px-4">
          <img class="w-full" [src]="solvaceLogo$ | async"/>
        </div>
  
        <!-- Title -->
        <div class="mt-10 mb-5 text-center text-[#17457A] text-4xl font-extrabold tracking-tight leading-tight">
          {{ t("Esqueceu a senha?") }}
        </div>
        <div class="text-center text-[#17457A] font-medium">{{ t("Nós te mandaremos um código de verificação") }}</div>
  
        <!-- Alert -->
        <svc-alert
          class="mt-8 -mb-4"
          *ngIf="showAlert"
          [appearance]="'outline'"
          [showIcon]="false"
          [type]="alert.type"
          [@shake]="alert.type === 'error'"
        >
          {{ alert.message }}
        </svc-alert>
  
        <!-- Forgot password form -->
        <form class="space-y-6 mt-6" [formGroup]="forgotPasswordForm" #forgotPasswordNgForm="ngForm">
          <!-- User field -->
          <svc-text class="w-full"
            [label]="t('Usuário')"
            [formControl]="forgotPasswordForm.controls.user"
          ></svc-text>
  
          <!-- Submit button -->
          <button
            type="button"
            class="svc-mat-button-large w-full bg-[#17457A] text-white text-ellipsis"
            mat-flat-button
            (click)="sendResetLink()"
          >
            <ng-container *ngIf="!forgotPasswordForm.disabled">{{ t("Enviar código de verificação") }}</ng-container>
            <mat-progress-spinner
              *ngIf="forgotPasswordForm.disabled"
              [diameter]="24"
              [mode]="'indeterminate'"
            ></mat-progress-spinner>
          </button>
  
          <button
            type="button"
            [routerLink]="['/sign-in']"
            class="w-full bg-zinc-100 text-black"
            mat-flat-button
          >
            {{ t("Voltar") }}
          </button>
  
        </form>
      </div>
    </div>
  </div>
</div>
