import { Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { SvcMediaCarouselAddEvent, SvcMediaCarouselRemoveEvent } from 'projects/lib-shared-component/src/lib/svc-media-carousel/interfaces/svc-media-carousel-events';
import { SvcMediaCarouselItem, SvcMediaCarouselModule } from 'projects/lib-shared-component/src/public-api';

@Component({
  standalone: true,
  selector: 'app-new-carousel',
  imports: [
    SvcMediaCarouselModule,
    MatButtonModule,
  ],
  template: `
    <svc-media-carousel
      [items]="items1"
      [editMode]="editMode"
      [height]="220"
      (onAddTriggered)="onAddTriggered1($event)"
      (onDeleteTriggered)="onDeleteTriggered1($event)"
      fitContent="cover"
    />
    <br/>
    <br/>
    <svc-media-carousel
      class="m-auto w-64"
      [height]="170"
      [items]="items2"
      [editMode]="editMode"
      (onAddTriggered)="onAddTriggered2($event)"
      (onDeleteTriggered)="onDeleteTriggered2($event)"
      renderMode="one-by-one"
    />
    <br/>
    <br/>
    <button class="block m-auto" mat-button (click)="editMode = !editMode">Toogle Edit</button>
    <br/>
    <div class="flex gap-2 w-fit m-auto">
      <button mat-button (click)="updateItems1()">Update items 01</button>
      <button mat-button (click)="updateItems2()">Update items 02</button>
    </div>
  `,
  styles: [`
    :host {
      display: block;
      padding: 50px;
    }
  `],
})
export class NewCarouselComponent {
  protected editMode = true;
  private items: SvcMediaCarouselItem[] = [
    {
      type: 'image',
      url: 'https://ericferreira1992.github.io/slider-carousel/assets/img/at_01.jpg'
    },
    {
      type: 'video',
      url: 'https://www.w3schools.com/html/mov_bbb.mp4'
    },
    {
      type: 'image',
      url: 'https://ericferreira1992.github.io/slider-carousel/assets/img/at_02.jpg'
    },
    {
      type: 'image',
      url: 'https://ericferreira1992.github.io/slider-carousel/assets/img/at_03.jpg'
    },
    {
      type: 'image',
      url: 'https://ericferreira1992.github.io/slider-carousel/assets/img/at_04.jpg'
    },
    {
      type: 'image',
      url: 'https://ericferreira1992.github.io/slider-carousel/assets/img/rm_01.jpg'
    },
    {
      type: 'image',
      url: 'https://ericferreira1992.github.io/slider-carousel/assets/img/rm_02.jpg'
    },
    {
      type: 'image',
      url: 'https://ericferreira1992.github.io/slider-carousel/assets/img/rm_03.jpg'
    },
    {
      type: 'image',
      url: 'https://ericferreira1992.github.io/slider-carousel/assets/img/rm_04.jpg'
    },
  ];
  protected items1 = this.items.map((item) => ({...item}));
  protected items2 = this.items.map((item) => ({...item}));
  protected initialItem1: SvcMediaCarouselItem = this.items1[0];
  protected initialItem2: SvcMediaCarouselItem = this.items2[0];
  private extraImages = [
    'https://c4.wallpaperflare.com/wallpaper/542/255/804/grass-line-pink-small-wallpaper-preview.jpg',
    'https://c4.wallpaperflare.com/wallpaper/533/647/735/line-background-abstract-background-geometric-hd-wallpaper-preview.jpg',
    'https://c4.wallpaperflare.com/wallpaper/144/201/200/pineapples-fruit-segments-background-wallpaper-preview.jpg',
    'https://c4.wallpaperflare.com/wallpaper/437/455/804/food-fruit-strawberries-blood-orange-wallpaper-preview.jpg',
  ];

  protected updateItems1() {
    this.items1 = this.items.map((item) => ({...item}));
  }

  protected updateItems2() {
    this.items2 = this.items.map((item) => ({...item}));
  }

  protected onAddTriggered1(event: SvcMediaCarouselAddEvent) {
    const items = event.onAdded({
      item: { type: 'image', url: this.extraImages[Math.floor(Math.random() * this.extraImages.length)] },
      // atBeginning: true,
      // beCurrent: true,
    });
    this.items1 = items;
  }
  protected onDeleteTriggered1(event: SvcMediaCarouselRemoveEvent) {
    const items = event.onDeleted();
    this.items1 = items;
  }

  protected onAddTriggered2(event: SvcMediaCarouselAddEvent) {
    const items = event.onAdded({
      item: { type: 'image', url: this.extraImages[Math.floor(Math.random() * this.extraImages.length)] },
      // atBeginning: true,
      // beCurrent: true,
    });
    this.items2 = items;
  }
  protected onDeleteTriggered2(event: SvcMediaCarouselRemoveEvent) {
    const items = event.onDeleted();
    this.items2 = items;
  }
}
