<div class="relative w-full max-w-200" matDialogFullscreen="ALWAYS">
	<svc-media-carousel *ngIf="carouselHeight"
		[height]="carouselHeight"
		[items]="carouselItems"
		[initialItem]="carouselInitialItem"
		[canClickToExpandImage]="false"
		[isDark]="true"
		[fitContent]="config.fitContent"
		renderMode="one-by-one"
	/>
	<button class="img-viewer-close-btn absolute top-3 right-3 text-white" mat-icon-button [matTooltip]="'Fechar' | transloco" matTooltipPosition="above" (click)="close()">
		<mat-icon class="text-current" svgIcon="mat_solid:close"></mat-icon>
	</button>
</div>