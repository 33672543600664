import { Component } from '@angular/core';
import { SvcBtnBaseComponent } from '../svc-btn-base.component';

@Component({
  selector: '[svc-filled-btn]',
  templateUrl: './svc-filled-btn.component.html',
  styleUrls: ['./svc-filled-btn.component.scss'],
  host: {
    'matRipple': '',
    '[class.svc-filled-btn]': `true`,
    '[class.svc-filled-btn-primary]': `color === 'primary'`,
    '[class.svc-filled-btn-on-primary]': `color === 'on-primary'`,
    '[class.svc-filled-btn-disabled]': `processing || disabled`,
  },
})
export class SvcFilledBtnComponent extends SvcBtnBaseComponent {
}
