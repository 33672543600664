import {HttpClient, HttpParams} from "@angular/common/http";
import {inject, Injectable} from "@angular/core";

import { SvcHttpClient } from "projects/lib-shared-common/src/lib/classes/svc-http-client";
import { AppEnvironmentConfig } from "projects/config/model/environment.config.model";
import { SitesService, UserService } from "projects/lib-shared-core/src/public-api";
import { BosDrilldownData, BosDrilldownFilter } from "../interfaces/svc-modal-bos.interface";
import { Observable } from "rxjs";
import { SvcDialogService } from "projects/lib-shared-component/src/public-api";
import { SvcModalBosComponent } from "../../svc-modal-bos.component";
import { SvcModalBosType } from "../enums/svc-modal-bos.enum";

@Injectable()
export class BosHttpClient extends SvcHttpClient {

  private userService: UserService = inject(UserService);

  public siteId: number = this.userService.user.lastSiteId;

  private readonly _apiUrlBos: string = this.appConfig.APIs.apiUrlBOS;

  constructor(
    private appConfig: AppEnvironmentConfig,
    private _dialogService: SvcDialogService,
    private httpClient: HttpClient,
    private sitesService: SitesService
  ) {
    super(appConfig.APIs.apiUrlBOS, httpClient);
  }

  private getParams(filter: any, params: HttpParams, key: string): HttpParams {
    const value = filter?.[key];

    if (Array.isArray(value))
      value.forEach((val: any) =>
        params = params.append(`${key}`, val)
      );
    else
      params = params.append(key, value);

    return params;
  }
b
  public getBosDataListParams(filter: BosDrilldownFilter): HttpParams {
    let params = new HttpParams();
    Object.keys(filter)?.forEach((key: string) => {
      if (filter?.[key] || key === 'pageIndex')
        params = this.getParams(filter, params, key);
    });

    params = params.append('siteId', this.sitesService.currentSite.siteId);

    return params;
  }

  public getRequestParams(filter: any): HttpParams {
    let params = new HttpParams();
    Object.keys(filter)?.forEach((key: string) => {
      if (filter?.[key] || key === 'pageIndex')
        params = this.getParams(filter, params, key);
    });

    params = params.append('siteId', this.siteId);

    return params;
  }

  public getBosDrilldown(filter: BosDrilldownFilter): Observable<BosDrilldownData> {
    const params = this.getBosDataListParams(filter);

    return this.httpClient.get<BosDrilldownData>(`${this._apiUrlBos}/Feedback/ListDrillDown`, {
      params
    });
  }

  public openBos(mode: SvcModalBosType, id?: number): void {
    this._dialogService.open(SvcModalBosComponent, {
      data: { mode, id },
      width: '100%',
      maxWidth: '500px',
      autoFocus: false,
      panelClass: 'app-dialog-alert'
    });
  }
}
