<div *ngIf="!dismissed" class="alert-container flex items-center text-sm relative" [ngStyle]="{'background-color': this._alertBarTypeData.backgroundColor, 'color':  this._alertBarTypeData.color}">
  <mat-icon class="mat-icon-alert mr-2 ml-2" [ngStyle]="{'color':  this._alertBarTypeData.color}" svgIcon="heroicons_solid:information-circle"></mat-icon>
  <span class="alert-message text-sm mr-4">{{ message }}</span>
  <div *ngIf="translated" class="alert-actions flex items-center">
    <mat-icon class="mat-icon-alert mr-1" [ngStyle]="{'color':  this._alertBarTypeData.color}" svgIcon="heroicons_solid:language"></mat-icon>
    <span class="mr-2 text-sm" [ngStyle]="{'color':  this._alertBarTypeData.color}">{{ 'Traduzido por Google' | transloco }}</span>
    <span class="original-link text-sm cursor-pointer" [ngStyle]="{'color':  this._alertBarTypeData.color}" (click)="noTranslate()">{{ 'Ver original' | transloco }}</span>
  </div>
  <button mat-icon-button class="flex items-center ml-2 mr-2" (click)="closeAlert()">
    <mat-icon class="mat-icon-alert" [ngStyle]="{'color':  this._alertBarTypeData.color}" svgIcon="heroicons_solid:x-mark"></mat-icon>
  </button>
</div>
