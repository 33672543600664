import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AddHashtagFormComponent } from './form/add-hashtag/add-hashtag-form.component';
import { UserHashtagsService } from './services/user-hastags.service';
import { Hashtag, HashtagTypeEnum } from "./services/user-hashtags.types";
import { EditHashtagComponent } from "./form/edit-hashtag/edit-hashtag.component";
import { finalize, Subject, takeUntil, tap } from "rxjs";
import { AllHashtagComponent } from "./form/all-hashtag/all-hashtag.component";
import { ISvcChipGroupedOption } from 'projects/lib-shared-component/src/public-api';
import { SvcUserPreferencesEvents } from '../models/svc-user-preferences-events.model';
import { SvcUserPreferenceFeatureCustomPorpertyKey } from '../models/svc-user-preferences.model';
import { SvcFeatureToggleService } from '../../../services/svc-feature-toggle/svc-feature-toggle.service';
import { StaticApplicationId } from '../../../Constants/static-application-id.enum';
import { Router } from '@angular/router';

@Component({
  selector: 'user-hashtags',
  templateUrl: './user-hashtags.component.html',
  styleUrls: ['./user-hashtags.component.scss']
})
export class UserHashtagsComponent extends SvcUserPreferencesEvents<'hashtag'> implements OnInit {

  @ViewChild('containerHashtags') containerHashtags: ElementRef;

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.refreshSeeMore()
  }

  toggleType: HashtagTypeEnum = HashtagTypeEnum.Local;
  filteredHashtags: Hashtag[];
  hashtags: Hashtag[];
  hashtagsByType: Hashtag[];
  hashtagTypeEnum = HashtagTypeEnum;
  hashtagSearch: string;
  isLoading: boolean = true;
  currentFilteredIds: number[] = [];
  seeMore: boolean;
  seeMoreRevealed: boolean;
  filteredhashtagByPreference = false;
  hashtagFiltered: { availableHashtags: ISvcChipGroupedOption[] };
  hashtagHasError = false;
  postIsEnabled = false;
  cleanFilter = false;

  private _unsubscribeAll: Subject<any> = new Subject<any>();

  constructor(
    private _dialog: MatDialog,
    private _userHashtagService: UserHashtagsService,
    private _router: Router,
    private _featureToggleService: SvcFeatureToggleService,
  ) {
    super({
      featureName: 'Myworkspace Filter Posts Tab by hashtags clicked',
      customPropertyKey: SvcUserPreferenceFeatureCustomPorpertyKey.List,
      customPropertyType: 'string',
      customPropertyValue: ['hashtag'],
      activeCustomProperty: 'hashtag',
      applicationId: StaticApplicationId.myworkspace,
      customFilter: 'HomeHashtagFilterPostsTab'
    });

    this.postIsEnabled = this._featureToggleService.postIsEnabled;
  }

  ngOnInit() {
    this.isLoading = true;
    const subscription = this.activeFilter$.pipe(
      takeUntil(this._unsubscribeAll),
    ).subscribe(response => {
      subscription.unsubscribe();
      const filters = response?.filters ?? [];
      const hashtags = filters?.find(x => x.filterKey === 'hashTagIds')?.filterValue.map(x => parseInt(x));
      if (hashtags?.length > 0) {
        this.seeMore = false;
        this.currentFilteredIds = hashtags;
      }
      this._userHashtagService.getUserHashtags().pipe(
        finalize(() => {
          this.hashtagHasError = this._userHashtagService.userHashtagsHasError;
          this.isLoading = false;
          if (this.currentFilteredIds.length && this._router.url !== '/board/main/posts') {
            this._userHashtagService.filteredHashtagClicked(this.filteredHashtags);  
          }
        }),
      ).subscribe();
    });

    this._userHashtagService.hashtags$.pipe(
      takeUntil(this._unsubscribeAll),
      tap(res => {
        this.hashtagHasError = this._userHashtagService.userHashtagsHasError;
        this.hashtags = res ?? [];
        const isGlobal = this.toggleType == HashtagTypeEnum.Global;
        this.hashtagsByType = this.hashtags.filter((x) => x.isGlobal == isGlobal);
        this.filteredhashtagByPreference = !!(this.currentFilteredIds?.length);
        this.filteredHashtags = this.currentFilteredIds?.length
          ? this.hashtags.filter(item => this.currentFilteredIds?.includes(item.hashtagId))
          : [...this.hashtags];
        this.refreshSeeMore();
      }),
    ).subscribe();

    this._userHashtagService.clearHashtagPreferenceFilter$.pipe(
      takeUntil(this._unsubscribeAll),
    ).subscribe(((clear) => {
      if (clear) {
        this.#clearFilterHashtagByPreference({ fromClick: false });
      }
    }));
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

  onAddEventClicked() {
    this._dialog.open(AddHashtagFormComponent, { width: '450px' });
  }

  onSeeAllEventClicked() {
    this._dialog.open(AllHashtagComponent, { width: '450px' });
  }

  onEditEventClicked() {
    this._dialog.open(EditHashtagComponent, { width: '450px' }).afterClosed().subscribe(response => {
      this.cleanFilter = response?.cleanFilter;
      if (this.cleanFilter) {
        this.#clearFilterHashtagByPreference();
      }
    });
  }

  refreshHashtags() {
    this.isLoading = true;
    this._userHashtagService.getUserHashtags().pipe(
      finalize(() => {
        this.isLoading = false;
      }),
    ).subscribe();
  }

  onToggleTypeEventClicked(type: HashtagTypeEnum) {
    this.toggleType = type;
    const isGlobal = this.toggleType == HashtagTypeEnum.Global;
    this.hashtagsByType = this.hashtags.filter((x) => x.isGlobal == isGlobal);
    if (this.hashtagSearch) {
      this.filter(this.hashtagSearch)
    }
    else {
      this.filteredHashtags = this.hashtagsByType;
      this.refreshSeeMore();
    }

    if (this.postIsEnabled) {
      this.#clearFilterHashtagByPreference();
    }
  }

  filter(value: string) {
    if (!value?.trim()) {
      this.filteredHashtags = this.hashtagsByType;
    } else {
      this.filteredHashtags = this.hashtagsByType.filter(x =>
        x.name.trim().toLowerCase().includes(value.trim().toLowerCase())
      );
    }
    this.refreshSeeMore();
  }

  refreshSeeMore() {
    setTimeout(() => {
      if (!this.containerHashtags && !this.seeMoreRevealed) return;
      this.seeMore = this.containerHashtags?.nativeElement.clientHeight < (this.containerHashtags.nativeElement.scrollHeight);
    });
  }

  filterByHashtagClicked(value: string) {
    this.filteredhashtagByPreference = true;
    this.hashtagSearch = '';

    if (!value?.trim()) {
      this.#clearFilterHashtagByPreference();
    } else {
      this.filteredHashtags = this.hashtagsByType.filter(x => x.name.trim().toLowerCase() === value.trim().toLowerCase());
      this._userHashtagService.filteredHashtagClicked(this.filteredHashtags);
      this.currentFilteredIds = this.filteredHashtags.map(f => f.hashtagId);
      this.setPreferenceFilter({
        filterName: this.preferencesConfig.customFilter,
        filter: { hashTagIds: this.currentFilteredIds },
      });
    }
    this.refreshSeeMore();
  }

  #clearFilterHashtagByPreference(options?: { fromClick: boolean }) {
    this.filteredhashtagByPreference = false;
    this.currentFilteredIds = [];
    this.filteredHashtags = this.hashtagsByType.filter(h => this.hashtags.some(ht => ht.hashtagId === h.hashtagId));
    if (options?.fromClick ?? true) {
      this._userHashtagService.filteredHashtagClicked([]);
    }
    this.clearPreferenceFilter(this.preferencesConfig.customFilter);
    this.refreshSeeMore();
  }
}
