import { NgModule } from '@angular/core';
import { SvcMediaCarouselComponent } from './svc-media-carousel.component';
import { LibSharedCommonModule } from 'projects/lib-shared-common/src/public-api';
import { TranslocoModule } from '@ngneat/transloco';
import { SvcMediaCarouselItemComponent } from './svc-media-carousel-item/svc-media-carousel-item.component';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@NgModule({
  declarations: [
    SvcMediaCarouselComponent,
    SvcMediaCarouselItemComponent,
  ],
  imports: [
    LibSharedCommonModule, 
    MatIconModule,
    MatButtonModule,
    MatTooltipModule,
    MatProgressSpinnerModule,
    TranslocoModule,
  ],
  exports: [
    SvcMediaCarouselComponent,
    SvcMediaCarouselItemComponent,
  ],
})
export class SvcMediaCarouselModule {}
