import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

import { debounceTime, distinctUntilChanged, filter } from 'rxjs';

import { SvcDataTableAccordion, SvcDataTableAccordionColumn } from './interfaces/svc-data-table-accordion.interface';

import _ from 'lodash';
import { SvcSearchFieldComponent } from '../svc-search-field/svc-search-field.component';

@Component({
  selector: 'svc-data-table-accordion',
  templateUrl: './svc-data-table-accordion.component.html',
  styleUrls: ['./svc-data-table-accordion.component.scss']
})
export class SvcDataTableAccordionComponent implements OnChanges {

  @Input() public showSearchControl = true;
  @Input() public showOpenAllAccordions = true;
  @Input() public showDownloadOptions = true;
  @Input() public isAllExpanded: boolean;
  @Input() public dataTable: SvcDataTableAccordion;
  @Input() public isLoading: boolean;
  @Input() public isExporting: boolean;
  @Input() public items: any[];
  @Input() public classContainer: string = 'mx-6';

  @Output() public exportFile = new EventEmitter<'pdf' | 'excel'>();
  @Output() public onSubRowClicked = new EventEmitter<any>();
  @Output() public barClicked = new EventEmitter<any>();
  @Output() public numberClicked = new EventEmitter<any>();


  public sort: MatSort;
  @ViewChild(MatSort) set matSort(matSort: MatSort) {
    if (matSort) {
      this.sort = matSort;
      this.sort.start = 'desc';
      this.setDataSourceAttributes();
    }
  }
  @ViewChild(SvcSearchFieldComponent) svcSearchField: SvcSearchFieldComponent;

  public dataSource = new MatTableDataSource<any>();
  public expandedRows = new Set<any>();

  constructor() {
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes?.items?.previousValue !== changes?.items?.currentValue)
      this.updateData();

    if (changes?.isAllExpanded?.previousValue !== changes?.isAllExpanded?.currentValue)
      this.toggleIsAllExpanded(true);
    
    if (changes?.dataTable?.previousValue !== changes?.dataTable?.currentValue)
      this.setDataMockSkeleton();
  }

  private setDataMockSkeleton(): void {
    if (!this.items?.length && this.isLoading && this.dataTable?.columns?.length) {
      let data = [];
      Array.from({length: 3}).forEach(() => {
        Array.from({length: this.dataTable?.columns?.length ?? 0}).forEach((column: any, index: number) => {
          column = this.dataTable?.columns?.[index];
          data.push({
            [column.property]: '',
          })
        })       
      });
      this.dataSource.data = data;
    }
  }

  private setDataSourceAttributes(): void {
    this.dataSource.sort = this.sort;
  }

  private updateData(): void {
    this.svcSearchField?.reset();
    this.dataSource.data = this.items;
  }

  public getColumnLabels(): string[] {
    return this.dataTable?.columns?.map((column: SvcDataTableAccordionColumn, index: number) => column?.label + index);
  }

  public getColumnLabelsAccordion(): string[] {
    return this.dataTable?.columns?.map((column: SvcDataTableAccordionColumn, index: number) =>
      this.dataTable?.showHeaderRowSubItems && column?.accordion?.label ? column?.accordion?.label : column?.label + (index + this.dataTable?.columns?.length)
    );
  }

  public toggleExpanded(row: any): void {
    if (this.expandedRows.has(row)) {
      this.expandedRows.delete(row);

      if (this.expandedRows.size === 0)
        this.isAllExpanded = false;
    }
    else 
      this.expandedRows.add(row);
  }

  public toggleIsAllExpanded(isInputProperty = false): void {
    if (!isInputProperty)
      this.isAllExpanded = !this.isAllExpanded;

    this.expandedRows.clear();
    if (this.isAllExpanded) 
      this.dataSource?.data?.forEach(value => this.expandedRows.add(value));
  }

  public searchSubItems(filteredItems: any[], searchText: string): void {
    
    filteredItems.forEach((item: any) => {
      let subData: any[] = item[this.dataTable.subItemsPropertyName];
      if (subData?.length) {
        item[this.dataTable.subItemsPropertyName] = subData.filter((subItem: any) =>
          this.dataTable.columns.some((column: SvcDataTableAccordionColumn) =>
            column?.isSearchable &&
            subItem[column?.accordion?.property]?.toString().toLowerCase().includes(searchText)
          )
        );
      }
    });
  }

  public searchItems(text: string): void {
    const searchText = text.trim().toLowerCase();
    let filteredItems = this.items.filter((item: any) =>
      this.dataTable.columns.some((column: SvcDataTableAccordionColumn) =>
        column?.isSearchable && 
        (
          item[column.property]?.toString().toLowerCase().includes(searchText) ||
          item?.[this.dataTable?.subItemsPropertyName]?.some(data =>
            data?.[column?.accordion?.property]?.toString()?.toLowerCase()?.includes(searchText)
          )
        )
      )
    );

    filteredItems = _.cloneDeep(filteredItems);
    this.searchSubItems(filteredItems, searchText);
    this._restoreExpandedRows(filteredItems);
    this.dataSource.data = filteredItems;
  }

  private _restoreExpandedRows(filteredItems: any[]): void {
    const previousExpandedRows = _.cloneDeep(this.expandedRows);
    this.expandedRows.clear();
    previousExpandedRows?.forEach(row => 
      filteredItems?.forEach(info => {
        if (JSON.stringify(info) === JSON.stringify(row))
          this.expandedRows.add(info);
      })
    );
  }

  public barClick(pointerEvent: PointerEvent, row: any): void {
    pointerEvent.stopPropagation();
    this.barClicked.emit(row);
  }
  
  public onNumberClick(pointerEvent: PointerEvent, row: any): void {
    pointerEvent.stopPropagation();
    this.numberClicked.emit(row);
  }
}
