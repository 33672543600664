import { Component, ElementRef, EventEmitter, inject, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { SvcDialogService } from '../svc-dialogs/svc-dialog.service';
import { ISvcCarousel } from './interfaces/svc-carousel.interface';

@Component({
  selector: 'svc-carousel',
  templateUrl: './svc-carousel.component.html',
  styleUrls: ['./svc-carousel.component.scss']
})
export class SvcCarouselComponent implements OnInit, OnChanges {
  readonly #svcDialogService = inject(SvcDialogService);

  @ViewChild('video') public video: ElementRef<HTMLVideoElement>;

  @Input() slides: ISvcCarousel[] = [];
  @Input() readOnly = false;
  @Input() height = 300;
  @Input() minHeight = 0;
  @Input() fitContent: 'contain' | 'cover' | 'fill' | 'none' | 'scale-down' = 'contain';
  @Input() imageViewer = true;
  @Input() slidesLimit = 0;
  @Input() goToEmptyViewOnAddSlide = false;
  @Input('video-controls') videoControls = true;

  @Output('addSlide') onAddSlide = new EventEmitter<void>();
  @Output('removeSlide') onRemoveSlide = new EventEmitter<ISvcCarousel>();
  @Output('currentSlide') onCurrentSlide = new EventEmitter<ISvcCarousel>();
  @Output() onVideoPlay = new EventEmitter<HTMLVideoElement>();
  @Output() onVideoPause = new EventEmitter<void>();
  @Output() onVideoEnd = new EventEmitter<void>();

  protected currentSlide: ISvcCarousel;
  protected get currentIndex(): number {
    const slide = this.slides.find((s) => this.currentSlide?.id ? s.id === this.currentSlide.id : s.url === this.currentSlide?.url);
    return slide ? this.slides.indexOf(slide) : null;
  }
  protected get isInEmptyView() {
    return this.currentSlide == null;
  }
  protected get isAddAvailable() {
    return this.onAddSlide.observed && (!this.slidesLimit || this.slidesLimit < (this.slides?.length ?? 0));
  }
  protected get isRemoveAvailable() {
    return this.onRemoveSlide.observed;
  }
  protected get isEditingMode() {
    return this.isAddAvailable || this.isRemoveAvailable;
  }

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if ('slides' in changes) {
      if (this.goToEmptyViewOnAddSlide) return;
      if ((!this.currentSlide && this.slides?.length > 0)|| !(this.slides?.some((s) => this.currentSlide?.id ? s.id === this.currentSlide.id : s.url === this.currentSlide?.url) ?? false)) {
        this.setSlide(this.slides?.length > 0 ? this.slides[0] : null);
      }
    }
  }

  private setSlide(slide: ISvcCarousel, options?: { emitEvent?: boolean }) {
    this.currentSlide = slide;
    if (slide && (!slide.type || slide.type === 'image') && slide['fileLoading'] == null) {
      slide['fileLoading'] = true;
    }
    if (options?.emitEvent ?? true) {
      this.onCurrentSlide.emit(slide);
    }
  }

  public goToPrevious(event: Event): void {
    event.stopPropagation();

    if (this.isInEmptyView) return;
    
    if (this.currentIndex === 0) {
      this.setSlide(null);
    } else {
      const currentIndex = this.currentIndex - 1;
      this.setSlide(this.slides[currentIndex]);
    }
  }

  public goToNext(event: Event): void {
    event.stopPropagation();

    const slides = this.slides;
    const isLastSlide = this.currentIndex === slides.length - 1;
    if (!isLastSlide) {
      const currentIndex = this.currentIndex == null ? 0 : this.currentIndex + 1;
      this.setSlide(slides[currentIndex]);
    }
  }

  public goToSlide(index: number, options?: { emitEvent?: boolean }): void {
    if (index < this.slides.length) {
      this.setSlide(this.slides[index], options);
    }
    else if ((!this.currentSlide && this.slides?.length > 0) || !(this.slides?.some((s) => this.currentSlide?.id ? s.id === this.currentSlide.id : s.url === this.currentSlide?.url) ?? false)) {
      this.setSlide(this.slides?.length > 0 ? this.slides[0] : null, options);
    }
  }

  public goToEmptyView(): void {
    if (this.isEditingMode) {
      this.setSlide(null);
    }
  }

  public getCurrentSlide(): ISvcCarousel {
    return this.currentSlide;
  }

  public getCurrentIndex(): number {
    return this.currentIndex;
  }

  protected emitAddSlide() {
    this.onAddSlide.emit();
  }

  public removeSlide() {
    if (this.currentSlide) {
      this.onRemoveSlide.emit(this.currentSlide);
    }
  }

  public openInViewer() {
    if (this.imageViewer && (this.currentSlide?.type === 'image' || !this.currentSlide?.type)) {
      this.#svcDialogService.openImage(this.currentSlide.url);
    }
  }
}
