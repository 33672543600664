import { Component, ElementRef, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, } from '@angular/core';
import { BehaviorSubject } from 'rxjs';


@Component({
	selector: 'vertical-navigation',
	templateUrl: './vertical-navigation.component.html',
	styleUrls: ['./vertical-navigation.component.scss'],
	host: {
		'[class.closed]': `!opened`,
		'[class.over]': `mode === 'over'`,
		'[class.retracted]': `isRetracted`,
		'[class.small-in-above-md]': `isSmallInAboveMd`,
	},
})
export class VerticalNavigationComponent implements OnInit, OnChanges, OnDestroy {

	@Input()
	public mode: 'over' | 'side' = 'side';
	@Input()
	public opened: boolean = true;
	@Input()
	public retracted: boolean = false;
	@Input()
	public headerWithOverflow: boolean = true;
	@Input()
	public forceOverlay: boolean = false;
	@Input()
	public smallInAboveMd: boolean = false;

	public isRetracted: boolean = false;
	private isSmallInAboveMd: boolean = false;

	public rectracting$ = new BehaviorSubject<boolean>(false);
	public opening$ = new BehaviorSubject<boolean>(true);

	protected overlayEl: HTMLElement;
	private overlayIsClosing = false;

	constructor(
		protected elementRef: ElementRef<HTMLElement>,
	) { }

	ngOnInit(): void {
		this.handleInputs();
	}

	ngOnChanges(changes: SimpleChanges): void {
		if ('mode' in changes) {
			this.forceOverlay = false;
			if (this.mode === 'side') {
				this.isRetracted = true;
			}
		}
		this.handleInputs();
	}

	private handleInputs(options?: { retractedChanged?: boolean }) {
		this.opened;
		this.isSmallInAboveMd = this.smallInAboveMd ?? false;
		this.isRetracted = (this.mode === 'over') ? false : (options?.retractedChanged ? this.isRetracted : this.retracted);
		this.checkIfNeedShowOverlay();
	}

	public setForceOverlay(value: boolean): void {
		this.forceOverlay = value;
		this.checkIfNeedShowOverlay();
	}

	public toggle(): void {
		if (this.overlayIsClosing) return;
		if (!this.opened || (!this.forceOverlay || this.mode !== 'side')) {
			if (this.opened) {
				this.forceOverlay = false;
			}
			this.opened = !this.opened;
			this.opening$.next(this.opened);
			this.handleInputs();
		}
	}

	public toggleRetraction(): void {
		this.isRetracted = !this.isRetracted;
		this.rectracting$.next(this.isRetracted);
		this.handleInputs({ retractedChanged: true });
	}

	protected createOverlay() {
		if (this.overlayEl == null) {
			this.elementRef.nativeElement.insertAdjacentHTML('afterend', '<div class="vertical-navigation-overlay fadeIn"></div>');
			this.overlayEl = this.elementRef.nativeElement.parentElement.querySelector('.vertical-navigation-overlay');
			this.overlayEl.addEventListener('click', () => this.toggle());
		}
		else {
			this.overlayEl?.classList.remove('fadeOut');
			this.overlayEl?.classList.add('fadeIn');
		}
	}

	private removeOverlay() {
		this.overlayIsClosing = true;
		this.overlayEl?.classList.remove('fadeIn');
		this.overlayEl?.classList.add('fadeOut');
		setTimeout(() => {
			if (!(this.mode === 'over') && !this.forceOverlay) {
				this.overlayEl?.remove();
				this.overlayEl = null;
			}
			this.overlayIsClosing = false;
		}, 300);
	}

	private checkIfNeedShowOverlay() {
		((this.mode === 'over')) || this.forceOverlay ? this.createOverlay() : this.removeOverlay();
		if (this.opened || this.forceOverlay) {
			this.overlayEl?.classList.remove('closed');
		}
		else {
			this.overlayEl?.classList.add('closed');
		}
	}

	ngOnDestroy(): void {
	}
}
