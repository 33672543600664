import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from "@angular/router";
import { LibServiceKaizenAnalyticsService } from 'projects/lib-service-kaizen-analytics/src/public-api';
import { AutoDestroy } from 'projects/lib-shared-common/src/lib/decorators/auto-destroy';
import { HttpErrorService } from 'projects/lib-shared-common/src/public-api';
import { SvcAppSettings } from 'projects/lib-shared-core/src/public-api';
import { catchError, Subject, takeUntil, tap } from 'rxjs';

@Component({
  selector: 'svc-kaizen-resume',
  templateUrl: './svc-kaizen-resume.component.html',
  styleUrls: ['./svc-kaizen-resume.component.scss']
})
export class SvcKaizenResumeComponent implements OnInit {

  summary: { lcy: number, totalIdeas: number };
  disable: boolean;
  currencyAcronym: string;
  isLoading = true;

  @AutoDestroy destroy$: Subject<void> = new Subject<void>();

  constructor(
    private kaizenService: LibServiceKaizenAnalyticsService,
    private appSettings: SvcAppSettings,
    private router: Router,
    private _httpErrorService: HttpErrorService
  ) {
    this.currencyAcronym = this.appSettings.currencyAcronym;
  }

  ngOnInit(): void {
    this.kaizenService._kaizenSummary$.pipe(
      tap((response) => {
        this.summary = response;
        this.isLoading = false;
      }),
      catchError((err) => {
        this._httpErrorService.showErrorInToast(err);
        this.isLoading = false;
        return err;
      }),
      takeUntil(this.destroy$),
    ).subscribe();

    const path = '/board/main/dashboard/subordinates';
    this.disable = this.router.url.includes(path)
    this.router.events.pipe(
      takeUntil(this.destroy$),
    ).subscribe((event: any) => {
      if (event.hasOwnProperty('routerEvent') && event.routerEvent instanceof NavigationEnd)
        this.disable = event.routerEvent.url.includes(path);
    });

    this.#getKaizenSummary();
  }

  public getKaizenSummary(event?: Event) {
    event.stopImmediatePropagation();
    this.#getKaizenSummary();
  }

  #getKaizenSummary() {
    this.isLoading = true;
    this.kaizenService.getKaizenSummary().subscribe();
  }
}
