import { NgModule } from '@angular/core';
import { SvcBtnModule } from './svc-btn/svc-btn.module';
import { SvcFilledBtnModule } from './svc-filled-btn/svc-filled-btn.module';
import { SvcOutlinedBtnModule } from './svc-outlined-btn/svc-outlined-btn.module';

@NgModule({
  exports: [
    SvcBtnModule,
    SvcFilledBtnModule,
    SvcOutlinedBtnModule,
  ]
})
export class SvcButtonModule { }
