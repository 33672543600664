<div class="w-full flex flex-col gap-4" *screenSizes="let size">

  <div 
    class="flex flex-col relative bg-white rounded-lg px-3 py-1" 
    [ngClass]="{ 'p-4 shadow': size.isAboveSM, 'px-3 py-1': size.isBelowMD }"
    *ngFor="let post of [1, 2, 3]; index as index"
  >
    <div class="flex items-center justify-end gap-1 border-b mb-2 pb-2 px-3 -mx-4 md:-mt-2" *ngIf="index === 0">
      <div class="flex-auto flex items-center gap-2 pl-0.5">
        <ngx-skeleton-loader
          [theme]="{ width: '24px', height: '24px', borderRadius: '50%' }"
        >
        </ngx-skeleton-loader>
    
        <div class="flex gap-2">
          <ngx-skeleton-loader
            [theme]="{ width: '100px', height: '24px', borderRadius: '0' }"
          >
          </ngx-skeleton-loader>

          <ngx-skeleton-loader
            [theme]="{ width: '70px', height: '24px', borderRadius: '0' }"
          >
          </ngx-skeleton-loader>
        </div>
      </div>

      <div class="flex-auto flex flex-row justify-end self-start gap-1">
        <ngx-skeleton-loader
          [theme]="{ width: '24px', height: '24px', borderRadius: '4px' }"
        >
        </ngx-skeleton-loader>

        <ngx-skeleton-loader
          [theme]="{ width: '24px', height: '24px', borderRadius: '4px' }"
        >
        </ngx-skeleton-loader>
      </div>
    </div>

    <div class="flex-auto flex flex-col sm:flex-row sm:items-center gap-3">
      <div 
        class="flex-auto flex flex-row justify-end self-start gap-1 w-full"
        *ngIf="size.isXS && index > 0"
      >
        <ngx-skeleton-loader
          [theme]="{ width: '24px', height: '24px', borderRadius: '4px' }"
        >
        </ngx-skeleton-loader>

        <ngx-skeleton-loader
          *ngIf="index !== 1"
          [theme]="{ width: '24px', height: '24px', borderRadius: '4px' }"
        >
        </ngx-skeleton-loader>
      </div>

      <div class="flex-auto flex flex-row sm:items-center gap-3">
        <ngx-skeleton-loader 
          class="self-center"
          [theme]="{ width: '48px', height: '48px', borderRadius: '50%'}"
        >
        </ngx-skeleton-loader>
        
        <div class="flex flex-col gap-1">
          <div class="flex gap-1 flex-col sm:flex-row">
            <ngx-skeleton-loader
              [theme]="{ width: '150px', height: '20px', borderRadius: '0' }"
            >
            </ngx-skeleton-loader>

            <span *ngIf="!size.isXS && index > 0" class="border-r"></span>
    
            <ngx-skeleton-loader
              *ngIf="index > 0"
              [theme]="{ width: '125px', height: '20px', borderRadius: '0' }"
            >
            </ngx-skeleton-loader>
          </div>

          <div class="flex gap-3 items-center">
            <ngx-skeleton-loader
              [theme]="{ width: '118px', height: '16px', borderRadius: '0' }"
            >
            </ngx-skeleton-loader>

            <ngx-skeleton-loader
              [theme]="{ width: '44px', height: '16px', borderRadius: '0' }"
            >
            </ngx-skeleton-loader>
          </div>  
        </div>
      </div>

      <div class="flex-auto flex flex-row justify-end self-start gap-1"  *ngIf="!size.isXS && index > 0">
        <ngx-skeleton-loader
          [theme]="{ width: '24px', height: '24px', borderRadius: '4px' }"
        >
        </ngx-skeleton-loader>
  
        <ngx-skeleton-loader
          *ngIf="index !== 1"
          [theme]="{ width: '24px', height: '24px', borderRadius: '4px' }"
        >
        </ngx-skeleton-loader>
      </div>
    </div>

    <ngx-skeleton-loader 
      class="pt-2"
      *ngIf="index === 2"
      [theme]="{ width: '90px', height: '24px', borderRadius: '4px'}"
    >
    </ngx-skeleton-loader>

    <div class="flex flex-col gap-1 mt-3">
      <ngx-skeleton-loader 
        class="pt-2 pb-1"
        *ngIf="index === 2"
        [theme]="{ width: '80px', height: '19px', borderRadius: '0' }"
      >
      </ngx-skeleton-loader>

      <ngx-skeleton-loader
        *ngFor="let line of [1, 2, 3]"
        [theme]="{ width: '100%', height: '24px', borderRadius: '0' }"
      >
      </ngx-skeleton-loader>

      <ngx-skeleton-loader
        [theme]="{ width: '70px', height: '19px', borderRadius: '0', marginTop: '4px' }"
      >
      </ngx-skeleton-loader>
    </div>

    <div class="mt-3" *ngIf="index === 2">
      <ngx-skeleton-loader
        [theme]="{ width: '100%', height: '400px', borderRadius: '0' }"
      >
      </ngx-skeleton-loader>
    </div>

    <div class="flex flex-col">
      <div class="flex flex-row my-2 flex-wrap" *ngIf="index !== 1">
        <ngx-skeleton-loader
          class="flex flex-auto justify-start"
          [theme]="{ width: '50px', height: '20px', borderRadius: '0'}"
        >
        </ngx-skeleton-loader>

        <ngx-skeleton-loader
          class="flex items-center justify-end"
          [theme]="{ width: '130px', height: '20px', borderRadius: '0'}"
        >
        </ngx-skeleton-loader>
      </div>

      <div class="border-t mb-2" [class.my-2]="index === 1">
      </div>

      <div class="grid grid-cols-3 gap-4 mb-3">
        <ngx-skeleton-loader
          *ngFor="let action of [1, 2, 3]"
          [theme]="{ width: '100%', height: '24px', borderRadius: '4px'}"
        >
        </ngx-skeleton-loader>
      </div>
    </div>

  </div>

</div>