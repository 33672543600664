<div *ngIf="!chartInfo?.error" class="flex-col flex-auto p-2 w-full"
  [ngStyle]="chartInfo?.loading ? { 'filter': 'blur(2px)' } : null"
  [class]="(hasDataChart || !useSkeleton) && !isLoading ? 'flex' : 'hidden'"  
>

  <div class="flex flex-col" *ngIf="chartInfo?.title || chartInfo?.description">
    <div class="text-2xl font-bold leading-6" *ngIf="chartInfo?.title">
      {{ chartInfo?.title }}
    </div>

    <div class="text-xl font-light" *ngIf="chartInfo?.description">
      {{ chartInfo?.total | formatNumberDefault }} {{ chartInfo?.description }}
    </div>
  </div>

  <div class="flex flex-col md:flex-row items-center justify-center">
    <div
      echarts
      #chart
      class="echarts flex flex-auto w-full"
      [style.height]="height"
      [options]="chartOptions"
      (chartInit)="onChartInit($event)"
      (chartClick)="onChartClick($event)"
    ></div>
  </div>
</div>

<div *ngIf="chartInfo?.error" class="absolute flex justify-center items-center left-0 top-0 w-full h-full"
  (click)="refresh($event)">
  <mat-icon class="text-primary icon-size-4 inline-block align-middle mr-1"
    [svgIcon]="'heroicons_solid:exclamation-triangle'" [style.verticalAlign]="'middle'"></mat-icon>

  <span class="text-primary text-base underline">
    {{ errorMsg }}
  </span>
</div>

<div *ngIf="!chartInfo?.error && chartInfo?.loading && !useSkeleton"
  class="absolute flex justify-center items-center left-0 top-0 w-full h-full">
  <mat-spinner diameter="30"></mat-spinner>
</div>

<ng-container *screenSizes="let size">
  <div class="flex flex-col gap-3 p-2" *ngIf="!chartInfo?.error && (chartInfo?.loading || isLoading) && useSkeleton">
  
    <div class="flex flex-col gap-0.5" *ngIf="chartInfo?.title || chartInfo?.description">
      <ngx-skeleton-loader 
        *ngIf="chartInfo?.title"
        [theme]="{width: '145px', height: '24px', border: '0'}"
      >
      </ngx-skeleton-loader>
  
      <ngx-skeleton-loader
        *ngIf="chartInfo?.description" 
        [theme]="{width: '212px', height: '30px', border: '0'}"
      >
      </ngx-skeleton-loader>
    </div>
  
    <div 
      class="items-end gap-3"
      [ngClass]="{
        'flex justify-center': typeSkeleton === 'default',
        'grid grid-flow-col overflow-hidden': typeSkeleton === 'custom',
        'gap-1': typeSkeleton === 'custom' && size?.isXS
      }"
    >
      <div class="flex justify-center flex-col gap-1" *ngFor="let bar of barsSkeleton; index as index" >
        <ngx-skeleton-loader 
          [ngClass]="classSizeBar"
          [theme]="{width: '100%', minHeight: '33px', height: bar, borderTopLeftRadius: '8px', borderTopRightRadius: '8px', maxHeight: height}"
        >
        </ngx-skeleton-loader>
  
        <ngx-skeleton-loader 
          class="flex justify-center self-center justify-self-center"
          [class.rotate-90]="orientations.VERTICAL === orientation"
          [theme]="{width: '28px', height: '16px', borderRadius: '0'}"
        >
        </ngx-skeleton-loader>
      </div>
    </div>
  </div>
</ng-container>