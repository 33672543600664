import { Component, HostBinding, HostListener, OnDestroy, OnInit, } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { tap } from 'rxjs';
import { LanguagesService } from '../languages/services/languages.service';
import { TranslocoService } from '@ngneat/transloco';
import { AuthService } from '../../auth/auth.service';

export enum PageCantBeLoadedType {
  DEFAULT = 'DEFAULT',
  PERMISSION = 'PERMISSION',
  GDPR = 'GDPR'
}

@Component({
  selector: 'app-page-cant-be-load',
  templateUrl: './page-cant-be-load.component.html',
  styleUrls: ['./page-cant-be-load.component.scss'],
})
export class PageCantBeLoadComponent implements OnInit, OnDestroy {
  @HostBinding('class.svc-005F86') svcThemeClass = true;

  public isSmScreen: boolean;
  public isBacking: boolean;
  public isLogged: boolean = false;
  public termsLoaded: boolean = false;
  public previousPath: string;
  public type: PageCantBeLoadedType = PageCantBeLoadedType.DEFAULT;

  constructor(
    private router: Router,
    private _authService: AuthService,
    public _translocoService: TranslocoService,
    public _activatedRoute: ActivatedRoute,
  ) {
    this._activatedRoute.queryParamMap.pipe(
      tap(data => {
        this.type = (data.get('type') ?? PageCantBeLoadedType.DEFAULT) as PageCantBeLoadedType;
        this.previousPath = data.get('from');
      })
    ).subscribe();

    this._authService.check().pipe(
      tap((authenticated) => {
        this.isLogged = authenticated;
        
        if (this._translocoService.getTranslation().size > 0) {
          this.termsLoaded = true;
        }
        else {
          this._translocoService.events$.subscribe((e) => {
            this.termsLoaded = e.type === 'translationLoadSuccess';
          });
        }

        if (!this._translocoService.getAvailableLangs().length) {
          this._translocoService.setAvailableLangs([{ id: 'en-US', label: 'English' }]);
          this._translocoService.setActiveLang('en-US');
        }
      })
    ).subscribe();
  }

  ngOnInit(): void {
    this.onWindowResize();
  }

  public async refresh(): Promise<void> {
    this.isBacking = true;
    await this.router.navigate(['../']);
    document.body.classList.add('hidden');
    setTimeout(() => location.reload());
  }

  public back(): void {
    this.isBacking = true;
    if (this.previousPath) {
      this.router.navigate(['/' + this.previousPath]);
    }
    else {
      this.router.navigate(['/']).finally(() => {
        document.body.classList.add('hidden');
        setTimeout(() => location.reload());
      })
    }
  }

  public signOut() {
    this.router.navigate(['/sign-out']);
  }

  @HostListener('window:resize')
  onWindowResize() {
    this.isSmScreen = window.innerWidth < 600;
  }

  ngOnDestroy(): void {
  }
}
