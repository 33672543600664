import { Component, HostListener, Input } from '@angular/core';

@Component({
  selector: '[svc-btn-base]',
  template: ``,
  host: {},
})
export class SvcBtnBaseComponent {
  @Input() color: 'primary' | 'on-primary' = 'primary';
  @Input() public processing = false;
  @Input('disabled') public set setDisabled(value: boolean) {
    value = typeof value !== 'boolean' ? (value || true) : value;
    this.disabled = typeof value === 'string' ? value === 'true' : value;
  }

  protected disabled: boolean = false;

  @HostListener('click', ['$event']) protected onClick(event: MouseEvent) {
    if (this.disabled || this.processing) {
      event.stopImmediatePropagation();
    }
  }
}
