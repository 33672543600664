import { Component, ElementRef, EventEmitter, OnDestroy, Output, ViewChild } from '@angular/core';
import { NewsletterService } from '../../services/newsletter.service';
import { AgVirtualSrollComponent } from 'ag-virtual-scroll';
import { MatTab, MatTabGroup } from '@angular/material/tabs';
import { Subject, takeUntil, tap } from 'rxjs';
import { Version } from '../../models/newsletter.model';

@Component({
  selector: 'lib-newsletter-content',
  templateUrl: './newsletter-content.component.html',
  styleUrls: ['./newsletter-content.component.scss']
})
export class NewsletterContentComponent implements OnDestroy {
  protected newsletterItems$ = this.newsletterService.newsletters$;
  protected isLoading$ = this.newsletterService.isNewslettersLoading$;

  @Output() clickedItem = new EventEmitter<Version>();

  @ViewChild(AgVirtualSrollComponent) agVirtualScroll: AgVirtualSrollComponent;

  private _unsubscribeAll: Subject<any> = new Subject<any>();

  constructor(
    private tabGroup: MatTabGroup,
    private tab: MatTab,
    private newsletterService: NewsletterService,
    public elRef: ElementRef<HTMLElement>,
  ) {
    this.tabGroup.selectedIndexChange.pipe(
      takeUntil(this._unsubscribeAll),
      tap((index: number) => {
        if (index === this.tabGroup._tabs.toArray().indexOf(this.tab)) {
          setTimeout(() => this.agVirtualScroll?.refreshData());
        }
      })
    ).subscribe();
  }

  emitClickItem(newsletter: Version) {
    this.clickedItem.emit(newsletter);
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }
}
