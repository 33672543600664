import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { OverlayModule } from '@angular/cdk/overlay';
import { PortalModule } from '@angular/cdk/portal';
import { TranslocoModule } from '@ngneat/transloco';
import { AgVirtualScrollModule } from 'ag-virtual-scroll';
import { LibSharedCommonModule } from 'projects/lib-shared-common/src/public-api';
import { LibSharedComponentModule, SvcButtonsModule, SvcUserAvatarModule } from 'projects/lib-shared-component/src/public-api';
import { SvcNotificationsComponent } from './svc-notifications.component';
import { MatTabsModule } from '@angular/material/tabs';
import { NotificationContentComponent } from './components/notification-content/notification-content.component';
import { NewsletterContentComponent } from './components/newsletter-content/newsletter-content.component';
import { CommunicationContentComponent } from './components/communication-content/communication-content.component';
import { SvcSmartUserAvatarModule} from '../svc-smart-user-avatar/svc-smart-user-avatar.module';
import { ModalViewCommunicationModule} from '../../modals/modal-view-communication/modal-view-communication.module';
import { ModalInsightsModule } from '../../modals/modal-insights/modal-insights.module';

@NgModule({
	declarations: [
		SvcNotificationsComponent,
    NotificationContentComponent,
    NewsletterContentComponent,
		CommunicationContentComponent,
	],
	imports: [
		RouterModule,
		OverlayModule,
		PortalModule,
		LibSharedCommonModule,
		LibSharedComponentModule,
		TranslocoModule,
		SvcButtonsModule,
		SvcUserAvatarModule,
		AgVirtualScrollModule,
		MatTabsModule,
		SvcSmartUserAvatarModule,
		ModalViewCommunicationModule,
    ModalInsightsModule,
	],
	exports: [
		SvcNotificationsComponent
	]
})
export class SvcNotificationsModule {
}
