<div *ngIf="!chartInfo?.error" class="flex flex-col flex-auto p-2" [class]="chartInfo?.loading ? 'hidden' : 'flex'">
  <div class="flex flex-col">
    <div class="text-2xl font-bold tracking-tight leading-6">
      {{ chartInfo?.title }}
    </div>
    <div class="text-xl font-thin tracking-tight leading-6">
      {{ chartInfo?.total | formatNumberDefault }} {{ chartInfo?.description }}
    </div>
  </div>
  <div class="flex flex-col md:flex-row items-center justify-center">
    <div
      class="echarts flex flex-auto w-full"
      [style.height]="height"
      echarts
      [options]="chartOptions"
      (chartInit)="onChartInit($event)"
      (chartClick)="onChartClick($event)"
    ></div>
  </div>
</div>
<div *ngIf="chartInfo?.error" class="absolute flex justify-center items-center left-0 top-0 w-full h-full" (click)="refresh($event)">
  <mat-icon class="text-primary icon-size-4 inline-block align-middle mr-1" [svgIcon]="'heroicons_solid:exclamation-triangle'" [style.verticalAlign]="'middle'"></mat-icon>
  <span class="text-primary text-sm underline">
    {{ errorMsg }}
  </span>
</div>

<div class="flex flex-col gap-2 p-2 h-full" *ngIf="!chartInfo?.error && chartInfo?.loading">
  <div class="flex flex-col gap-0.5">
    <ngx-skeleton-loader 
      [theme]="{width: '130px', height: '24px', border: '0'}"
    >
    </ngx-skeleton-loader>

    <ngx-skeleton-loader 
      [theme]="{width: '60px', height: '24px', border: '0'}"
    >
    </ngx-skeleton-loader>
  </div>

  <div class="flex flex-col justify-around h-full gap-1">
    <div class="flex gap-1" *ngFor="let bar of barsSkeleton; index as index" >
      <ngx-skeleton-loader
        class="h-[11px]"
        [theme]="{width: '40px', height: '11px', border: 0}"
      >
      </ngx-skeleton-loader>
  
      <ngx-skeleton-loader
        [style.width.%]="(index + 1) * 25"
        [theme]="{width: '100%', height: '25px', borderRadius: '50px'}"
      >
      </ngx-skeleton-loader>
    </div>
  </div>

  <div class="flex items-end gap-1 flex-wrap">
    <div class="flex items-center gap-0.5" *ngFor="let legend of legendSkeleton">
      <ngx-skeleton-loader
        [theme]="{width: '12px', height: '12px', borderRadius: '50%'}"
      >
      </ngx-skeleton-loader>

      <ngx-skeleton-loader
        [theme]="{width: '60px', height: '11px', borderRadius: '0'}"
      >
      </ngx-skeleton-loader>
    </div>
  </div>
</div>