import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { TasksCategory } from "../models/tasks-category";
import { TranslocoService } from "@ngneat/transloco";
import { Subject, takeUntil } from "rxjs";
import { MainTasksService } from "../main-tasks.service";

@Component({
  selector: 'main-tasks',
  templateUrl: './main-tasks.component.html',
  styleUrls: ['./main-tasks.component.scss']
})
export class MainTasksComponent implements OnInit {

  allTasks: any = [];
  categories: any = [];
  @Output() onTotalChanged = new EventEmitter<number>();
  @Output() onLoadingChanged = new EventEmitter<number>();
  @Output() onError = new EventEmitter<{ allHadErrors: boolean, hadAtLeastOneError: boolean }>();

  public apiErrors = {
    'Action Plan': false,
    'Checklist': false,
    'CIL': false,
    'Defect Tag': false
  };
  public allHadErrors = false;
  public hadAtLeastOneError = false;
  public isTotalTasksLoading = true;

  private _unsubscribeAll: Subject<any> = new Subject<any>();
  private _totalTasks: any = {};

  constructor(
    private _mainTasksService: MainTasksService,
    private _translocoService: TranslocoService,
  ) { }

  ngOnInit(): void {
    this._translocoService.selectTranslation().subscribe(() => {
      this.categories = [
        {
          enum: [TasksCategory.LATE],
          title: this._translocoService.translate('Atrasados'),
          opened: false
        },
        {
          enum: [TasksCategory.TODAY],
          title: this._translocoService.translate('Hoje'),
          opened: true
        },
        {
          enum: [TasksCategory.TOMORROW_UP_TO_7_DAYS],
          title: this._translocoService.translate('<= 7 dias'),
          opened: false
        },
        {
          enum: [TasksCategory.MORE_THAN_7_DAYS],
          title: this._translocoService.translate('> 7 dias'),
          opened: false
        }
      ];
    });

    this.allTasks = this._mainTasksService._allTasks;
    this.updateTotalTasks();

    this._mainTasksService.allTasksUpdated$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(value => {
        this.allTasks = value;
        this.updateTotalTasks();
      });

    this._mainTasksService.isAllTasksLoading$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((isLoading) => {
        this.isTotalTasksLoading = isLoading;
        this.onLoadingChanged.emit(isLoading);

        if (!isLoading) {
          this.allHadErrors = Object.keys(this.apiErrors).every((key) => this.apiErrors[key]);
          this.hadAtLeastOneError = Object.keys(this.apiErrors).filter((key) => this.apiErrors[key]).length > 0;
          this.onError.emit({ allHadErrors: this.allHadErrors, hadAtLeastOneError: this.hadAtLeastOneError })
        } else {
          for (const key in this.apiErrors) {
            this.apiErrors[key] = false;
          }
        }
      });

    this._mainTasksService.hasError$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(errorInfo => {
        this.apiErrors[errorInfo.apiName] = true;
      });
  }

  getTasks() {
    this._mainTasksService.getUserDrillDowns();
    this.allHadErrors = Object.keys(this.apiErrors).every((key) => this.apiErrors[key]);
  }

  private updateTotalTasks() {
    this._totalTasks = Object.values(this.allTasks).reduce((total: number, tasks: any[]) => total + tasks.length, 0);
    this.onTotalChanged.emit(this._totalTasks);
  }
}
