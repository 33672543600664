<ng-container *ngIf="!isResolving">
  <!-- Toast manager -->
  <svc-toast></svc-toast>

  <!-- Loading bar -->
  <loading-bar></loading-bar>

  <div @fadeInOut class="absolute inset-0 flex flex-col min-w-0 overflow-hidden" *screenSizes="let size">
    <alert-bar></alert-bar>
    <div class="relative flex flex-0 items-center w-full h-16 z-49 shadow bg-primary text-on-primary">
      <div class="flex w-full px-2 lg:px-4" [style.maxWidth.px]="(feedIsEnabled && windowWidth >= 1782) ? 1782 : 1440" [style.margin]="'auto'">
        <button class="md:hidden mr-3" mat-icon-button (click)="toggleNavigation()">
          <mat-icon class="text-current" svgIcon="heroicons_solid:bars-3"></mat-icon>
        </button>

        <div class="flex" [style.width.px]="size.isAboveSM ? 250 : null" [class.-ml-2]="size.isAboveSM && size.isBelowLG">
          <img class="max-w-28 m-auto" [src]="environment.logoImageNegative"/>
        </div>

        <!-- Components -->
        <div class="flex items-center pl-2 ml-auto space-x-0.5 sm:space-x-2" *transloco="let t">
          <svc-notifications></svc-notifications>
          <sites *ngIf="!size.isBelowMD"></sites>
          <user [showAvatar]="!size.isBelowMD" [showSites]="size.isBelowMD"></user>
          <button *ngIf="!size.isBelowLG" mat-icon-button (click)="toggleMenu()">
            <mat-icon class="text-current" [svgIcon]="'mat_solid:apps'"></mat-icon>
          </button>
        </div>
      </div>
    </div>

    <div class="flex flex-auto w-full m-auto overflow-y-auto" *screenSizes="let size" [style.maxWidth.px]="(feedIsEnabled && windowWidth >= 1782) ? 1782 : 1440" [style.margin]="'auto'">
      <div class="flex flex-col overflow-y-auto lg:pl-4 lg:pt-4">
        <!-- Navigation -->
        <vertical-navigation class="bg-primary-200 overflow-auto h-full lg:rounded-t-lg lg:z-49"
          [mode]="size.isBelowMD ? 'over' : 'side'"
          [opened]="!size.isBelowMD"
          [headerWithOverflow]="false"
          [smallInAboveMd]="true"
        >
          <!-- Navigation header hook -->
          <ng-container verticalNavigationContentHeader>
            <div class="flex mt-1 md:hidden justify-end">
              <button
                class="w-6 min-h-6 h-6"
                mat-icon-button
                (click)="toggleNavigation()"
              >
                <mat-icon
                  class="text-on-primary-200 icon-size-4"
                  [svgIcon]="'mat_solid:close'"
                ></mat-icon>
              </button>
            </div>

            <!-- User -->
            <div class="mt-1 md:mt-2">
              <user-card></user-card>
            </div>
            <div class="mt-3">
              <ng-container *ngIf="!navigationGotError">
                <user-navigation class="flex flex-col gap-y-2 py-1" *ngIf="!navigationIsLoading"
                  [navigation]="navigation?.workspace"
                  (openPraiseReceived)="openPraiseReceived()"
                  (emitCounters)="getCounters($event)">
                </user-navigation>
              </ng-container>
              <div *ngIf="navigationGotError" class="flex justify-center bg-white rounded-xl p-5" (click)="reloadUserNavigation()" [ngClass]="{ 'cursor-pointer': !navigationIsLoading }">
                <ng-container *ngIf="navigationGotError">
                  <mat-icon class="text-primary icon-size-4 inline-block align-middle mr-1" [svgIcon]="'heroicons_solid:exclamation-triangle'" [style.verticalAlign]="'middle'"></mat-icon>
                  <span class="text-primary text-sm underline">{{ 'Recarregar esta página' | transloco }}</span>
                </ng-container>
              </div>

              <ngx-skeleton-loader
                *ngIf="navigationIsLoading"
                class="flex flex-col gap-2 py-1"
                count="3"
                [theme]="{ width: '100%', height: '38px', borderRadius: '8px' }"
              >
              </ngx-skeleton-loader>

            </div>
            <user-hashtags class="mt-3 flex-auto overflow-hidden"></user-hashtags>
          </ng-container>
          <ng-container verticalNavigationFooter>
            <div class="flex flex-col items-center justify-center mt-2 mb-3">
              <hr class="w-full m-0 border-b-0"/>
              <div class="flex px-5 pt-3">
                <img
                  class="max-w-40"
                  src="https://solvacelabs-webcomponents.s3.amazonaws.com/images/company-logo-ng.svg"
                />
              </div>
            </div>
          </ng-container>
        </vertical-navigation>
      </div>

      <!-- Content -->
      <div class="relative flex flex-col flex-auto">
        <!-- *ngIf="true" hack is required here for router-outlet to work correctly.
              Otherwise, layout changes won't be registered and the view won't be updated! -->
        <router-outlet *ngIf="true"></router-outlet>
        <svc-copilot-float-button *ngIf="copilotEnabled" class="absolute left-10 bottom-7 z-99 hidden lg:flex"></svc-copilot-float-button>
      </div>

      <!--Apps panel-->
      <apps-panel #appsPanel="appsPanel"></apps-panel>
      <float-add-button #floatAddButton bottomCssClass="bottom-20 lg:bottom-10"></float-add-button>
      <sidebar-navigation
        #sidebarNavigationComponent
        class="lg:pr-4"
        [class.pt-2]="feedIsEnabled && windowWidth < 1782"
        [class.pt-4]="!feedIsEnabled || windowWidth >= 1782"
        [mode]="size.isAboveMD ? 'side' : 'over'"
        [opened]="size.isAboveMD"
        [showSideBySide]="feedIsEnabled && windowWidth >= 1782"
        (onKaizenResumeClicked)="onKaizenResumeClicked()"
      ></sidebar-navigation>

    </div>

    <mobile-menu
      (menuClick)="mobileMenuClick($event)"
      [totalTasks]="sidebarNavigationComponent?.totalTasks"
      [loadingTasks]="sidebarNavigationComponent?.loadingTasks ?? true"
      [totalFeeds]="sidebarNavigationComponent?.totalFeeds"
      [loadingFeeds]="sidebarNavigationComponent?.loadingFeeds ?? true"
    ></mobile-menu>
  </div>

  <router-outlet name="modal"></router-outlet>

</ng-container>
<ng-container *ngIf="isResolving">
  <div @fadeInOut class="workspace-skeleton-page fixed inset-0 flex flex-col overflow-y-hidden" *screenSizes="let size">
    <!-- HEADER -->
    <div class="relative flex flex-0 items-center w-full py-2.5 bg-zinc-400 bg-opacity-30">
      <div class="flex w-full h-11 m-auto px-4 md:pl-0 md:pr-4 lg:px-4 opacity-75" [style.maxWidth.px]="windowWidth >= 1782 ? 1782 : 1440" [style.margin]="'auto'">
        <div class="flex items-center justify-center h-full gap-3" [style.width.px]="size.isAboveSM ? 250 : null">
          <ngx-skeleton-loader *ngIf="size.isBelowMD" class="white" [theme]="{ width: '24px', height: '24px' }" />
          <ngx-skeleton-loader class="w-fit h-full py-1 white" [theme]="{ width: '112px', height: '100%' }" />
        </div>
        <div class="flex-auto flex items-center justify-end gap-2">
          <ngx-skeleton-loader class="mx-3 white" [theme]="{ width: '24px', height: '24px' }" />
          <div class="flex items-center px-3 gap-1" *ngIf="size.isAboveSM">
            <ngx-skeleton-loader class="white" [theme]="{ width: '24px', height: '24px', margin: 0 }" appearance="circle" />
            <ngx-skeleton-loader class="white" [theme]="{ width: '50px', height: '16px' }" />
          </div>
          <ngx-skeleton-loader class="mx-3 white" [theme]="{ width: '24px', height: '24px', margin: 0 }" appearance="circle" />
          <ngx-skeleton-loader *ngIf="size.isAboveMD" class="ml-3 white" [theme]="{ width: '24px', height: '24px' }" />
        </div>
      </div>
    </div>

    <div class="flex-auto flex w-full m-auto overflow-y-hidden" [style.maxWidth.px]="windowWidth >= 1782 ? 1782 : 1440" [style.margin]="'auto'">

      <!-- LEFT SIDE BAR -->
      <div *ngIf="size.isAboveSM" class="flex h-full lg:pl-4 lg:pt-4">
        <div class="flex-auto flex flex-col items-center px-2 pt-2 pb-3 gap-3 bg-zinc-200 bg-opacity-30 lg:rounded-t-lg lg:z-49" [style.width.px]="250">
          <div class="flex-auto flex flex-col overflow-y-hidden gap-4">
            <div class="flex flex-col w-full opacity-75">
              <ngx-skeleton-loader class="w-full" [theme]="{ height: '150px', borderTopLeftRadius: '8px', borderTopRightRadius: '8px' }" />
              <div class="flex flex-col items-center w-full gap-2 py-3 bg-white bg-opacity-80 rounded-b-lg">
                <ngx-skeleton-loader [theme]="{ width: '100px', height: '24px' }" />
                <div class="flex items-center w-full gap-3 px-2">
                  <ngx-skeleton-loader *ngFor="let _ of [1,2,3,4]" class="w-full" [theme]="{ height: '38px' }" />
                </div>
              </div>
            </div>
            <div class="flex flex-col w-full gap-2">
              <ngx-skeleton-loader *ngFor="let _ of [1,2]" class="w-full white" [theme]="{ height: '38px', borderRadius: '8px' }" />
            </div>
            <div class="w-full border-t border-white border-opacity-60"></div>
            <div class="flex flex-col w-full gap-4">
              <div class="flex gap-2 items-center">
                <ngx-skeleton-loader class="flex-auto white" [theme]="{ width: '100%', height: '42px', borderRadius: '8px' }" />
                <ngx-skeleton-loader class="w-6 h-6 white" [theme]="{ margin: 0, height: '24px' }" appearance="circle" />
                <ngx-skeleton-loader class="w-6 h-6 white" [theme]="{ margin: 0, height: '24px' }" appearance="circle" />
              </div>
              <div class="flex flex-wrap items-start gap-x-3 gap-y-4 justify-center content-start">
                <ngx-skeleton-loader class="white" [theme]="{ width: '80px', height: '24px', borderRadius: '8px' }" />
                <ngx-skeleton-loader class="white" [theme]="{ width: '120px', height: '24px', borderRadius: '8px' }" />
                <ngx-skeleton-loader class="white" [theme]="{ width: '100px', height: '24px', borderRadius: '8px' }" />
              </div>
            </div>
          </div>
          <div class="w-full">
            <div class="w-full border-t border-white border-opacity-60"></div>
          </div>
          <ngx-skeleton-loader class="white" [theme]="{ width: '160px', height: '36px', borderRadius: '6px' }" />
        </div>
      </div>

      <!-- CONTENT -->
      <div class="flex-auto flex flex-col gap-2 h-full items-center pt-3 md:pt-6 px-4 overflow-y-hidden">
        <div class="w-full flex shrink-0 gap-2 items-center justify-center overflow-hidden">
          <ngx-skeleton-loader [theme]="{ width: '75px', height: '20px' }" />
          <ngx-skeleton-loader [theme]="{ width: '100px', height: '20px' }" />
          <ngx-skeleton-loader [theme]="{ width: '90px', height: '20px', }" />
          <ngx-skeleton-loader [theme]="{ width: '110px', height: '20px', }" />
        </div>
        <div class="w-full border-t-2 border-gray-200"></div>
        <div class="w-full flex flex-col gap-y-1.5 items-center justify-center">
          <div class="flex flex-wrap -mx-1.5" [style.width]="'calc(100% + 12px)'">
            <div class="p-1.5 w-full sm:w-1/2" *ngFor="let _ of [1,2,3,4]">
              <ngx-skeleton-loader class="w-full" [class.white]="size.isAboveSM" [theme]="{ width: '100%', height: '244px' }" />
            </div>
          </div>
          <ngx-skeleton-loader class="w-full" [class.white]="size.isAboveSM" [theme]="{ width: '100%', height: '300px' }" />
        </div>
      </div>

      <!-- RIGHT SIDE BAR -->
      <div *ngIf="size.isAboveMD" class="flex h-full lg:pr-4 pt-4 gap-6 overflow-y-hidden" [class.pt-2]="windowWidth < 1782" [class.pt-4]="windowWidth >= 1782">
        <div class="flex flex-col gap-2 w-80">
          <ngx-skeleton-loader class="w-full" [theme]="{ height: '64px', borderRadius: '8px' }" />
          <ngx-skeleton-loader *ngIf="windowWidth < 1782" class="w-full" [theme]="{ height: '38px', borderRadius: '8px' }" />
          <div class="flex flex-col">
            <div class="flex gap-3 h-13 items-center bg-gray-200 bg-opacity-50 border-b border-gray-300 border-opacity-50 rounded-t-lg px-6">
              <ngx-skeleton-loader class="w-16" [theme]="{ height: '25px', borderRadius: '4px' }" />
              <ngx-skeleton-loader [theme]="{ width: '23px', height: '23px', margin: 0 }" appearance="circle" />
            </div>
            <div class="flex gap-3 h-13 items-center bg-gray-200 bg-opacity-50 border-b border-gray-300 border-opacity-50 px-6">
              <ngx-skeleton-loader class="w-12" [theme]="{ height: '25px', borderRadius: '4px' }" />
              <ngx-skeleton-loader [theme]="{ width: '23px', height: '23px', margin: 0 }" appearance="circle" />
            </div>
            <div class="flex gap-3 h-13 items-center bg-gray-200 bg-opacity-50 border-b border-gray-300 border-opacity-50 px-6">
              <ngx-skeleton-loader class="w-15" [theme]="{ height: '25px', borderRadius: '4px' }" />
              <ngx-skeleton-loader [theme]="{ width: '23px', height: '23px', margin: 0 }" appearance="circle" />
            </div>
            <div class="flex gap-3 h-13 items-center bg-gray-200 bg-opacity-50 rounded-b-lg px-6">
              <ngx-skeleton-loader class="w-11" [theme]="{ height: '25px', borderRadius: '4px' }" />
              <ngx-skeleton-loader [theme]="{ width: '23px', height: '23px', margin: 0 }" appearance="circle" />
            </div>
          </div>
        </div>
        <div *ngIf="windowWidth >= 1782" class="flex flex-col w-80 gap-4 bg-zinc-200 bg-opacity-30 rounded-t-lg p-4">
          <div class="flex gap-2 items-center">
            <ngx-skeleton-loader class="flex-auto white" [theme]="{ width: '100%', height: '42px', borderRadius: '6px' }" />
            <ngx-skeleton-loader class="w-8 h-8 white" [theme]="{ margin: 0, height: '32px', borderRadius: '6px' }" />
            <ngx-skeleton-loader class="w-6 h-6 white" [theme]="{ margin: 0, height: '24px', borderRadius: '6px' }" />
          </div>
          <div class="flex-auto flex flex-col rounded-lg gap-3 bg-white bg-opacity-30 p-2.5">
            <div class="flex flex-col gap-2 border-b border-gray-200 pb-3">
              <div class="flex gap-3 items-center">
                <div class="flex-auto">
                  <ngx-skeleton-loader class="w-30" [theme]="{ height: '25px', borderRadius: '4px' }" />
                </div>
                <ngx-skeleton-loader [theme]="{ width: '24px', height: '24px', margin: 0 }" appearance="circle" />
              </div>
              <div class="flex gap-3 items-center">
                <ngx-skeleton-loader [theme]="{ width: '34px', height: '34px', margin: 0 }" appearance="circle" />
                <ngx-skeleton-loader class="w-20" [theme]="{ height: '25px', borderRadius: '4px' }" />
              </div>
            </div>
            <div class="flex flex-col gap-2">
              <div class="flex gap-3 items-center">
                <ngx-skeleton-loader class="w-40" [theme]="{ height: '25px', borderRadius: '4px' }" />
              </div>
              <div class="flex gap-3 items-center">
                <ngx-skeleton-loader [theme]="{ width: '34px', height: '34px', margin: 0 }" appearance="circle" />
                <ngx-skeleton-loader class="w-28" [theme]="{ height: '25px', borderRadius: '4px' }" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
