<ng-container *screenSizes="let size">
  <!-- SLIDE EMPTY VIEW (ADD MORE) -->
  <ng-container *ngIf="!item">
    <img
      class="fadeIn object-none"
      src="assets/default_card_thumbnail.png"
    />
    <!-- ADD BUTTON -->
    <button
      *ngIf="parent.editMode"
      mat-icon-button
      class="fadeIn absolute top-3 right-3 flex w-6 h-6 min-h-6 bg-primary text-on-primary"
      (click)="parent.emitAddItem()"
      [matTooltip]="'Adicionar' | transloco"
    >
      <mat-icon class="icon-size-4 text-current" svgIcon="fontawesome_solid:plus"/>
    </button>
  </ng-container>
  
  <!-- CURRENT SLIDE -->
  <ng-container *ngIf="canBeDisplayed && item">
    <img *ngIf="item.type === 'image' && !item.fileLoading && item.base64Url"
      #img
      class="fadeIn"
      [src]="item.base64Url"
      [style.object-fit]="parent.fitContent"
      [class.cursor-pointer]="parent.canClickToExpandImage"
      (click)="parent.openItem(item)"
    />
    <video *ngIf="item.type === 'video'"
      #video
      class="fadeIn"
      [style.object-fit]="parent.fitContent"
      [src]="item.url"
      [controls]="size.isXS && parent.videoControls"
      (mouseenter)="parent.videoControls ? $event.target.controls = true : null"
      (mouseleave)="parent.videoControls ? $event.target.controls = false : null"
      (error)="item.fileLoading = false"
      (loadeddata)="item.fileLoading = false"
    ></video>
  
    <!-- LOADING IMAGE STATE -->
    <div
      *ngIf="item.fileLoading"
      [@fadeIn]="{ value: ':enter' }"
      [@fadeOut]="{ value: ':leave' }"
      class="absolute inset-0 flex items-center justify-center"
      [ngClass]="parent.isDark ? 'text-white' : 'text-primary'"
    >
      <div class="absolute inset-0  opacity-50" [ngClass]="parent.isDark ? 'bg-gray-900' : 'bg-white'"></div>
      <mat-spinner class="relative z-99 text-current" diameter="24"/>
    </div>
    
    <!-- REMOVE BUTTON -->
    <button
      *ngIf="parent.editMode"
      class="fadeIn"
      mat-icon-button
      class="absolute top-3 right-3 flex w-6 h-6 min-h-6 bg-primary text-on-primary"
      (click)="parent.emitDeleteItem(item)"
      [matTooltip]="'Remover' | transloco"
    >
      <mat-icon class="icon-size-4.5 text-current" svgIcon="heroicons_solid:trash"/>
    </button>
  </ng-container>
</ng-container>