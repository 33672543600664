import { HttpClient, HttpContext, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable, Optional } from "@angular/core";
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class SvcHttpClient {

    constructor(
        @Optional() protected _baseUrl: String,
        protected _httpClient: HttpClient,
    ) {}

    public get<T>(path: string, options?: {
        headers?: HttpHeaders | {
            [header: string]: string | string[];
        };
        context?: HttpContext;
        observe?: 'body' | 'response';
        params?: HttpParams | {
            [param: string]: string | number | boolean | ReadonlyArray<string | number | boolean>;
        };
        reportProgress?: boolean;
        responseType?: 'json' | 'arraybuffer' | 'blob' | any;
        withCredentials?: boolean;
    }): Observable<T> {
        return this._httpClient.get<T>(`${this._baseUrl ?? ''}${path}`, options as any) as Observable<T>;
    }

    public post<T>(path: string, body: any | null, options?: {
        headers?: HttpHeaders | {
            [header: string]: string | string[];
        };
        context?: HttpContext;
        observe?: 'body' | 'response';
        params?: HttpParams | {
            [param: string]: string | number | boolean | ReadonlyArray<string | number | boolean>;
        };
        reportProgress?: boolean;
        responseType?: 'json' | 'arraybuffer' | 'blob' | any;
        withCredentials?: boolean;
    }): Observable<T> {
        return this._httpClient.post<T>(`${this._baseUrl ?? ''}${path}`, body, options as any) as Observable<T>;
    }

    public put<T>(path: string, body: any | null, options?: {
        headers?: HttpHeaders | {
            [header: string]: string | string[];
        };
        context?: HttpContext;
        observe?: 'body' | 'response';
        params?: HttpParams | {
            [param: string]: string | number | boolean | ReadonlyArray<string | number | boolean>;
        };
        reportProgress?: boolean;
        responseType?: 'json' | 'arraybuffer' | 'blob' | any;
        withCredentials?: boolean;
    }): Observable<T> {
        return this._httpClient.put<T>(`${this._baseUrl ?? ''}${path}`, body, options as any) as Observable<T>;
    }

    public patch<T>(path: string, body: any | null, options?: {
        headers?: HttpHeaders | {
            [header: string]: string | string[];
        };
        context?: HttpContext;
        observe?: 'body' | 'response';
        params?: HttpParams | {
            [param: string]: string | number | boolean | ReadonlyArray<string | number | boolean>;
        };
        reportProgress?: boolean;
        responseType?: 'json' | 'arraybuffer' | 'blob' | any;
        withCredentials?: boolean;
    }): Observable<T> {
        return this._httpClient.patch<T>(`${this._baseUrl ?? ''}${path}`, body, options as any) as Observable<T>;
    }

    public delete<T>(path: string, options?: {
        headers?: HttpHeaders | {
            [header: string]: string | string[];
        };
        context?: HttpContext;
        observe?: 'body' | 'response';
        params?: HttpParams | {
            [param: string]: string | number | boolean | ReadonlyArray<string | number | boolean>;
        };
        reportProgress?: boolean;
        responseType?: 'json' | 'arraybuffer' | 'blob' | any;
        withCredentials?: boolean;
        body?: any | null;
    }): Observable<T> {
        return this._httpClient.delete<T>(`${this._baseUrl ?? ''}${path}`, options as any) as Observable<T>;
    }

    public request<T>(method: string, path: string, options?: {
        body?: any;
        headers?: HttpHeaders | {
            [header: string]: string | string[];
        };
        context?: HttpContext;
        observe?: 'body';
        params?: HttpParams | {
            [param: string]: string | number | boolean | ReadonlyArray<string | number | boolean>;
        };
        reportProgress?: boolean;
        responseType?: 'json' | 'arraybuffer' | 'blob' | any;
        withCredentials?: boolean;
    }): Observable<T> {
        return this._httpClient.request<T>(method, `${this._baseUrl ?? ''}${path}`, options);
    }

    public unauthorizedGet<T>(path: string, options?: {
        headers?: HttpHeaders | {
            [header: string]: string | string[];
        };
        context?: HttpContext;
        observe?: 'body' | 'response';
        params?: HttpParams | {
            [param: string]: string | number | boolean | ReadonlyArray<string | number | boolean>;
        };
        reportProgress?: boolean;
        responseType?: 'json' | 'arraybuffer' | 'blob' | any;
    }): Observable<T> {
        return this.get<T>(`${this._baseUrl ?? ''}${path}`, {
            ...(options ?? {} as any),
            headers: new HttpHeaders(options?.headers ?? <any>{})
                .set('SvcUnauthorizedRequest', ''),
            withCredentials: false,
        }) as Observable<T>;
    }
}
