import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'svc-button-float',
  templateUrl: './svc-button-float.component.html',
  styleUrls: ['./svc-button-float.component.scss']
})
export class SvcButtonFloatComponent {
  @Input() position: 'absolute' | 'fixed' = 'fixed';
  @Input() buttonId: string = 'main-floating-button';
  @Output() onButtonClicked: EventEmitter<any> = new EventEmitter<any>();

  onClick(): void {
    this.onButtonClicked.emit();
  }
}
