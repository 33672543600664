import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SvcActionplanComponent } from './svc-actionplan.component';
import { LibSharedCommonModule } from 'projects/lib-shared-common/src/public-api';
import { LibSharedComponentModule, SvcButtonsModule, SvcControlsModule, SvcLockButtonModule, SvcSearchFieldModule, SvcStatusBadgeModule, SvcUserAvatarModule } from 'projects/lib-shared-component/src/public-api';
import { TranslocoModule } from '@ngneat/transloco';
import { LibServiceActionplanAnalyticsModule } from 'projects/lib-service-actionplan-analytics/src/public-api';
import { SvcTotalsStatusModule } from '../svc-totals-status/svc-totals-status.module';
import { SvcPaginateModule } from 'projects/lib-shared-component/src/lib/svc-paginate/svc-paginate.module';

@NgModule({
  declarations: [
    SvcActionplanComponent,
  ],
  imports: [
    CommonModule,
    LibSharedCommonModule,
    LibSharedComponentModule,
    TranslocoModule,
    SvcControlsModule,
    SvcUserAvatarModule,
    SvcButtonsModule,
    SvcStatusBadgeModule,
    LibServiceActionplanAnalyticsModule,
    SvcTotalsStatusModule,
    SvcLockButtonModule,
    SvcSearchFieldModule,
    SvcPaginateModule
  ],
  exports: [
    SvcActionplanComponent,
  ]
})
export class SvcActionplanModule { }
