<div class="svc-mc-viewport" #viewport (scroll)="onViewportScroll()">
  <div class="svc-mc-inner-viewport" [style.height.px]="itemHeight ? itemHeight : null">
    <svc-media-carousel-item
      *ngIf="!items.length || editMode"
      [style.width.px]="itemWidth ? itemWidth : viewport.offsetWidth"
      [item]="null"
    />
    <svc-media-carousel-item
      *ngFor="let item of items"
      [item]="item"
      [style.width.px]="itemWidth ? itemWidth : viewport.offsetWidth"
    />
  </div>
</div>

<!-- NAV-BUTTON PREVIOUS -->
<button
  *ngIf="renderMode === 'list' ? (viewportRemainingToStart > 0) : (currentIndex > 0 || (currentIndex === 0 && (!this.items.length || this.editMode)))"
  mat-icon-button
  class="absolute bg-transparent w-7 h-7 min-h-7 top-1/2 left-0 -translate-y-1/2 text-on-primary"
  matTooltipPosition="above"
  [matTooltip]="'Anterior' | transloco"
  (click)="goToPrevious($event)"
>
  <mat-icon class="text-current icon-size-6" svgIcon="heroicons_solid:chevron-left"/>
</button>

<!-- NAV-BUTTON NEXT -->
<button
  *ngIf="renderMode === 'list' ? (viewportRemainingToEnd > 0) : ((!current && items.length) || (current && currentIndex < (items.length - 1)))"
  mat-icon-button
  class="absolute bg-transparent w-7 h-7 min-h-7 top-1/2 right-0 -translate-y-1/2 text-on-primary"
  matTooltipPosition="above"
  [matTooltip]="'Próximo' | transloco"
  (click)="goToNext($event)"
>
  <mat-icon class="text-current icon-size-6" svgIcon="heroicons_solid:chevron-right"/>
</button>
